import ReactStars from "react-rating-stars-component";
import React from "react";
import PropTypes from "prop-types";

const RatingReview = ({ rating }) => {
  
  return (
    <ReactStars
      key={rating} // add this line
      count={5}
      size={24}
      isHalf={true}
      value={rating}
      emptyIcon={<i className="far fa-star"></i>}
      halfIcon={<i className="fa fa-star-half-alt"></i>}
      fullIcon={<i className="fa fa-star"></i>}
      activeColor="#ffd700"
      edit={false}
      precision={0.01}
    />
  );
};

// Define prop types
RatingReview.propTypes = {
  rating: PropTypes.number,
};

export default RatingReview;
