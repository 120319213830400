import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assignmentType: "",
  assignmentTrackingData: null,
  assignmentTrackingDataWithDeadMiles: null,
};

export const AssignmentTrackingStatsSlice = createSlice({
  name: "assignmentTrackingStats",
  initialState,
  reducers: {
    setAssignmentTrackingDataRedux: (state, action) => {
      state.assignmentTrackingData = action.payload;
    },
    setAssignmentTrackingDataWithDeadMiles: (state, action) => {
      state.assignmentTrackingDataWithDeadMiles = action.payload;
    },
  },
});

export const { setAssignmentTrackingDataRedux, setAssignmentTrackingDataWithDeadMiles } =
  AssignmentTrackingStatsSlice.actions;

export default AssignmentTrackingStatsSlice.reducer;
