import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "react-daisyui";
import { postMethodDataWithToken } from "../../ApiMethods/Api";
import { setStaff } from "../../Redux-Toolkit/StaffSlice";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const Profile = () => {
  return <Screen />;
};

export default Profile;

const Screen = () => {

  const dispatch = useDispatch();

  const token = useSelector((state) => state?.auth?.Token);
  const [staffDataLoading, setStaffDataLoading] = useState(true);
  const [staffDetails, setStaffDetails] = useState([]);

  const userID = jwtDecode(token)?.UserID || "";

  useEffect(() => {
    const body = {
      userID: userID,
      userName: "",
      role: 0,
      inactiveflag: 0,
      page: 1,
      limit: 20,
    };
    fetchStaffData("/StaffDetails", body, (response) => {
      if (response?.data?.data?.length === 0) {
        setStaffDetails([]);
      } else {
        setStaffDetails(response?.data?.data[0]);
      }
    });
  }, []);

  const fetchStaffData = async (URL, data, callback) => {
    setStaffDataLoading(true);
    const body = data;
    try {
      const response = await postMethodDataWithToken(URL, body, token);
      setStaffDataLoading(false);
      callback(response); // Assuming data is under data property
    } catch (error) {
      console.error("An error occurred while fetching staff data:", error);
      setStaffDataLoading(false);
      dispatch(setStaff([]));
    }
  };

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Profile Details</p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      {staffDataLoading ? (
        <Loader />
      ) : (
        <div className="card bg-base-100 shadow-md p-6 mt-3 mb-3">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="userid"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                User ID
              </label>
              <Input
                name="userid"
                value={staffDetails?.userID || "N/A"}
                placeholder="User ID"
                disabled
                id="userid"
                type="text"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Name
              </label>
              <Input
                name="name"
                value={staffDetails?.userName || "N/A"}
                disabled
                placeholder="User Name"
                id="name"
                type="text"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>

            <div>
              <label
                htmlFor="role"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Email
              </label>
              <Input
                name="email"
                value={staffDetails?.email || "N/A"}
                placeholder="User Name"
                id="email"
                disabled
                type="text"
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Loader = () => (
  <div className="flex justify-center items-center h-full">
    <svg
      className="animate-spin h-8 w-8 text-gray-600"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
);
