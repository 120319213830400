import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
  stateList: [],
  categoryList: [],
  notificationcount: "",
  selectedTableRow: null,

  customerFilters: {
    customerID: 0,
    customerName: "",
    email: "",
    states: "",
    category: "",
    inactive: -2,
  },
};

export const CustomerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    loadUser: (state) => {
      if (!localStorage.getItem("userAuthApproved")) {
        state.userAuthApproved = false;
        return;
      }
      state.Token = localStorage.getItem("Admintoken");
      state.user = localStorage.getItem("Admin");
      state.userAuthApproved = true;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setErrLoggingIn: (state, action) => {
      state.errLoggingIn = action.payload;
    },
    // Add other reducers here
    setLoginLoading: (state, action) => {
      state.loginLoading = action.payload;
    },
    setClaimants: (state, action) => {
      state.claimants = action.payload;
    },
    setStatesList: (state, action) => {
      state.stateList = action.payload;
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    setCustomerFilters: (state, action) => {
      state.customerFilters = action.payload;
    },
    resetCustomerFilters: (state) => {
      state.customerFilters = {...initialState.customerFilters}; 
      state.selectedTableRow = null;
    },
    updateCustomerFilters: (state, action) => {
      state.customerFilters = { ...state.customerFilters, ...action.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedTableRow = action.payload;
    },
  },

});

export const {
  setClaimants,
  setErrLoggingIn,
  setLoginLoading,
  userAuthApproved,
  loadUser,
  setStatesList,
  setCategoryList,
  setCustomers,
  setCustomerFilters,
  resetCustomerFilters,
  updateCustomerFilters,
  setSelectedRow,
} = CustomerSlice.actions;

export default CustomerSlice.reducer;
