import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "react-daisyui";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { setStaffFilters } from "../../../../Redux-Toolkit/StaffSlice";
import { useNavigate } from "react-router-dom";

const StaffManagementFilter = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const staffFilters = useSelector((state) => state?.staff?.staffFilters);

  useEffect(() => {
    setUserName(staffFilters?.userName);
    setUserID(staffFilters?.userID);
    setInactiveflag(staffFilters?.inactiveflag);
    setRole(staffFilters?.role);
    setEmail(staffFilters?.email);
    setPage(staffFilters?.page);
  }, [staffFilters]);

  const [userName, setUserName] = useState(staffFilters?.userName || "");
  const [userID, setUserID] = useState(staffFilters?.userID || "");
  const [inactiveflag, setInactiveflag] = useState(staffFilters?.inactiveflag || -2);
  const [role, setRole] = useState(staffFilters?.role || 0);
  const [email, setEmail] = useState(staffFilters?.email || "");
  const [page, setPage] = useState(staffFilters?.page || 0);
  const staffRoles = useSelector((state) => state?.staff?.roles || []);

  /**
   *
   * @param {url} url
   * @returns customers search list
   */

  const allclear = () => {
    setUserID("");
    setUserName("");
    setInactiveflag(-2);
    setRole(0);

    const filterBody = {
      userID: "",
      userName: "",
      inactiveflag: -2,
      role: 0,
      email: "",
      page: page,
      limit: 20,
    };
    dispatch(setStaffFilters(filterBody)); //to update the filter states in store to persist filter values
    navigate("/staff-management");
  };

  return (
    <div className="card bg-base-100 shadow-md p-6 mt-3 mb-3">
      <form
        onSubmit={(e) => {
          const data = {
            ...(userID && userID.trim() !== "" && { userID }),
            ...(userName && userName.trim() !== "" && { userName }),
            ...(role && role.trim() !== "" && { role }),
            ...(email && email.trim() !== "" && { email }),
            ...(inactiveflag !== undefined && { inactiveflag }),
            page: 1,
            limit: 20,
          };
          dispatch(setStaffFilters(data)); //to update the filter states in store to persist filter values
          navigate(`?page=1`);

          e.preventDefault();
        }}
      >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          <div>
            <label
              htmlFor="userid"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              User ID
            </label>
            <Input
              name="userid"
              onChange={(e) => setUserID(e.target.value)}
              value={userID}
              placeholder="User ID"
              id="userid"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          </div>
          <div>
            <label
              htmlFor="userName"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              User Name
            </label>
            <Input
              name="userName"
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
              placeholder="User Name"
              id="userName"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          </div>

          <div>
            <label
              htmlFor="role"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Role
            </label>
            <select
              id="role"
              onChange={(e) => setRole(e.target.value)}
              name="role"
              value={role}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value={0}>{"All"}</option>
              {Array.isArray(staffRoles) &&
                staffRoles?.map((role, index) => (
                  <option key={role?.groupNum} value={role?.groupNum}>
                    {role?.groupName}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="status"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Status
            </label>
            <select
              id="status"
              onChange={(e) => setInactiveflag(e.target.value)}
              name="status"
              value={inactiveflag}
              className="select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered"
            >
              <option value={-2}>ALL</option>
              <option value={0}>Active</option>
              <option value={-1}>Inactive </option>
            </select>
          </div>
        </div>
        <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
          <button
            type="reset"
            onClick={() => {
              allclear();
            }}
            className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            <MdOutlineRefresh className="mr-1" />
            Reset
          </button>
          <button className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4">
            <MdOutlineSearch className="mr-1" />
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default StaffManagementFilter;
