import React from "react";
import { MdOutlineNextPlan } from "react-icons/md";
import { FaPeopleGroup, FaBuilding } from "react-icons/fa6";
import { FaPeopleArrows, FaCalendarWeek } from "react-icons/fa";
import { RxPerson } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addWeeks, startOfWeek, endOfWeek, format } from "date-fns";
import { updateReservationFilters } from "../../../../Redux-Toolkit/ReservationSlice";
import { updateAssignmentFilters } from "../../../../Redux-Toolkit/AssignmentSlice";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";

const iconEnum = {
  FaBuilding: FaBuilding,
  MdOutlineNextPlan: MdOutlineNextPlan,
  FaPeopleGroup: FaPeopleGroup,
  FaPeopleArrows: FaPeopleArrows,
  FaCalendarWeek: FaCalendarWeek,
  RxPerson: RxPerson,
};

const pageRoutes = {
  "Total Customers": "/customer-management",
  "Total Claimants": "/claimant-management",
  "Total Contractors": "/contractor-management",
  "Total Reservations": "/reservations-management",
  "This Week Reservations": "/reservations-management",
  "Today's Reservations": "/reservations-management",
  "Next Week Reservations": "/reservations-management",
  "Upcoming Reservations": "/reservations-management",
  "Total Assignments": "/assignments-management",
  "Today's Assignments": "/assignments-management",
  "Canceled Assignments": "/assignments-management",
  "This Week Assignments": "/assignments-management",
  "Upcoming Assignments": "/assignments-management",
  "Need Attention Assignments": "/assignments-management",
};

const reservationDates = [
  {
    name: "Total Reservations",
    dateFrom: "",
    dateTo: "",
    inactiveflag: -2,
  },
  {
    name: "This Week Reservations",
    dateFrom: format(
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      "yyyy-MM-dd"
    ),
    dateTo: format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
    inactiveflag: "0",
  },
  {
    name: "Today's Reservations",
    dateFrom: new Date().toISOString().slice(0, 10),
    dateTo: new Date().toISOString().slice(0, 10),
    inactiveflag: "0",
  },
  {
    name: "Next Week Reservations",
    dateFrom: format(
      startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 }),
      "yyyy-MM-dd"
    ),
    dateTo: format(
      endOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 }),
      "yyyy-MM-dd"
    ),
    inactiveflag: "0",
  },
  {
    name: "Upcoming Reservations",
    dateFrom: format(new Date(), "yyyy-MM-dd"),
    dateTo: "",
    inactiveflag: "0",
  },
];

const assignmentDates = [
  {
    name: "Total Assignments",
    filter: {
      dateFrom: "",
      dateTo: "",
      inactiveflag: -2,
    },
  },
  {
    name: "Today's Assignments",
    filter: {
      dateFrom: new Date().toISOString().slice(0, 10),
      dateTo: new Date().toISOString().slice(0, 10),
      inactiveflag: "0",
    },
  },
  {
    name: "This Week Assignments",
    filter: {
      dateFrom: format(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        "yyyy-MM-dd"
      ),
      dateTo: format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
      inactiveflag: "0",
    },
  },
  {
    name: "Upcoming Assignments",
    filter: {
      dateFrom: format(new Date(), "yyyy-MM-dd"),
      dateTo: "",
      inactiveflag: "0",
    },
  },
  {
    name: "Need Attention Assignments",
    filter: {
      dateFrom: new Date().toISOString().slice(0, 10),
      dateTo: new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .slice(0, 10),
      inactiveflag: "0",
      conAssignStatus: -1,
      rsvacCode: "Reserved",
    },
  },
  {
    name: "Canceled Assignments",
    filter: {
      inactiveflag: "-1",
    },
  },
];

const CardDashbord = ({ cardData }) => {
  return <Screen cardData={cardData} />;
};

CardDashbord.propTypes = {
  cardData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default CardDashbord;

const Screen = ({ cardData }) => {
  const dispatch = useDispatch();
  const setCardFilter = (cardname) => {
    reservationDates.forEach((card) => {
      if (card.name === cardname) {
        const filterData = {
          dateFrom: card?.dateFrom || "",
          dateTo: card?.dateTo || "",
          inactiveflag: card?.inactiveflag,
        };
        dispatch(updateReservationFilters(filterData));
      }
    });

    assignmentDates.forEach((card) => {
      if (card.name === cardname) {
        const filterData = {
          dateFrom: card?.filter?.dateFrom || "",
          dateTo: card?.filter?.dateTo || "",
          inactiveflag: card?.filter?.inactiveflag,
          conAssignStatus: card?.filter?.conAssignStatus,
          rsvacCode: card?.filter?.rsvacCode,
        };
        dispatch(updateAssignmentFilters(filterData));
      }
    });
  };

  return (
    <>
      <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 gap-8" />
      <div className="grid xl:grid-cols-4 mt-5 lg:grid-cols-2 md:grid-cols-2 gap-8">
        {cardData &&
          Array.isArray(cardData) &&
          cardData?.map((card, index) => {
            if (!card?.name || !card?.icon || card?.value === null) {
              return null; // Skip rendering this card if any required field is missing or invalid
            }

            const Icon = iconEnum[card?.icon];
            const PageRoute = pageRoutes[card?.name];

            return (
              <Link to={PageRoute} key={nanoid()}>
                <div
                  className={`card cursor-pointer bg-base-100 shadow-md p-6 group card-dash`}
                >
                  <button
                  className=" text-left"
                    onClick={() => setCardFilter(card?.name)}
                  >
                    <div className="flex">
                      <div
                        className={`${card.name === "Need Attention Assignments" ? "text-[red] hover:text-[red]" : ""} w-9/12`}
                      >
                        <p className="text-xl font-semibold mb-2">
                          {card?.value}
                        </p>
                        <p className="text-base font-medium opacity-80 hover:text-[#fff] group-hover:text-[#fff]">
                          {card?.name}
                        </p>
                      </div>
                      <div className="w-3/12 flex">
                        <div className="bg-green group-hover:bg-[#fff] aspect-[1] w-full m-auto flex items-center justify-center text-3xl rounded-lg">
                          <Icon />
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </Link>
            );
          })}
      </div>
    </>
  );
};

Screen.propTypes = {
  cardData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};
