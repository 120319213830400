import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contractorRates: [],
  total_contractorRates: "",
  loginLoading: false,
  errLoggingIn: "",
  userAuthApproved: false,
  Token: null,
  verifybtn: false,
  notificationcount: "",
  selectedTableRow: null,

  contractorRateFilters: {
    ContractorRatesID: 0,
    acctgCode: "",
    transType: "",
    language: "",
    lob: "",
  },
  acctgCode: "",
  transType: "",
  language: "",
  lob: "",
};

export const ContractorRatesSlice = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    loadUser: (state) => {
      if (!localStorage.getItem("userAuthApproved")) {
        state.userAuthApproved = false;
        return;
      }
      state.Token = localStorage.getItem("Admintoken");
      state.user = localStorage.getItem("Admin");
      state.userAuthApproved = true;
    },
    setContractorRates: (state, action) => {
      state.contractorRates = action.payload;
  },
  setErrLoggingIn: (state, action) => {
    state.errLoggingIn = action.payload;
  },
  // Add other reducers here
  setLoginLoading: (state, action) => {
    state.loginLoading = action.payload;
  },
    setAcctgCode: (state, action) => {
      state.acctgCode = action.payload;
    },
    setTransportType: (state, action) => {
      state.transType = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setLob: (state, action) => {
      state.lob = action.payload;
    },
    setContractorRatesFilters: (state, action) => {
      state.contractorRateFilters = action.payload;
    },
    resetContractorRatesFilters: (state) => {
      state.contractorRateFilters = {...initialState.contractorRateFilters}; 
      state.selectedTableRow = null;
    },
    updateContractorRatesFilters: (state, action) => {
      state.contractorRateFilters = { ...state.contractorRateFilters, ...action.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedTableRow = action.payload;
    },
  },

});

export const {
  setErrLoggingIn,
  setLoginLoading,
  userAuthApproved,
  loadUser,
  setContractorRates,
  resetContractorRatesFilters,
  updateContractorRatesFilters,
  setAcctgCode,
  setLanguage,
  setLob,
  setTransportType,
  setSelectedRow,
  setContractorRatesFilters,
} = ContractorRatesSlice.actions;

export default ContractorRatesSlice.reducer;
