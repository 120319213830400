import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import { formatDate } from "../../../utils/helpers";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ViewServiceLocationList = () => {

  const { id } = decryptParamID(useParams());
  const [contractors, setContractors] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [contractorDataLoading, setContractorDataLoading] = useState(true);

  const [currentPage] = useState(0);
  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setContractorDataLoading(false); // Set loading to false
    getData("/ContServiceLocation", id, (response) => {

      if (!response?.data?.status) {
        setContractors([]);
      } else if (response?.data?.status) {
        setContractors(response?.data?.data);
        return response;
      }
    });

    /** finding contractor company name by api */

    getData("/ContractorSelDetail", id, (response) => {
      if (response?.data?.status === true) {
        setCompanyName(response?.data?.data[0]?.company);
      } else {
        setCompanyName([]);
      }
    });
  }, [id]);

  const getData = async (url, id, callback) => {
    try {
      const data = {
        contractorID: id,
      };

      const response = await postMethodData(url, data);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer claimant list:",
        error
      );
    }
  };

  const goBack = () => {
    window.history.back();
  };

  return (

    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            Service Location List for: {id} | {companyName}
          </p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th></th>
                <th>Zipcode</th>
                <th>Last Change Date</th>
                <th>City</th>
                <th>State</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {contractorDataLoading &&
                Array.from({ length: 5 }).map((idx) => (
                  <tr key={nanoid()}>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                ))}
              {contractors
                .map((data, index) => (
                  <tr key={nanoid()}>
                    <td>{currentPage * ITEMS_PER_PAGE + index + 1}</td>

                    <td className={`capitalize`}>{data?.zipCode || "N/A"}</td>
                    <td className=" capitalize">
                      <div>{formatDate(data?.lastChangeDate)}</div>
                    </td>
                    <td className=" capitalize">{data?.city || "N/A"}</td>
                    <td className=" capitalize">{data?.state || "N/A"}</td>
                    <td>
                      <label
                        htmlFor="my-modal-5"
                        className={`cursor-default btn capitalize ${
                          data?.inactiveFlag === -1
                            ? "bg-[red] hover:bg-[red] "
                            : "bg-[#39ff14] hover:bg-[#39ff14]"
                        }  text-[#000] border-hidden  btn-xs`}
                      >
                        {data?.inactiveFlag === -1 ? "Inactive" : "Active"}
                      </label>
                    </td>
                  </tr>
                ))}

              {!contractorDataLoading && contractors?.length === 0 && (
                <tr>
                  <td colSpan={11} className="my-10 text-center">
                    <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Service Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {contractorDataLoading && (
          <div
            role="loader"
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}

          >
            <LoadingPage />
          </div>
        )}
    </>
  );
};

export default ViewServiceLocationList;
