
export const SafeAllowClick = (callback, role, roleType) => {
    if (!roleType) {
        console.error("roleType is required in safe Allow Click");
        return undefined;
    }

    try {
        // Check if data is a string; throw an error if it's not
        if (typeof callback !== 'function') {
            throw new Error("Callback must be a function");
        }
        const enumRoleTypes = String(roleType).split("")
        const preEnums = {
            'Administrators': 'A',
            'Billing': 'B',
            'Schedulers': 'S'
        }
        if (enumRoleTypes.includes(preEnums[role])) {
            callback();
        }
    } catch (error) {
        console.error("SafeAllowClick Error : ", error)
    }
}

export const SafeAllowShow = (data, role, roleType) => {
    if (!roleType) {
        console.error("roleType is required in safe Allow Click");
        return undefined;
    }

    try {

        if (typeof data !== 'string') {
            throw new TypeError("Data must be a string"); // Use TypeError for type issues
        }

        const enumRoleTypes = String(roleType).split("")
        const preEnums = {
            'Administrators': 'A',
            'Billing': 'B',
            'Schedulers': 'S'
        }
        if (enumRoleTypes.includes(preEnums[role])) {
            return data;
        }
    } catch (error) {
        console.error("SafeAllowShow Error : ", error);
        return undefined; // Return undefined in case of error
    }
}
