import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { getTimeDifference, getTimeFromString, safeParseJSON } from "../../../../utils/helpers";
import movingcar from "../../../../assets/images/car-moving.gif";
import contractorWaiting from "../../../../assets/images/contractorWaiting.gif";
import PropTypes from "prop-types";

const TransportAssignmentHistory = ({

  currentbuttonId,
  fetchLocationName,
  trackingData,
  index,
}) => {

  const currentButtonId = currentbuttonId;

  const [contractorStartedLocation, setContractorStartedLocation] = useState(null);

  //---------------------to get location name and deadMiles from latitude and longitude---------------------

  useEffect(() => {
    const location = safeParseJSON(trackingData?.startDriverLatitudeLongitude);

    location && fetchLocationNameFunc(location);
  }, [trackingData]);

  const fetchLocationNameFunc = async (location) => {
    const name = await fetchLocationName(location);

    setContractorStartedLocation(name);
  };

  //------------------------------------end of location name and deadMiles-----------------------------------

  const assignmentHistoryTitle = () => {
    if (index === 0 && currentButtonId[0] >= 1) return "Dead Miles";
    else if (index === 1 && trackingData?.rsvattcode === "1W") return "One Way";
    else if (index === 1 && trackingData?.rsvattcode === "FE")
      return "Front End Way";
    else if (index === 1 && trackingData?.rsvattcode === "BE")
      return "Back End Way";
    else if (trackingData?.rsvattcode === "RT") {
      if (index === 1 || trackingData?.tripEndButtonStatus === "HALT")
        return "Front Way Trip";
      else if (
        index === 2 ||
        trackingData?.tripEndButtonStatus === "E_ROUND_TRIP"
      )
        return "Back Way Trip";
    }
  };

  const getAddress = (address1, address2) =>
    `${address1 || ""} ${address2 || ""}`.trim();

  const contractorMovingGif_WithMessage = (message, type) => {
    let imgSrc = "";
    let imgClassName = "";

    if (type === "enroute") {
      imgSrc = movingcar;
      imgClassName = "h-[60px] w-[250px]";
    } else if (type === "waiting") {
      imgSrc = contractorWaiting;
      imgClassName = "h-[100px]";
    }

    return (

      <div className="flex items-center gap-2">
        <span className="text-[#63b0e0] text-sm">{message}</span>
        <span className="mb-[1rem] mt-[1rem]">
          <img
            src={imgSrc}
            className={imgClassName}
            alt={`contractor ${type}`}
          />
        </span>
      </div>
    );
  };

  const deadMilesTrip = () => {
    return (
      <>
        {index === 0 && currentButtonId[0] >= 1 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            <span> Driver Started From their Location </span>
            <textarea
              name="driverStartedFromtheirLocation"
              id="driverStartedFromtheirLocation"
              value={contractorStartedLocation || "N/A"}
              readOnly
              className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 0 && currentButtonId[0] >= 1 ? (
          <>
            <label className="grid grid-cols-2 mt-3 items-center">
              <span>Driver Started Time</span>
              <Input
                name="driverStartedTime"
                id="driverStartedTime"
                value={getTimeFromString(trackingData?.startDateandTime)}
                readOnly
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
              />
            </label>
            {currentButtonId[0] < 2 &&
              contractorMovingGif_WithMessage(
                "Contractor is en route to pick up the claimant",
                "enroute"
              )}
          </>
        ) : (
          ""
        )}

        {index === 0 && currentButtonId[0] >= 2 && (
          <label className="grid grid-cols-2 mt-3 items-center">
            <span>
              {index === 0 ? (
                <span>Driver Reached Pick Up Location</span>
              ) : (
                <span>Pick Up Location</span>
              )}
            </span>
            <textarea
              placeholder="Pick Up Location"
              name="pickUpLocation"
              id="pickUpLocation"
              value={getAddress(
                trackingData?.rsvattcode !== "BE"
                  ? trackingData?.puAddress1 + " " + trackingData?.puAddress2
                  : trackingData?.doAddress1 + " " + trackingData?.doAddress2
              )}
              readOnly
              className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        )}

        {index === 0 && currentButtonId[0] >= 2 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            <span>Driver Reached Time</span>
            <Input
              name="driverReachedTime"
              id="driverReachedTime"
              value={getTimeFromString(trackingData?.reachedDateandTime)}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 0 && currentButtonId[0] >= 2 ? (
          <>
            <label className="grid grid-cols-2 mt-3 items-center">
              Dead Miles
              <Input
                name="deadMile"
                id="deadMile"
                value={`${trackingData?.deadMiles ?? 0} miles`}
                readOnly
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
              />
            </label>
            {currentButtonId[0] < 3 &&
              contractorMovingGif_WithMessage(
                "Contractor reached pick up location. Waiting for the claimant",
                "waiting"
              )}
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  // Extracting the logic outside the JSX
  let dropoffLocationAddress = "";
  if (trackingData?.tripEndPickedupDateandTime) {
    if (trackingData?.rsvattcode !== "BE") {
      dropoffLocationAddress = getAddress(
        trackingData?.doAddress1 + " " + trackingData?.doAddress2
      );
    } else {
      dropoffLocationAddress = getAddress(
        trackingData?.puAddress1 + " " + trackingData?.puAddress2
      );
    }
  }

  const frontWayTrip = () => {
    return (
      <>
        {index === 1 && currentButtonId[1] >= 1 && (
          <label
            className="grid grid-cols-2 mt-3 items-center"
            htmlFor="pickUpLocation"
          >
            <span>Pick Up Location</span>
            <textarea
              name="pickUpLocation"
              id="pickUpLocation"
              value={getAddress(
                trackingData?.rsvattcode !== "BE"
                  ? trackingData?.puAddress1 + " " + trackingData?.puAddress2
                  : trackingData?.doAddress1 + " " + trackingData?.doAddress2
              )}
              readOnly
              className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        )}

        {index === 1 && currentButtonId[0] >= 3 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            <span>Claimant Waiting Time</span>
            <Input
              name="claimantWaitingTime"
              id="claimantWaitingTime"
              value={getTimeDifference(
                trackingData?.reachedDateandTime,
                trackingData?.claimantPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 1 && currentButtonId[0] >= 3 && (
          <label className="grid grid-cols-2 mt-3 items-center">
            <span> Claimant On-boarded Time</span>
            <Input
              name="claimantOnboardedTime"
              id="claimantOnboardedTime"
              value={getTimeFromString(
                trackingData?.claimantPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        )}

        {index === 1 && currentButtonId[0] >= 3 && (
          <>
            <label className="grid grid-cols-2 mt-3 items-center">
              Trip Started
              <Input
                name="tripStarted"
                id="tripStarted"
                value={getTimeFromString(
                  trackingData?.claimantPickedupDateandTime
                )}
                readOnly
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
              />
            </label>
            {currentButtonId[0] < 4 &&
              contractorMovingGif_WithMessage(
                "Claimant is on-boarded. Contractor is en route to drop off location",
                "enroute"
              )}

            {index === 1 && currentButtonId[0] >= 4 && (
              <label className="grid grid-cols-2 mt-3 items-center">
                Trip Ended
                <Input
                  name="tripEnded"
                  id="tripEnded"
                  value={getTimeFromString(
                    trackingData?.tripEndPickedupDateandTime
                  )}
                  readOnly
                  className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                />
              </label>
            )}

            {index === 1 && currentButtonId[0] >= 4 && (
              <label className="grid grid-cols-2 mt-3 items-center">
                <span>Driver Reached Drop-off Location</span>
                <textarea
                  name="driverReachedDropoffLocation"
                  id="driverReachedDropoffLocation"
                  value={dropoffLocationAddress}
                  readOnly
                  className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                />
              </label>
            )}

            {index === 1 && currentButtonId[0] >= 4 && (
              <label className="grid grid-cols-2 mt-3 items-center">
                Travel Time
                <Input
                  name="travelTime"
                  id="travelTime"
                  value={getTimeDifference(
                    trackingData?.claimantPickedupDateandTime,
                    trackingData?.tripEndPickedupDateandTime
                  )}
                  readOnly
                  className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                />
              </label>
            )}

            {index === 1 && currentButtonId[0] >= 4 && (
              <>
                <label className="grid grid-cols-2 mt-3 items-center">
                  Actual Miles
                  <Input
                    name="actualMiles"
                    id="actualMiles"
                    value={`${trackingData?.travellingMiles ?? 0} miles`}
                    readOnly
                    className=" focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </label>
                {index === 1 &&
                  currentButtonId[1] === 4 &&
                  trackingData?.rsvattcode === "RT" &&
                  !currentButtonId[2] &&
                  contractorMovingGif_WithMessage(
                    "Contractor reached drop off location. Waiting for claimant.",
                    "waiting"
                  )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const backWayTrip = () => {
    return (
      <>
        {index === 2 && currentButtonId[2] >= 1 && (
          <label className="grid grid-cols-2 mt-3 items-center" htmlFor="pickUpLocation">
            <span>Pick Up Location</span>
            <textarea
              name="pickUpLocation"
              id="pickUpLocation"
              value={getAddress(
                trackingData?.doAddress1,
                trackingData?.doAddress2
              )}
              readOnly
              className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        )}

        {index === 2 && currentButtonId[2] >= 3 && (
          <label className="grid grid-cols-2 mt-3 items-center" htmlFor="claimantOnboardedTime">
            <span> Claimant On-boarded Time</span>
            <Input
              name="claimantOnboardedTime"
              id="claimantOnboardedTime"
              value={getTimeFromString(
                trackingData?.claimantPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        )}

        {index === 2 && currentButtonId[2] >= 1 && (
          <>
            <label className="grid grid-cols-2 mt-3 items-center" htmlFor="tripStarted">
              Trip Started
              <Input
                name="tripStarted"
                id="tripStarted"
                value={getTimeFromString(
                  trackingData?.claimantPickedupDateandTime
                )}
                readOnly
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
              />
            </label>
            {currentButtonId[2] < 4 &&
              contractorMovingGif_WithMessage(
                "Claimant is on-boarded from facility. Contractor is en route to drop claimant.",
                "enroute"
              )}
          </>
        )}

        {index === 2 && currentButtonId[2] >= 2 && (
          <label className="grid grid-cols-2 mt-3 items-center" htmlFor="tripEnded">
            Trip Ended
            <Input
              name="tripEnded"
              id="tripEnded"
              value={getTimeFromString(
                trackingData?.tripEndPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        )}

        {index === 2 && currentButtonId[2] >= 2 && (
          <label className="grid grid-cols-2 mt-3 items-center" htmlFor="contractorReachedDropoffLocation">
            <span>Driver Reached Drop-off Location</span>
            <textarea
              name="contractorReachedDropoffLocation"
              id="contractorReachedDropoffLocation"
              value={
                trackingData?.tripEndPickedupDateandTime
                  ? getAddress(
                      trackingData?.puAddress1,
                      trackingData?.puAddress2
                    )
                  : "N/A"
              }
              readOnly
              className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        )}

        {index === 2 && currentButtonId[2] >= 2 && (
          <label className="grid grid-cols-2 mt-3 items-center" htmlFor="travelTime">
            Travel Time
            <Input
              name="travelTime"
              id="travelTime"
              value={getTimeDifference(
                trackingData?.claimantPickedupDateandTime,
                trackingData?.tripEndPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        )}

        {index === 2 && currentButtonId[2] >= 4 && (
          <>
            <label className="grid grid-cols-2 mt-3 items-center" htmlFor="actualMiles">
              Actual Miles
              <Input
                name="actualMiles"
                id="actualMiles"
                value={`${trackingData?.travellingMiles ?? 0} miles`}
                readOnly
                className=" focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
              />
            </label>
            {index === 1 &&
              currentButtonId[1] === 4 &&
              trackingData?.rsvattcode === "RT" &&
              !currentButtonId[1] &&
              contractorMovingGif_WithMessage(
                "Contractor reached drop off location. Waiting for claimant.",
                "waiting"
              )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className=" justify-center font-semibold text-xl mb-2">
        {assignmentHistoryTitle()}
      </div>
      <>
        {deadMilesTrip()}
        {trackingData?.claimantPickedupButtonStatus === "START_TRIP" &&
          frontWayTrip()}
        {backWayTrip()}
      </>
    </>
  );
};

TransportAssignmentHistory.propTypes = {
  currentbuttonId: PropTypes.arrayOf(PropTypes.number),
  fetchLocationName: PropTypes.func,
  trackingData: PropTypes.shape({
    startDriverLatitudeLongitude: PropTypes.string,
    startDateandTime: PropTypes.string,
    reachedDateandTime: PropTypes.string,
    rsvattcode: PropTypes.string,
    tripEndButtonStatus: PropTypes.string,
    puAddress1: PropTypes.string,
    puAddress2: PropTypes.string,
    doAddress1: PropTypes.string,
    doAddress2: PropTypes.string,
    deadMiles: PropTypes.number,
    claimantPickedupDateandTime: PropTypes.string,
    tripEndPickedupDateandTime: PropTypes.string,
    travellingMiles: PropTypes.number,
    claimantPickedupButtonStatus: PropTypes.string,
  }),
  index: PropTypes.number,
};


export default TransportAssignmentHistory;
