import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  settingsList: [],
  settings: {},
};

export const SettingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setSettingsList: (state, action) => {
      // Check if action.payload is an array, if not, wrap it in an array
      const payloadArray = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      state.settingsList = [...state.settingsList, ...payloadArray];
    },
  },
});

export const { setSettings, setSettingsList } = SettingSlice.actions;

export default SettingSlice.reducer;