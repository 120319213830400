import React, { useEffect, useState } from "react";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setClaimsFilter, updateClaimFilters } from "../../../../Redux-Toolkit/ClaimsSlice";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import InputSearchFilterDropDown from "../../../../Common/ui/inputSearchFilterDropDown";

const ClaimsFilter = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const claimFilters = useSelector((state) => state?.claims?.filterData) || [];

  const initializeState = (filter) => ({
    claimID: filter?.claimID || 0,
    claimantID: filter?.claimantID || 0,
    claimantName: filter?.claimantName || "",
    claimNumber: filter?.claimNumber || "",
    customerID: filter?.customerID || 0,
    customerName: filter?.customerName || "",
    birthdate: filter?.birthdate || "",
    page: filter?.page || 1,
  });

  const initialState = initializeState(claimFilters);
  const [claimID, setClaimID] = useState(initialState.claimID);
  const [claimantID, setClaimantID] = useState(initialState.claimantID);
  const [selectedClaimantName, setSelectedClaimantName] = useState(initialState.claimantName);
  const [selectedCustomerName, setSelectedCustomerName] = useState(initialState.customerName);
  const [claimantName, setClaimantName] = useState(initialState.claimantName);
  const [claimNumber, setClaimNumber] = useState(initialState.claimNumber);
  const [customerID, setCustomerID] = useState(initialState.customerID);
  const [customerName, setCustomerName] = useState(initialState.customerName);
  const [birthdate, setBirthdate] = useState(initialState.birthdate);
  const [page, setPage] = useState(initialState.page);

  useEffect(() => {
    updateStateFromFilter(claimFilters);
  }, [claimFilters]);

  const updateStateFromFilter = (filter) => {
    setClaimID(filter?.claimID);
    setClaimantID(filter?.claimantID);
    setClaimantName(filter?.claimantName);
    setClaimNumber(filter?.claimNumber);
    setSelectedCustomerName(filter?.customerName);
    setSelectedClaimantName(filter?.claimantName);
    setCustomerID(filter?.customerID);
    setCustomerName(filter?.customerName);
    setBirthdate(filter?.birthdate);
    setPage(filter?.page);
  };

  /**
   * Reset the filter values
   */

  const handleReset = () => {
    resetState();
    dispatch(setClaimsFilter(createFilterData()));
    navigate("/claims-management");
  };

  const resetState = () => {
    setClaimID(0);
    setClaimNumber("");
    setCustomerID(0);
    setSelectedCustomerName("");
    setClaimantID(0);
    setSelectedClaimantName("");
    setClaimantName("");
    setCustomerName("");
    setBirthdate("");
  }

  const createFilterData = () => ({
    claimID: 0,
    claimantID: 0,
    claimNumber: "",
    customerID: 0,
    birthdate: "",
    page: page,
    limit: 20,
  });

  return (

    <div className="card bg-base-100 shadow-md p-6 mt-3 mb-3">
      <form
        onSubmit={(e) => {
          const data = {
            ...(claimID &&
              !isNaN(Number(claimID)) && { claimID: Number(claimID) }),
            ...(claimNumber && claimNumber.trim() !== "" && { claimNumber }),
            ...(customerID &&
              !isNaN(Number(customerID)) && { customerID: Number(customerID) }),
            ...(claimantID &&
              !isNaN(Number(claimantID)) && { claimantID: Number(claimantID) }),
            ...(claimantName && claimantName.trim() !== "" && { claimantName }),
            ...(customerName && customerName.trim() !== "" && { customerName }),
            ...(birthdate && birthdate.trim() !== "" && { birthdate }),
            page: 1,
            limit: 20,
          };
          dispatch(setClaimsFilter(data)); // to update the filter states in store to persist filter values
          dispatch(updateClaimFilters({
            claimantName: selectedClaimantName,
            customerName: selectedCustomerName
          }));
          navigate("?page=1");

          e.preventDefault();
        }}
      >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          <div>
            <label
              htmlFor="claimid"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim ID
            </label>
            <input
              name="claimid"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setClaimID(Number(value));
                }
              }}
              value={claimID === 0 ? "" : claimID}
              placeholder="Claim ID"
              id="claimid"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          </div>
          <div>
            <label
              htmlFor="claimnumber"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim Number
            </label>
            <input
              name="claimnumber"
              onChange={(e) => setClaimNumber(e.target.value)}
              value={claimNumber}
              placeholder="Claim Number"
              id="claimnumber"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          </div>
          <div>
            <label
              htmlFor="claimantid"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claimant ID
            </label>
            <input
              name="claimantid"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setClaimantID(Number(value));
                }
              }}
              value={claimantID == 0 ? "" : claimantID}
              placeholder="Claimant ID"
              id="claimantid"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          </div>

          <div className="relative">
            <InputSearchFilterDropDown
              className={"claimant-search"}
              labelName={"Claimant"}
              url="/AllCLaimant"
              payloadName={"claimantName"}
              setSearchList={(selectedOption) => {
                setClaimantID(selectedOption?.claimantID);
                setSelectedClaimantName(selectedOption?.fullName);
              }}
              defaultSelectedValue={selectedClaimantName}
            />
          </div>

          <div className="relative">
            <InputSearchFilterDropDown
              className={"customer-search"}
              labelName={"Customer"}
              url="/AllCustomer"
              payloadName={"customerName"}
              data={[]}
              setSearchList={(selectedOption) => {
                setCustomerID(selectedOption?.customerID);
                setSelectedCustomerName(selectedOption?.fullName);
              }}
              defaultSelectedValue={selectedCustomerName}
            />
          </div>

          <div>
            <label
              htmlFor="dob"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              DOB
            </label>
            <input
              name="dob"
              onChange={(e) =>
                setBirthdate(format(new Date(e.target.value), "yyyy-MM-dd"))
              }
              value={birthdate}
              id="dob"
              type="date"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          </div>
        </div>
        <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
          <button
            type="reset"
            onClick={() => {
              handleReset();
            }}
            className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            <MdOutlineRefresh className="mr-1" />
            Reset
          </button>
          <button
            className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4"
            onClick={() => navigate("?page=1")}
            data-testid="search-button"
          >
            <MdOutlineSearch className="mr-1" />
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClaimsFilter;
