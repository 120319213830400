import React from "react";
import RatingReview from "../RatingReview";
import PropTypes from "prop-types";

const AssignmentClaimantReviews = ({
  isFeedbackComplete,
  claimantFeedbackData,
  feedbackQuestion,
}) => {
  return (
    <>
      {isFeedbackComplete === 1 && (
        <>
          <div className=" p-6 flex justify-start">
            <div>
              <p className=" text-xl font-semibold">Rating & Review </p>
            </div>
          </div>

          <div
            className=" flex card bg-base-100 shadow-md p-6 mt-2"
            style={{ flexDirection: "column" }}
          >
            {isFeedbackComplete === 1 &&
            Object.keys(claimantFeedbackData).length !== 0 ? (
              <>
                <span className="grid grid-cols-2 mt-3 items-center font-semibold">
                  Feedback
                </span>
                <div>
                  {feedbackQuestion.map((q) => (
                    <div key={q.id} className="mt-3">
                      <label className="grid grid-cols-2 items-center">
                        {q.question}
                        <div className="flex">
                          <RatingReview
                            rating={claimantFeedbackData[`answer${q.id}`]}
                          />
                        </div>
                      </label>
                    </div>
                  ))}
                </div>

                <label className="grid grid-cols-2 mt-10 items-center font-semibold">
                  <span>Comments</span>
                  <textarea
                    className="textarea textarea-bordered cursor-default "
                    value={
                      claimantFeedbackData?.notes ||
                      "No comments provided by claimant"
                    }
                    readOnly
                  />
                </label>
              </>
            ) : (
              <div className="text-xl font-semibold m-auto">TBA</div>
            )}
          </div>
        </>
      )}
    </>
  );
};

AssignmentClaimantReviews.propTypes = {
  isFeedbackComplete: PropTypes.number,
  claimantFeedbackData: PropTypes.array, // Assuming this is an object
  feedbackQuestion: PropTypes.arrayOf(PropTypes.shape({ // Array of objects
    id: PropTypes.number.isRequired, // Assuming id is a number
    question: PropTypes.string.isRequired // Assuming question is a string
  })).isRequired
};

export default AssignmentClaimantReviews;
