import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowFcmNoticationStatusBar } from "../../Redux-Toolkit/NotificationSlice";
import { postLogin } from "../../ApiMethods/Api";
import { generateFcmToken, requestNotificationPermission } from "../../Firebase/NotificationFCM";

const FcmNotificationEnabled = () => {

  const dispatch = useDispatch();
  const notificationPermission = useSelector((state) => state?.notifications?.notificationPermission);
  const showNotificationStatusBar = useSelector((state) => state?.notifications?.showFcmNoticationStatusBar);

  const fcmTokenSent = useSelector((state) => state?.auth?.fcmTokenSent);
  const userID = useSelector((state) => state?.auth?.userID);
  const device_id = useSelector((state) => state?.auth?.device_id);
  const fcmToken = useSelector((state) => state?.auth?.fcmToken);

  const handleNotificationStatusBar = () => {
    dispatch(setShowFcmNoticationStatusBar(false));
  };

  //-------------------- section to update fcmToken once we get it -------------//

  const webFcmTokenUpdate = useCallback(async () => {
    if (!userID || !device_id || !fcmToken) {
      return;
    }
    const body = {
      userID: userID || "",
      userDeviceID: device_id || "",
      userFcmToken: fcmToken || "",
    };
    try {
      const res = await postLogin("/webFcmUpdate", body);
      if (res.status === 200) {
        console.log("FCM token updated successfully");
      } else {
        console.error("Failed to update FCM token");
      }
    } catch (error) {
      console.error("Error updating FCM token:", error);
    }
  }, [userID, device_id, fcmToken]);

  useEffect(() => {
    if (!fcmTokenSent) {

      requestNotificationPermission(dispatch);
      generateFcmToken(device_id, dispatch);
      webFcmTokenUpdate();
    }
  }, [fcmToken, fcmTokenSent, device_id, dispatch, webFcmTokenUpdate]);

  //-------------------------- web fcm token update section end --------------------------//

  return (

    <>
      {" "}
      {!notificationPermission && showNotificationStatusBar && (
        <div className="status-bar mdcal w-full border-b sticky top-[125px] z-[10000] min-h-[60px] max-h-[80px] h-auto pb-[10px] flex justify-between items-center bg-[#444443] border-[#444443] rounded-sm px-[3rem]">
          <div className="text-[white]">
            <p>
              Your notifications are not enabled. You will not receive real time
              notification count and notifications for assignments and related
              updates. Kindly enable notifications to stay updated.
            </p>
            <p></p>
          </div>
          <div className="ml-[2rem]">
            <button
              type="reset"
              onClick={handleNotificationStatusBar}
              className="btn btn-sm btn-circle border-[#8394aa] text-[white]"
              aria-label="Close"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FcmNotificationEnabled;
