import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { postMethodData } from "../../ApiMethods/Api";

// Utility function to debounce API calls
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const InputSearchFilterDropDown = ({
  url,
  payloadName,
  setSearchList,
  defaultSelectedValue,
  labelName,
  ...props
}) => {

  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (defaultSelectedValue) {
      setSelectedOption({ label: defaultSelectedValue, value: defaultSelectedValue });
    } else {
      setSelectedOption(null);
    }
  }, [defaultSelectedValue]);

  const handleChange = (option) => {
    setSelectedOption(option);
    if (option) {
      setSearchList(option.value); // Pass the selected value
    } else {
      setSearchList(null); // Handle case when the selection is cleared
    }
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    return newValue;
  };

  const loadOptions = useCallback(
    debounce((inputValue, callback) => {
      if (!inputValue || inputValue.length < 3) {
        return callback([]); // Start searching after 2 characters
      }

      postMethodData(url, {
        [payloadName]: inputValue,
      })
        .then((response) => {
          if (Array.isArray(response.data.data)) {
            // Ensure response data is an array
            const options = response.data.data.map((item) => ({
              label: item.fullName, // Use fullName as the label
              value: item, // Use the entire item as the value
            }));
            callback(options);
          } else {
            callback([]);
          }
        })
    }, 1000), // 1000ms debounce time
    [url, payloadName]
  );

  return (
    <div>
      <label
        htmlFor={props['data-testid']}
        className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
      >
        {labelName}
      </label>
      <AsyncSelect
        id={props['data-testid']}
        data-testid={props['data-testid']}
        aria-labelledby={`${props['data-testid']}-label`}  // Associate with the label via aria-labelledby
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        value={selectedOption}
        onChange={handleChange}
        onInputChange={handleInputChange}
        classNamePrefix="react-select"
        placeholder="Search..."
        noOptionsMessage={() =>
          (inputValue?.length < 3) ? "Enter at least 3 characters" : "No Data Found"
        }
        className="w-full text-sm font-medium rounded-md h-[2.3rem]"
        isClearable
      />
    </div>
  );
};

InputSearchFilterDropDown.propTypes = {
  url: PropTypes.string,
  payloadName: PropTypes.string,
  setSearchList: PropTypes.func,
  labelName: PropTypes.string,
  className: PropTypes.string,
  defaultSelectedValue: PropTypes.string,
  props: PropTypes.string,
};

export default InputSearchFilterDropDown;
