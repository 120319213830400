import React, { useEffect, useState } from "react";
import GoogleMapComponent from "../GoogleMapComponent";
import { Input } from "react-daisyui";
import { formatDate, getTimeFromString } from "../../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { setAssignmentTrackingDataWithDeadMiles } from "../../../../Redux-Toolkit/AssignmentTrackingStatsSlice";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";

const AssignmentTrackHistory = ({

  isAssignmentTracking,
  assignmentTrackingData,
  assignmentHistory,
  checkAllowedMap,
  deadMiles,
  pickupLatLong,
  dropoffLatLong,
  claimantPickedupLatLng,
  assignmentDetails,
  assignmentDueDate,
  assignmentDueTime,
  assignmentCancelled,
  createDate,
}) => {  
  const dispatch = useDispatch();
  const assignmentTrackingRecord = useSelector(
    (state) => state?.assignmentTrackingStats?.assignmentTrackingData
  );

  const [updatedTrackingData, setUpdatedTrackingData] = useState(null);

  // Determine if map should be shown
  const mapShowCondition = assignmentTrackingData?.some((data) =>
    ["TRANSLATE", "TRANSPORT", "INTERPRET", "TRANSINTERP"].includes(data?.assgncode)
  );

  useEffect(() => {
    if (mapShowCondition) {
      const deadMilesData = extractDeadMilesData(assignmentTrackingRecord);
      const frontTripData = extractFrontTripData(assignmentTrackingRecord);
      const otherData = extractOtherData(assignmentTrackingRecord);
  
      const combinedData = [deadMilesData, frontTripData, ...otherData];
      updateTrackingData(combinedData);
    } 
    // else {
    //   updateTrackingData(assignmentTrackingData);
    // }
  }, [assignmentTrackingRecord, mapShowCondition]);

  // Helper function to extract dead miles data
  const extractDeadMilesData = (trackingRecord) => {
    return {
      reservationsAssignmentsID: trackingRecord[0]?.reservationsAssignmentsID,
      assgncode: trackingRecord[0]?.assgncode,
      rsvattcode: trackingRecord[0]?.rsvattcode,
      resAsgnCode: trackingRecord[0]?.resAsgnCode,
      language: trackingRecord[0]?.language,
      claimantLanguage1: trackingRecord[0]?.claimantLanguage1,
      claimantLanguage2: trackingRecord[0]?.claimantLanguage2,
      assignmentTrackingID: trackingRecord[0]?.assignmentTrackingID,
      claimantPickedupButtonStatus: trackingRecord[0]?.claimantPickedupButtonStatus,
      startButtonStatus: trackingRecord[0]?.startButtonStatus,
      startDriverLatitudeLongitude: trackingRecord[0]?.startDriverLatitudeLongitude,
      startDateandTime: trackingRecord[0]?.startDateandTime,
      reachedButtonStatus: trackingRecord[0]?.reachedButtonStatus,
      reachedDriverLatitudeLongitude: trackingRecord[0]?.reachedDriverLatitudeLongitude,
      reachedDateandTime: trackingRecord[0]?.reachedDateandTime,
      currentButtonID: trackingRecord[0]?.currentButtonID,
      puAddress1: trackingRecord[0]?.puAddress1,
      puAddress2: trackingRecord[0]?.puAddress2,
      doAddress1: trackingRecord[0]?.doAddress1,
      doAddress2: trackingRecord[0]?.doAddress2,
      deadMiles: trackingRecord[0]?.deadMiles,
      deadMileImageUrl: trackingRecord[0]?.deadMileImageUrl,
      ...(trackingRecord?.length && trackingRecord[0]?.waitingRecordsList[0] && {
        waitingRecordsList: [trackingRecord[0]?.waitingRecordsList[0]],
      }),
    };
  };
  
  // Helper function to extract front trip data
  const extractFrontTripData = (trackingRecord) => {
    return {
      ...trackingRecord[0],
      waitingRecordsList: trackingRecord[0].waitingRecordsList.slice(1),
    };
  };
  
  // Helper function to extract other data
  const extractOtherData = (trackingRecord) => {
    return trackingRecord.slice(1);
  };
  
  // Helper function to update tracking data and dispatch action
  const updateTrackingData = (data) => {
    setUpdatedTrackingData(data);
    dispatch(setAssignmentTrackingDataWithDeadMiles(data));
  };

  const renderGoogleMapComponent = ({
    index,
    trackingData,
    pickupLatLong,
    dropoffLatLong,
    claimantPickedupLatLng,
    assignmentDetails,
  }) => {
    return (
      <GoogleMapComponent
        index={index}
        assignmentTrackingData={trackingData}
        pickupLatLong={pickupLatLong}
        dropoffLatLong={dropoffLatLong}
        startDriverLatitudeLongitude={
          trackingData?.startDriverLatitudeLongitude
        }
        claimantPickedupLatitudeLongitude={
          trackingData?.claimantPickedupLatitudeLongitude
        }
        {...(claimantPickedupLatLng && {
          lat: claimantPickedupLatLng?.latitude,
          lng: claimantPickedupLatLng?.longitude,
        })}
        triptype={assignmentDetails?.rsvattcode}
        reachedDriverLatitudeLongitude={
          trackingData?.reachedDriverLatitudeLongitude
        }
        tripEndPickedupLatitudeLongitude={
          trackingData?.tripEndPickedupLatitudeLongitude
        }
      />
    );
  };

  const transportServiceConditions = (trackingData, index) => {
    switch (trackingData?.rsvattcode) {
      case "1W":
        return (
          (index === 0 && trackingData?.startButtonStatus === "START") ||
          (index === 1 &&
            trackingData?.claimantPickedupButtonStatus === "START_TRIP")
        );
      case "RT":
        return (
          (index === 0 && trackingData?.startButtonStatus === "START") ||
          (index === 1 &&
            trackingData?.claimantPickedupButtonStatus === "START_TRIP") ||
          (index === 2 &&
            trackingData?.claimantPickedupButtonStatus === "S_ROUND_TRIP")
        );
      default:
        return false;
    }
  };

  const translationServiceConditions = (trackingData, index) => {
    return (
      (index === 0 && trackingData?.startButtonStatus === "START") ||
      (index === 1 &&
        trackingData?.claimantPickedupButtonStatus === "START_SESSION")
    );
  };

  const transInterpreterServiceConditions = (trackingData, index) => {
    return (
      (index === 0 && trackingData?.startButtonStatus === "START") ||
      (index === 1 &&
        trackingData?.claimantPickedupButtonStatus === "START_TRIP") ||
      (index === 2 &&
        trackingData?.claimantPickedupButtonStatus === "START_SESSION") ||
      (index === 3 &&
        trackingData?.claimantPickedupButtonStatus === "S_ROUND_TRIP")
    );
  };

  const assignmentStatsCondition_Check = (trackingData, index) => {
    const res =
      transportServiceConditions(trackingData, index) ||
      translationServiceConditions(trackingData, index) ||
      transInterpreterServiceConditions(trackingData, index);

    return res;
  };

  const additionalWaitingTime = (trackingData) => {
    return (
      <>
        {trackingData?.waitingRecordsList &&
          trackingData?.waitingRecordsList?.length > 0 && (
            <div className=" mt-10 font-semibold mb-5 text-lg">
              Additional Waiting Time
            </div>
          )}
        {trackingData?.waitingRecordsList &&
          trackingData?.waitingRecordsList?.length > 0 &&
          trackingData?.waitingRecordsList.map((waitingRecord, index) => {
            return (
              <div key={nanoid()}>
                <div className="mb-4 mt-4">
                  <label
                    htmlFor={`location1`}
                    className="text-md font-semibold text-zinc-700 dark:text-stone-400 "
                  >
                    {`Waiting Time ${index + 1}`}
                  </label>
                </div>
                <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 lg:gap-8 md:gap-x-[60px] gap-4">
                  <div>
                    <label
                      htmlFor="startTime"
                      className="block text-sm font-semibold text-zinc-700 dark:text-stone-400"
                    >
                      Start Time
                    </label>
                    <Input
                      name="startTime"
                      id="startTime"
                      value={
                        waitingRecord?.isManuallyEntered === 0
                          ?
                          getTimeFromString(waitingRecord?.startDateandTime)
                          : "Manual Enter by Metrics"
                      }
                      readOnly
                      className="input focus:outline-0 text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="endTime"
                      className="block text-sm font-semibold text-zinc-700 dark:text-stone-400"
                    >
                      End Time
                    </label>
                    <Input
                      name="endTime"
                      id="endTime"
                      value={
                        waitingRecord?.isManuallyEntered === 0
                          ? getTimeFromString(waitingRecord?.endDateandTime)
                          : "Manual Enter by Metrics"
                      }
                      readOnly
                      className="input focus:outline-0 text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="totalTime"
                      className="block text-sm font-semibold text-zinc-700 dark:text-stone-400"
                    >
                      Total Time
                    </label>

                    <Input
                      name="totalTime"
                      id="totalTime"
                      value={waitingRecord?.timeInterval}
                      readOnly
                      className="input focus:outline-0 text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="comments"
                      className="block text-sm font-semibold text-zinc-700 dark:text-stone-400"
                    >
                      Comments
                    </label>
                    <textarea
                      name="comments"
                      id="comments"
                      value={waitingRecord?.comments || "N/A"}
                      readOnly
                      className="max-h-[10em] textarea textarea-bordered input focus:outline-0 text-sm font-medium rounded-md focus:outline-offset-0 cursor-default"
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  };
  let content;

  if (isAssignmentTracking === 1) {
    if (updatedTrackingData && updatedTrackingData.length > 0) {
      content = updatedTrackingData.map((trackingData, index) => {
        if (assignmentStatsCondition_Check(trackingData, index)) {
          return (
            <div
              key={nanoid()}
              className="flex card bg-base-100 shadow-md p-6 mt-2"
              style={{ flexDirection: "column" }}
            >
              <div className="flex flex-row justify-between">
                <div className="w-[50%]">
                  {assignmentHistory(trackingData, index, deadMiles)}
                </div>
                {checkAllowedMap(index) && (
                  <div className="w-[35%]">
                    <div className="mt-1 font-semibold text-xl mb-2">
                      {index === 0 ? (
                        <p>Enrouting Dead Miles Path Detail</p>
                      )
                        : <p>Enrouting Path Detail</p>
                      }
                    </div>
                    <div
                      className="rounded-2xl border-2 border-[#63b0e0]"
                      style={{
                        width: "100%"
                      }}
                    >
                      {Object.keys(trackingData).length > 0 &&
                        ((mapShowCondition &&
                          ((index === 0 && trackingData?.deadMileImageUrl) ||
                            trackingData?.currentButtonID >= 4 ||
                            trackingData?.imageUrl)) ||
                          trackingData?.imageUrl) ? (
                        <img
                          src={
                            index === 0
                              ? trackingData?.deadMileImageUrl
                              : trackingData?.imageUrl
                          }
                          alt="assignment completed map"
                          style={{
                            height: "50%",
                            width: "100%",
                            objectFit: "contain",
                            borderRadius: "1rem",
                          }}
                        />
                      ) : (
                        <div>
                          {renderGoogleMapComponent({
                            index,
                            trackingData,
                            pickupLatLong,
                            dropoffLatLong,
                            claimantPickedupLatLng,
                            assignmentDetails,
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div>{additionalWaitingTime(trackingData)}</div>
            </div>
          );
        }
        return null;
      });
    }
  }

  else if (formatDate(createDate) <= "09-18-2024" && assignmentCancelled !== -1) {
    content = (
      <p className="text-[red] text-center mb-[1rem]">
        No Data Found. Check Intime.
      </p>
    )
  }

  else if (assignmentCancelled !== -1) {
    content = (
      <p className="text-[red] text-center mb-[1rem]">
        Assignment Due:{" "}
        <span className="mr-1">
          {`${assignmentDueDate} at ${assignmentDueTime}`}
        </span>
        {(assignmentDueDate === "Expired" || assignmentDueDate === "Yesterday")
          ? ""
          : ". The contractor has not started yet."
        }
      </p>
    );
  }

  else {
    content = "";
  }

  return (
    assignmentDetails?.rsvacCode !== "ResCancel" &&
    assignmentDetails?.actionCode !== "Reservation Cancelled" &&
    assignmentCancelled !== -1 && (
      <div>
        <div className="p-6 flex justify-start">
          <div>
            <p className="text-xl font-semibold">Assignment Stats</p>
          </div>
        </div>
        {content}
      </div>
    )
  );
};

AssignmentTrackHistory.propTypes = {
  assignmentHistory: PropTypes.func, // or the correct shape
  isAssignmentTracking: PropTypes.number,
  assignmentTrackingData: PropTypes.array,
  // assignmentHistory: PropTypes.array,
  checkAllowedMap: PropTypes.func,
  deadMiles: PropTypes.array,
  pickupLatLong: PropTypes.object,
  dropoffLatLong: PropTypes.object,
  claimantPickedupLatLng: PropTypes.object,
  assignmentDetails: PropTypes.object,
  assignmentDueDate: PropTypes.string,
  assignmentDueTime: PropTypes.string,
  assignmentCancelled: PropTypes.number,
  createDate: PropTypes.string,
};

export default AssignmentTrackHistory;