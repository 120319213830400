import PropTypes from "prop-types";
import React from "react";
import Chart from "react-apexcharts";

const BarGraphChart = ({ data, name }) => {
  const graphName = {
    "Reservations By Month": "RESERVATIONS",
    "Claimant By Month": "CLAIMANTS",
    "Contractor By Month": "CONTRACTORS",
  };

  // Check if data is valid
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <div>No data available</div>; // Fallback UI if data is invalid
  }

  // Extract categories and series data
  const categories = data.map((item) => item.month || item.group);
  const seriesData = data.map((item) => item.value);

  const options = {
    chart: {
      type: "bar",
      height: 400,
    },
    noData: {
      text: 'No Data Available',
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#63b0e0',
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 4,
        borderRadiusApplication: 'end',
        dataLabels: {
          position: 'top', // Show data labels on top of the bars
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0); // Ensure the labels are integers
      },
      offsetY: -20, // Adjust the position of the data labels
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#63b0e0"],
    xaxis: {
      categories: categories,
    },
    yaxis: {
      min: 0,
      title: {
        tickAmount: 5, // Adjust the number of ticks on the y-axis
        text: graphName[name] || "Values",
        style: {
          color: "#63b0e0", // Text color
          fontSize: "14px", // Font size
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600, // Font weight
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    export: {
      csv: {
        filename: `${graphName[name] || "Data"}_export`,
        columnDelimiter: ',',
        headerCategory: 'Month', // Change the title of the category column
        headerValue: graphName[name] || "Value",
      },
    },
  };

  const series = [
    {
      name: graphName[name] || "Value",
      data: seriesData,
    },
  ];

  return (
    <div style={{ width: "100%", height: 400 }}>
      <Chart options={options} series={series} type="bar" height={400} />
    </div>
  );
};

// Define PropTypes
BarGraphChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string, // Assuming 'name' is a string, adjust as necessary
    })
  ),
  name: PropTypes.string,
};

export default BarGraphChart;
