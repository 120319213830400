import { useState, useEffect, useRef } from "react";
import * as signalR from "@microsoft/signalr";
import { deserializeCoordinates } from "../../utils/helpers";
import { setAssignmentCurrentButtonId, setIsDeadMiles } from "../../Redux-Toolkit/signalRSlice";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// Replace with your actual SignalR hub URL
const apiEndPoint = process.env.REACT_APP_SIGNALR_BASE_URL;
const SIGNALR_HUB_URL = `${apiEndPoint}/notificationHub`;

const SignalRComponent = ({
  reservationsAssignmentsID,
  setDriverPositionCoordinates,
  updateDriverPosition,
  driverPositionCoordinates,
}) => {

  const userToken = useSelector((state) => state?.auth?.Token);

  const dispatch = useDispatch();
  const [connection, setConnection] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const driverPositionCoordinatesRef = useRef(driverPositionCoordinates);

  useEffect(() => {
    driverPositionCoordinatesRef.current = driverPositionCoordinates;
  }, [driverPositionCoordinates]);

  useEffect(() => {
    setRoomId(String(reservationsAssignmentsID));

    // Initialize the SignalR connection
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(SIGNALR_HUB_URL, {
        accessTokenFactory: () => userToken,
      })
      .build();

    // Start the signalR connection once the connection is initialized successfully with url above
    connection
      .start()
      .then(() => console.log("SignalR connected"))
      .then(() => setIsConnected(true))
      .catch((err) => console.error("SignalR connection error:", err));

    // Handle incoming messages
    //pasring the incoming message and updating the DriverPositionCoordinates array to render path and  driver current position
    connection.on("ReceiveLiveCoordinates", (  ResAssignId, LatLong, IsDeadMile, CurrentButtonID) => {
      
      const latLng = deserializeCoordinates(LatLong);
      if (!isNaN(latLng.latitude) && !isNaN(latLng.longitude)) {
        const coordinates = {
          lat: latLng.latitude,
          lng: latLng.longitude,
        };
        const isDeadMile = IsDeadMile;
        dispatchCurrentButtonId(CurrentButtonID);
        dispatchIsDeadMile(isDeadMile);

        updateDriverPosition(coordinates, driverPositionCoordinatesRef.current);

        setDriverPositionCoordinates((prevCoords) => [
          ...prevCoords,
          coordinates,
        ]);
      }
    });

    // Set up the connection
    setConnection(connection);

    const dispatchCurrentButtonId = (CurrentButtonID) => {
      dispatch(setAssignmentCurrentButtonId(CurrentButtonID));
    }

    const dispatchIsDeadMile = (isDeadMile) => {    
      dispatch(setIsDeadMiles(isDeadMile));
    }

    // Clean up on unmount
    return () => {
      const exitRoomId = String( reservationsAssignmentsID  );
      connection
        .invoke("LeaveRoom", exitRoomId)
        .then(() => {
          console.log(`Left room: ${exitRoomId}`);
          return connection.stop();
        })
        .then(() => console.log("SignalR disconnected"))
        .catch((err) => console.error(err.toString()));
    };
  }, [reservationsAssignmentsID]);

  useEffect(() => {
    // Join the room
    if (connection && isConnected) {
      connection
        .invoke("JoinRoom", roomId)
        .then(() => console.log(`Joined room: ${roomId}`))
        .catch((err) => console.error(err.toString()));
    }
  }, [isConnected]);

  return (
    <></>
  );
};

// PropTypes validation
SignalRComponent.propTypes = {
  reservationsAssignmentsID: PropTypes.number,
  setDriverPositionCoordinates: PropTypes.object,
  updateDriverPosition: PropTypes.object,
  driverPositionCoordinates: PropTypes.object,
};

export default SignalRComponent;
