import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { getTimeDifference, getTimeFromString, safeParseJSON } from "../../../../utils/helpers";
import movingcar from "../../../../assets/images/car-moving.gif";
import contractorWailting from "../../../../assets/images/contractorWaiting.gif";
import translateContinue from "../../../../assets/images/translateContinue.gif";
import PropTypes from "prop-types";

const TransInterAssignmentHistory = ({

  fetchLocationName,
  calculateDistance,
  trackingData,
  rsvattcode,
  currentbuttonId,
  index,
}) => {

  const currentButtonID = currentbuttonId;

  const [deadMiles, setDeadMiles] = useState(null);
  const [assignmentHistoryTitleType, setAssignmentHistoryTitleType] = useState("");
  const [contractorStartedLocation, setContractorStartedLocation] = useState(null);
  const [contractorReachedPickupLocation, setContractorReachedPickupLocation] = useState(null);

  //---------------------to get location name and deadMiles from latitude and longitude---------------------

  useEffect(() => {
    const location = safeParseJSON(trackingData?.startDriverLatitudeLongitude);
    fetchLocationNameFunc(location);
    getDeadMiles();
  }, [trackingData]);

  const fetchLocationNameFunc = async (location) => {
    const name = await fetchLocationName(location);
    setContractorStartedLocation(name);
  };

  const getDeadMiles = async () => {
    const startLocation = JSON.parse(
      trackingData?.startDriverLatitudeLongitude
    );
    const reachedLocation = JSON.parse(
      trackingData?.reachedDriverLatitudeLongitude
    );
    setContractorStartedLocation(startLocation);
    setContractorReachedPickupLocation(reachedLocation);
    const dist = await calculateDistance(startLocation, reachedLocation);
    setDeadMiles(dist ?? "N/A");
  };

  //------------------------------------end of location name and deadMiles-----------------------------------

  const assignmentHistoryTitle = () => {
    const { rsvattcode } = trackingData || {};

    // Function to get the title based on index
    const getTitleByIndex = (index) => {
      switch (index) {
        case 0:
          return "Dead Miles";
        case 1:
          return "Front Way Trip";
        case 2:
          return "Translation Details";
        case 3:
          return "Back Way Trip";
        default:
          return "";
      }
    };

    // Function to determine the title based on rsvattcode and index
    const determineTitle = (rsvattcode, index) => {
      if (rsvattcode === "1W" || rsvattcode === "RT") {
        return getTitleByIndex(index);
      }
      return "";
    };
  
    // Get the title based on the current rsvattcode and index
    const title = determineTitle(rsvattcode, index);
  
    // If we have a valid title, set it via setAssignmentHistoryTitleType
    if (title) {
      setAssignmentHistoryTitleType(title);
    }
  
    return title;
  };

  const getAddress = (address1, address2) =>
    `${address1 || ""} ${address2 || ""}`.trim();

  useEffect(() => {
    const titleTag = assignmentHistoryTitle();
    setAssignmentHistoryTitleType(titleTag);
  }, [trackingData, index]); // Dependencies to watch

const contractorMovingGif_WithMessage = (message, type) => {
  // Determine the image source based on the type
  let imgSrc = "";
  let imgHeight = "";
  let imgWidth = "";

  if (type === "enroute") {
    imgSrc = movingcar;
    imgHeight = "40px";
    imgWidth = "150px";
  } else if (type === "waiting") {
    imgSrc = contractorWailting;
    imgHeight = "60px";
  } else if (type === "translateContinue" ){
    imgSrc = translateContinue;
    imgHeight = "80px";
  }

    return (

      <div className="flex items-center gap-2">
        <span className="text-[#63b0e0] text-sm">{message}</span>
        <span className="mb-[1rem] mt-[1rem]">
          <img
            src={imgSrc}
            className={`h-[${imgHeight}] w-[${imgWidth}]`}
            alt={`contractor ${type}`}
          />
        </span>
      </div>
    );
  };

  const deadMilesTrip = () => {
    return (
      <>
        {index === 0 && currentButtonID[0] >= 1 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            <span> Driver Started From their Location </span>
            <textarea
              name="contractorStartedFromHisLocation"
              id="contractorStartedFromHisLocation"
              value={contractorStartedLocation || "N/A"}
              readOnly
              className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 0 && currentButtonID[0] >= 1 ? (
          <>
            <label className="grid grid-cols-2 mt-3 items-center">
              <span>Driver Started Time</span>
              <Input
                name="contractorStartedTime"
                id="contractorStartedTime"
                value={getTimeFromString(trackingData?.startDateandTime)}
                readOnly
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
              />
            </label>
            {currentButtonID[0] < 2 &&
              contractorMovingGif_WithMessage(
                "Contractor is en route to pick up the claimant",
                "enroute"
              )}
          </>
        ) : (
          ""
        )}

        {index === 0 && currentButtonID[0] >= 2 && (
          <label className="grid grid-cols-2 mt-3 items-center">
            {"Driver Reached Pick Up Location"}
            <textarea
              name="contractorReachedPickUpLocation"
              id="contractorReachedPickUpLocation"
              value={
                getAddress(
                  trackingData?.puAddress1,
                  trackingData?.puAddress2
                ) || "N/A"
              }
              readOnly
              className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        )}

        {index === 0 && currentButtonID[0] >= 2 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            <span>Driver Reached Time</span>
            <Input
              name="contractorReachedTime"
              id="contractorReachedTime"
              value={getTimeFromString(trackingData?.reachedDateandTime)}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 0 &&
          currentButtonID[0] >= 2 &&
          contractorStartedLocation &&
          contractorReachedPickupLocation && (
            <>
              <label className="grid grid-cols-2 mt-3 items-center">
                Dead Miles
                <Input
                  name="deadMile"
                  id="deadMile"
                  value={`${deadMiles ?? 0} miles `}
                  readOnly
                  className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                />
              </label>
              {currentButtonID[0] < 3 &&
                contractorMovingGif_WithMessage(
                  "Contractor reached pick up location. Waiting for the claimant",
                  "waiting"
                )}
            </>
          )}
      </>
    );
  };


  const renderLabelWithInput = (label, value, isTextArea = false) => (
    <label className="grid grid-cols-2 mt-3 items-center">
      {label}
      {isTextArea ? (
        <textarea
          name="pickupTime"
          id="pickupTime"
          value={value}
          readOnly
          className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
        />
      ) : (
        <Input
          name="pickupTime"
          id="pickupTime"
          value={value}
          readOnly
          className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
        />
      )}
    </label>
  );
  
  const renderContractorMovingGif = (message, type) =>
    contractorMovingGif_WithMessage(message, type);

  const frontWayTrip = () => {
    const { puAddress1, puAddress2, reachedDateandTime, claimantPickedupDateandTime, tripEndPickedupDateandTime, doAddress1, doAddress2, travellingMiles } = trackingData || {};
  
    const pickupLocationLabel = renderLabelWithInput(
      "Driver Reached Pick Up Location",
      getAddress(puAddress1, puAddress2),
      true
    );
  
    const claimantWaitingTimeLabel = renderLabelWithInput(
      "Claimant Waiting Time",
      getTimeDifference(reachedDateandTime, claimantPickedupDateandTime)
    );
  
    const claimantOnboardedTimeLabel = renderLabelWithInput(
      "Claimant On-boarded Time",
      getTimeFromString(claimantPickedupDateandTime)
    );
  
    const tripStartedLabel = renderLabelWithInput(
      "Trip Started",
      getTimeFromString(claimantPickedupDateandTime)
    );
  
    const tripEndedLabel = renderLabelWithInput(
      "Trip Ended",
      getTimeFromString(tripEndPickedupDateandTime)
    );
  
    const dropOffLocationLabel = renderLabelWithInput(
      "Driver Reached Drop-off Location",
      tripEndPickedupDateandTime ? getAddress(doAddress1, doAddress2) : "N/A",
      true
    );
  
    const travelTimeLabel = renderLabelWithInput(
      "Travel Time",
      getTimeDifference(claimantPickedupDateandTime, tripEndPickedupDateandTime)
    );
  
    const actualMilesLabel = renderLabelWithInput(
      "Actual Miles",
      `${travellingMiles ?? 0} miles`
    );
  
    return (
      <>
        {index === 1 && currentButtonID[1] >= 1 && pickupLocationLabel}
        {index === 1 && currentButtonID[1] >= 3 && (
          <>
            {claimantWaitingTimeLabel}
            {claimantOnboardedTimeLabel}
            {tripStartedLabel}
            {currentButtonID[1] < 4 &&
              renderContractorMovingGif(
                "Claimant is on-boarded. Driver is en route to drop off location",
                "enroute"
              )}
            {currentButtonID[1] >= 4 && (
              <>
                {tripEndedLabel}
                {dropOffLocationLabel}
              </>
            )}
          </>
        )}
        {index === 1 && currentButtonID[1] >= 4 && (
          <>
            {travelTimeLabel}
            {actualMilesLabel}
            {currentButtonID[1] === 4 &&
              !currentButtonID[2] &&
              renderContractorMovingGif(
                "Driver reached to drop off location. Waiting for the session to start.",
                "waiting"
              )}
          </>
        )}
      </>
    );
  };
  const translationTrip = () => {
    return (
      <>
        {index === 2 && currentButtonID[2] >= 1 ? (
          <>
            <label className="grid grid-cols-2 mt-3 items-center">
              {"Translation Started"}
              <Input
                name="translationStarted"
                id="translationStarted"
                value={getTimeFromString(
                  trackingData?.claimantPickedupDateandTime
                )}
                readOnly
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
              />
            </label>
            {currentButtonID[2] < 4 &&
              contractorMovingGif_WithMessage(
                "Session is ongoing.",
                "translateContinue"
              )}
          </>
        ) : (
          ""
        )}

        {index === 2 && currentButtonID[2] >= 4 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            {"Translation Ended"}
            <Input
              name="translationEnded"
              id="translationEnded"
              value={getTimeFromString(
                trackingData?.tripEndPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 2 && currentButtonID[2] >= 4 ? (
          <>
          <label className="grid grid-cols-2 mt-3 items-center">
            {"Translation Duration"}
            <Input
              name="translationDuration"
              id="translationDuration"
              value={getTimeDifference(
                trackingData?.claimantPickedupDateandTime,
                trackingData?.tripEndPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
           {index === 2 && currentButtonID[2] === 4 && !currentButtonID[3] && rsvattcode === "RT" &&
            contractorMovingGif_WithMessage(
              "Session is Completed. Waiting for the claimant to en route the back way trip.",
              "waiting"
            )}
            </>
        ) : (
          ""
        )}
      </>
    );
  };

  const backWayTrip = () => {
    return (
      <>
        {index === 3 && currentButtonID[3] >= 1 && (
          <label className="grid grid-cols-2 mt-3 items-center">
            {"Driver Pick Up Location"}
            <textarea
              name="contractorPickUpLocation"
              id="contractorPickUpLocation"
              value={getAddress(
                trackingData?.doAddress1,
                trackingData?.doAddress2
              )}
              readOnly
              className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        )}

        {index === 3 && currentButtonID[3] >= 1 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            <span> Claimant On-boarded Time</span>
            <Input
              name="claimantonBoarderTime"
              id="claimantonBoarderTime"
              value={getTimeFromString(
                trackingData?.claimantPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 3 && currentButtonID[3] >= 1 ? (
          <>
            <label className="grid grid-cols-2 mt-3 items-center">
              {"Trip Started"}
              <Input
                name="tripStarted"
                id="tripStarted"
                value={getTimeFromString(
                  trackingData?.claimantPickedupDateandTime
                )}
                readOnly
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
              />
            </label>
            {currentButtonID[3] < 4 &&
              contractorMovingGif_WithMessage(
                "Claimant is on-boarded. Driver is en route to drop off location",
                "enroute"
              )}
          </>
        ) : (
          ""
        )}

        {index === 3 && currentButtonID[3] >= 4 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            {"Trip Ended"}
            <Input
              name="tripEnded"
              id="tripEnded"
              value={getTimeFromString(
                trackingData?.tripEndPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 3 && currentButtonID[3] >= 4 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            <span>Driver Reached Drop-off Location</span>
            <textarea
              name="contractorReachedDropoffLocation"
              id="contractorReachedDropoffLocation"
              value={
                trackingData?.tripEndPickedupDateandTime
                  ? getAddress(
                      trackingData?.puAddress1,
                      trackingData?.puAddress2
                    )
                  : "N/A"
              }
              readOnly
              className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 3 && currentButtonID[3] >= 4 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            {"Travel Time"}
            <Input
              name="travelTime"
              id="travelTime"
              value={getTimeDifference(
                trackingData?.claimantPickedupDateandTime,
                trackingData?.tripEndPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 3 && currentButtonID[3] >= 4 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            Actual Miles
            <Input
              name="actualMiles"
              id="actualMiles"
              value={`${trackingData?.travellingMiles ?? 0} miles`}
              readOnly
              className=" focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <>
      <div className=" justify-center font-semibold text-xl mb-2">
        {assignmentHistoryTitleType}
      </div>

      <>
        {deadMilesTrip()}
        {frontWayTrip()}
        {translationTrip()}
        {backWayTrip()}
      </>
    </>
  );
};

TransInterAssignmentHistory.propTypes = {
  fetchLocationName: PropTypes.func,
  calculateDistance: PropTypes.func,
  trackingData: PropTypes.shape({
    startDriverLatitudeLongitude: PropTypes.string,
    reachedDriverLatitudeLongitude: PropTypes.string,
    rsvattcode: PropTypes.string,
    puAddress1: PropTypes.string,
    puAddress2: PropTypes.string,
    claimantPickedupDateandTime: PropTypes.string,
    tripEndPickedupDateandTime: PropTypes.string,
    doAddress1: PropTypes.string,
    doAddress2: PropTypes.string,
    travellingMiles: PropTypes.number,
    startDateandTime: PropTypes.string,
    reachedDateandTime: PropTypes.string,
  }),
  rsvattcode: PropTypes.string,
  currentbuttonId: PropTypes.arrayOf(PropTypes.number),
  index: PropTypes.number,
};

export default TransInterAssignmentHistory;
