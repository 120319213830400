import React from "react";
import Dashboard from "./PanelPages/Pages/HomeDashboard/Dashboard";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import ClaimantManagement from "./PanelPages/Pages/ClaimantManagement/ClaimantManagement";
import { PageNotFound } from "./ErrorHandleImagePages/PageNotFound";
import InsuranceCompanyManagement from "./PanelPages/Pages/CustomerManagement/CustomersManagement";
import ContractorManagement from "./PanelPages/Pages/ContractorManagement/ContractorManagement";
import Profile from "./Common/Profile/Profile";
import StaffManagement from "./PanelPages/Pages/StaffManagement/StaffManagement";
import AppointmentsManagement from "./PanelPages/Pages/ReservationManagement/ReservationManagement";
import MetricsManagement from "./PanelPages/Pages/MetricsManagement/MetricsManagement";
import ViewCustomerDetails from "./PanelPages/Pages/CustomerManagement/ViewCustomerDetails";
import ViewClaimantDetails from "./PanelPages/Pages/ClaimantManagement/ViewClaimantDetails";
import ViewContractorDetails from "./PanelPages/Pages/ContractorManagement/ViewContractorDetails";
import ViewReservationDetails from "./PanelPages/Pages/ReservationManagement/ViewReservationDetails";
import Login from "./Auth/Login/Login";
import AssignContractor from "./PanelPages/Pages/ContractorManagement/AssignContractor";
import ViewServiceLocationList from "./PanelPages/Pages/ContractorManagement/ViewServiceLocationList";
import ContractorRatesTable from "./PanelPages/Pages/ContractorManagement/ContractorRatesTable";
import ContractorRateDetails from "./PanelPages/Pages/ContractorManagement/ContractorRateDetails";
import ContractorLanguageList from "./PanelPages/Pages/ContractorManagement/ContractorLanguageList";
import ReservationAssignmentDetails from "./PanelPages/Pages/ReservationManagement/ReservationAssignmentDetails/ReservationAssignmentDetails";
import ContractorVehicleSizeList from "./PanelPages/Pages/ContractorManagement/ContractorVehiclesList";
import ContractorDriverList from "./PanelPages/Pages/ContractorManagement/ContractorDriverList";
import ViewClaimDetails from "./PanelPages/Pages/ClaimantManagement/ViewClaimDetails";
import ClaimsManagement from "./PanelPages/Pages/ClaimsManagement/ClaimsManagement";
import ContractorAvailableHours from "./PanelPages/Pages/ContractorManagement/ContractorAvailableHours";
import ViewClaimFacilities from "./PanelPages/Pages/ClaimsManagement/ViewClaimFacilities";
import ViewClaimApprovedContractor from "./PanelPages/Pages/ClaimsManagement/ViewClaimApprovedContractor";
import ViewClaimFacilityDetails from "./PanelPages/Pages/ClaimsManagement/ViewClaimFacilityDetails";
import ViewClaimApprovedContractorDetails from "./PanelPages/Pages/ClaimsManagement/ViewClaimApprovedContractorDetails";
import AssignmentManagement from "./PanelPages/Pages/AssignmentManagement/AssignmentManagement";
import VerifyEmail from "./Auth/Login/VerifyEmail";
import EmailSentPageUI from "./Auth/Login/EmailSentPageUI";
import ContractorClaimantStaffPasswordReset from "./Common/ContractorClaimantStaffPasswordReset";
import { InternalServerError500 } from "./ErrorHandleImagePages/InternalServerError500";
import NotificationList from "./Common/Notification/NotificationList";
import ForgotPassword from "./Auth/Login/ForgotPassword";
import MetricsManagementDetail from "./PanelPages/Pages/MetricsManagement/MetricManagementDetail";
import Settings from "./PanelPages/Pages/Settings/Settings";

/**
 * here, we have to make pages for staff and accounts as well
 * once admin routes are owrking properly
 */

const allOrganisedRoutes = [
  { path: "/forgot-password", component: <VerifyEmail />, accessRole: ["all"] },
  {
    path: "/settings",
    component: <Settings />,
    accessRole: ["Administrators"],
  },
  {
    path: "/change-password",
    component: <ForgotPassword />,
    accessRole: ["all"],
  },
  {
    path: "/password-sent-to-email-page",
    component: <EmailSentPageUI />,
    accessRole: ["all"],
  },
  { path: "/", component: <Dashboard />, accessRole: ["all"] },
  { path: "/profile", component: <Profile />, accessRole: ["all"] },
  { path: "/login", component: <Login />, accessRole: ["all"] },
  {
    path: "/InternalServerErrorPage",
    component: <InternalServerError500 />,
    accessRole: ["all"],
  },

  {
    path: "/claimant-management",
    component: <ClaimantManagement />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/claimant-management/customer/:customerid",
    component: <ClaimantManagement />,
    accessRole: ["Administrators", "Billing", "Schedulers"],
  },
  {
    path: "/claimant-management/claimant-details/:id",
    component: <ViewClaimantDetails />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/claims-management",
    component: <ClaimsManagement />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/claims-management/customer/:customerid",
    component: <ClaimsManagement />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/claims-management/claimant/:claimantid",
    component: <ClaimsManagement />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/claims-management/claim-details/:id",
    component: <ViewClaimDetails />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/claims-management/claim-facilities/:claimID/:claimnumber/:claimantName",
    component: <ViewClaimFacilities />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/claims-management/claim-approvedContractor/:claimID/:claimnumber/:claimantName",
    component: <ViewClaimApprovedContractor />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/claims-management/claim-facility-details/:claimID",
    component: <ViewClaimFacilityDetails />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/claims-management/claim-approved-contractor-details/:claimID",
    component: <ViewClaimApprovedContractorDetails />,
    accessRole: ["Administrators", "Schedulers"],
  },

  {
    path: "/customer-management",
    component: <InsuranceCompanyManagement />,
    accessRole: ["Administrators", "Billing"],
  },
  {
    path: "/customer-management/customer-details/:id",
    component: <ViewCustomerDetails />,
    accessRole: ["Administrators", "Billing", "Schedulers"],
  },
  {
    path: "/staff-management",
    component: <StaffManagement />,
    accessRole: ["Administrators"],
  },
  {
    path: "/reservations-management",
    component: <AppointmentsManagement />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/reservations-management/reservation-details/:reservationid",
    component: <ViewReservationDetails />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/reservations-management/customer/:customerid",
    component: <AppointmentsManagement />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/reservations-management/claimant/:claimantid",
    component: <AppointmentsManagement />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/reservations-management/claim/:claimnumber",
    component: <AppointmentsManagement />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/metrics-management",
    component: <MetricsManagement />,
    accessRole: ["Administrators", "Billing"],
  },
  {
    path: "/metrics-management/:id",
    component: <MetricsManagement />,
    accessRole: ["Administrators", "Billing"],
  },
  {
    path: "/metrics-management/:contractorID",
    component: <MetricsManagement />,
    accessRole: ["Administrators", "Billing"],
  },
  {
    path: "/contractor-management",
    component: <ContractorManagement />,
    accessRole: ["Administrators", "Schedulers", "Billing"],
  },
  {
    path: "/contractor-management/contractor-details/:id",
    component: <ViewContractorDetails />,
    accessRole: ["Administrators", "Schedulers", "Billing"],
  },
  {
    path: "/contractor-management/contractor-service-location/:id/:contractorname",
    component: <ViewServiceLocationList />,
    accessRole: ["Administrators", "Schedulers", "Billing"],
  },
  {
    path: "/contractor-management/contractor-rates-table/:id/:contractorname/:ratecodeid",
    component: <ContractorRatesTable />,
    accessRole: ["Administrators", "Schedulers", "Billing"],
  },
  {
    path: "/contractor-management/contractor-language/:id",
    component: <ContractorLanguageList />,
    accessRole: ["Administrators", "Schedulers", "Billing"],
  },
  {
    path: "/contractor-management/contractor-vehicle/:id",
    component: <ContractorVehicleSizeList />,
    accessRole: ["Administrators", "Schedulers", "Billing"],
  },
  {
    path: "/contractor-management/contractor-availableHours/:id",
    component: <ContractorAvailableHours />,
    accessRole: ["Administrators", "Schedulers", "Billing"],
  },
  {
    path: "/contractor-mangement/contractor-driver/:id",
    component: <ContractorDriverList />,
    accessRole: ["Administrators", "Schedulers", "Billing"],
  },
  {
    path: "/contractor-management/contractor-rate-details/:contractorname/:ratecode/:contractorRateID",
    component: <ContractorRateDetails />,
    accessRole: ["Administrators", "Billing", "Schedulers"],
  },
  {
    path: "/assignments-management",
    component: <AssignmentManagement />,
    accessRole: ["Administrators", "Billing", "Schedulers"],
  },
  {
    path: "/assignments-management/reservation/:contractorid/:reservationid/puzip/:puzipCode/:reservationsAssignmentsID/:quantity",
    component: <AssignContractor />,
    accessRole: ["Administrators", "Billing", "Schedulers"],
  },
  {
    path: "/assignments-management/reservation/:contractorid/:reservationid/dozip/:dozipCode/:reservationsAssignmentsID/:quantity",
    component: <AssignContractor />,
    accessRole: ["Administrators", "Billing", "Schedulers"],
  },
  {
    path: "/assignments-management/reservation/:reservationid/:claimnumber/:zipCode",
    component: <AssignmentManagement />,
    accessRole: ["Administrators", "Billing", "Schedulers"],
  },
  {
    path: "/assignments-management/contractor/:contractorid",
    component: <AssignmentManagement />,
    accessRole: ["Administrators", "Billing", "Schedulers"],
  },
  {
    path: "/assignments-management/reservation-assignment-details/:id",
    component: <ReservationAssignmentDetails />,
    accessRole: ["Administrators", "Billing", "Schedulers"],
  },
  {
    path: "/contractor-management/password-reset/:id",
    component: <ContractorClaimantStaffPasswordReset />,
    accessRole: ["Administrators", "Schedulers"],
  },
  {
    path: "/claimant-management/password-reset/:id",
    component: <ContractorClaimantStaffPasswordReset />,
    accessRole: ["Administrators", "Schedulers"],
  },
  
  {
    path: "/staff-management/password-reset/:id",
    component: <ContractorClaimantStaffPasswordReset />,
    accessRole: ["Administrators"],
  },
  {
    path: "/notification-list",
    component: <NotificationList />,
    accessRole: ["Administrators", "Billing", "Schedulers"],
  },
  {
    path: "/metrics-management/metrics-detail/:id",
    component: <MetricsManagementDetail />,
    accessRole: ["Administrators", "Billing"],
  },
  {
    path: "/metrics-management/assignment/:assignmentid",
    component: <MetricsManagement />,
    accessRole: ["Administrators", "Billing"],
  },
  {
    path: "/metrics-management/contractor/:contractorid",
    component: <MetricsManagement />,
    accessRole: ["Administrators", "Billing"],
  },
];

/**
 * here, we will have to map the pages in routes as per the role we get from the backend
 * So will need to change later when we have backend running properly
 */

const Routess = () => {
  const userRole = useSelector((state) => state?.auth?.role);

  return (
    <Routes>
      {/* <Route index element={<Dashboard />} /> */}
      {allOrganisedRoutes.map(
        ({ component, path, accessRole }) =>
          accessRole &&
          (accessRole.includes("all") || accessRole.includes(userRole)) && (
            <Route key={path} element={component} path={path} />
          )
      )}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Routess;
