import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import {
  setReservationFilters,
  updateReservationFilters,
} from "../../../../Redux-Toolkit/ReservationSlice";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { handleIDInputChange, iDValue } from "../../../../utils/helpers";
import InputSearchFilterDropDown from "../../../../Common/ui/inputSearchFilterDropDown";

const ReservationManagementFilter = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const defaultState = {
    reservationid: 0,
    claimNumber: "",
    customerID: 0,
    customerName: "",
    claimantID: 0,
    claimantName: "",
    contractorID: 0,
    contractorName: "",
    inactiveflag: -2,
    service: "",
    dateFrom: "",
    dateTo: "",
    tripType: "",
    transportType: "",
    actionCode: "",
    conAssignStatus: 0,
    claimantConfirmation: "",
    page: 1,
  };
  
  const initializeState = (filter = {}) => ({
    ...defaultState,
    ...filter,
  });

  const reservationFilter = useSelector((state) => state?.reservation?.reservationFilters) || [];
  const reservationServiceList = useSelector((state) => state?.reservation?.serviceList) || [];
  const reservationTransportList = useSelector((state) => state?.reservation?.transportTypeList) || [];
  const reservationTripList = useSelector((state) => state?.reservation?.tripTypeList) || [];
  const setActionCodeList = useSelector((state) => state?.reservation?.reservationStatusList) || [];

  const initialState = initializeState(reservationFilter);
  const [reservationid, setReservationid] = useState(initialState.reservationid);
  const [claimNumber, setClaimNumber] = useState(initialState.claimNumber);
  const [customerID, setCustomerID] = useState(initialState.customerID);
  const [selectedCustomerName, setSelectedCustomerName] = useState( initialState.customerName);
  const [claimantID, setClaimantID] = useState(initialState.claimantID);
  const [selectedClaimantName, setSelectedClaimantName] = useState( initialState.claimantName);
  const [contractorID, setContractorID] = useState(initialState.contractorID);
  const [selectedContractorName, setSelectedContractorName] = useState( initialState.contractorName );
  const [inactiveflag, setInactiveflag] = useState(initialState.inactiveflag);
  const [claimantConfirmation, setClaimantConfirmation] = useState(initialState.claimantConfirmation);
  const [service, setService] = useState(initialState.service);
  const [dateFrom, setDateFrom] = useState(initialState.dateFrom);
  const [dateTo, setDateTo] = useState(initialState.dateTo);
  const [tripType, setTripType] = useState(initialState.tripType);
  const [transportType, setTransportType] = useState(initialState.transportType);
  const [actionCode, setActionCode] = useState(initialState.actionCode);
  const [conAssignStatus, setConAssignStatus] = useState(initialState.conAssignStatus);

  useEffect(() => {
    updateStateFromFilter(reservationFilter);
  }, [reservationFilter]);

  const updateStateFromFilter = (filter) => {
    setReservationid(filter?.reservationid);
    setClaimNumber(filter?.claimNumber);
    setCustomerID(filter?.customerID);
    setSelectedCustomerName(filter?.customerName);
    setClaimantID(filter?.claimantID);
    setSelectedClaimantName(filter?.claimantName);
    setContractorID(filter?.contractorID);
    setSelectedContractorName(filter?.contractorName);
    setInactiveflag(filter?.inactiveflag);
    setService(filter?.service);
    setDateFrom(filter?.dateFrom);
    setDateTo(filter?.dateTo);
    setTripType(filter?.tripType);
    setTransportType(filter?.transportType);
    setActionCode(filter?.actionCode);
    setConAssignStatus(filter?.conAssignStatus);
    setClaimantConfirmation(filter?.claimantConfirmation);
  };

  const handleReset = () => {
    resetState();
    dispatch(setReservationFilters(createFilterData()));
    navigate("/reservations-management");
  };

  const resetState = () => {
    setReservationid(0);
    setClaimNumber("");
    setCustomerID(0);
    setSelectedCustomerName("");
    setSelectedClaimantName("");
    setClaimantID(0);
    setContractorID(0);
    setSelectedContractorName("");
    setInactiveflag(-2);
    setService("");
    setDateFrom("");
    setDateTo("");
    setTripType("");
    setTransportType("");
    setActionCode("");
    setConAssignStatus(0);
    setClaimantConfirmation("");
  };

  const createFilterData = () => ({
    reservationid: 0,
    claimNumber: "",
    claimantID: 0,
    customerID: 0,
    contractorID: 0,
    inactiveflag: -2,
    service: "",
    dateFrom: "",
    dateTo: "",
    tripType: "",
    transportType: "",
    actionCode: "",
    conAssignStatus: 0,
    claimantConfirmation: "",
    limit: 20,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = createSubmissionData();
    dispatch(setReservationFilters(data));
    dispatch(updateReservationFilters({
      customerName: selectedCustomerName,
      claimantName: selectedClaimantName, 
      contractorName: selectedContractorName 
    }));
    navigate(`?page=1`);
  };

  const createSubmissionData = () => {
    return {
      ...(reservationid &&
        !isNaN(Number(reservationid)) && {
          reservationid: Number(reservationid),
        }),
      ...(claimNumber && claimNumber.trim() !== "" && { claimNumber }),
      ...(customerID &&
        !isNaN(Number(customerID)) && { customerID: Number(customerID) }),

      ...(claimantID &&
        !isNaN(Number(claimantID)) && { claimantID: Number(claimantID) }),
      ...(contractorID &&
        !isNaN(Number(contractorID)) && {
          contractorID: Number(contractorID),
        }),
      ...(service && service.trim() !== "" && { service }),

      ...(inactiveflag &&
        !isNaN(Number(inactiveflag)) && {
          inactiveflag: Number(inactiveflag),
        }),
      ...(dateFrom && dateFrom.trim() !== "" && { dateFrom }),
      ...(dateTo && dateTo.trim() !== "" && { dateTo }),

      ...(transportType && transportType.trim() !== "" && { transportType }),
      ...(tripType && tripType.trim() !== "" && { tripType }),
      ...(actionCode && actionCode.trim() !== "" && { actionCode }),

      ...(conAssignStatus &&
        !isNaN(Number(conAssignStatus)) && {
          conAssignStatus: Number(conAssignStatus),
        }),
        ...(claimantConfirmation && claimantConfirmation.trim() !== "" && { claimantConfirmation }),
      page: 1,
      limit: 20,
    };
  };

  return (

    <div className="card bg-base-100 shadow-md p-6 mt-3 mb-3">
      <form
        onSubmit={handleSubmit}
        data-testid="reservation-form"
      >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          <div>
            <label
              htmlFor="reservationid"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Reservation ID
            </label>
            <input
              name="reservationid"
              onChange={(e) => handleIDInputChange(e, setReservationid)}
              value={iDValue(reservationid)}
              placeholder="Reservation ID"
              id="reservationid"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>
          <div>
            <label
              htmlFor="claimnumber"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim Number
            </label>
            <input
              name="claimnumber"
              onChange={(e) => {
                setClaimNumber(e.target.value);
              }}
              value={claimNumber}
              placeholder="Claim Number"
              id="claimnumber"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>

          <div className="relative">
            <InputSearchFilterDropDown
              data-testid="customer"
              className={"customer-search"}
              labelName={"Customer"}
              url="/AllCustomer"
              payloadName={"customerName"}
              data={[]}
              setSearchList={(selectedOption) => {
                setCustomerID(selectedOption?.customerID);
                setSelectedCustomerName(selectedOption?.fullName);
              }}
              defaultSelectedValue={selectedCustomerName}
            />
          </div>

          <div className="relative">
            <InputSearchFilterDropDown
              className={"claimant-search"}
              labelName={"Claimant"}
              url="/AllCLaimant"
              payloadName={"claimantName"}
              setSearchList={(selectedOption) => {
                setClaimantID(selectedOption?.claimantID);
                setSelectedClaimantName(selectedOption?.fullName);
              }}
              defaultSelectedValue={selectedClaimantName}
            />
          </div>

          <div className="relative"
            data-testid="contractor"
          >
            <InputSearchFilterDropDown
              data-testid="contractor"
              className={"contractor-search"}
              labelName={"Contractor"}
              url="/AllContractor"
              payloadName={"contractorName"}
              data={[]}
              setSearchList={(selectedOption) => {
                setContractorID(selectedOption?.contractorID);
                setSelectedContractorName(selectedOption?.fullName);
              }}
              defaultSelectedValue={selectedContractorName}
            />
          </div>

          <div>
            <label
              htmlFor="dateFrom"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Date From
            </label>
            <Input
              name="dateFrom"
              placeholder=""
              onChange={(e) => {
                const dateValue = e.target.value;
                if (dateValue) {
                  setDateFrom(format(new Date(dateValue), "yyyy-MM-dd"));
                } else {
                  setDateFrom(""); // Clear the date if the input is cleared
                }
              }}
              value={dateFrom}
              id="dateFrom"
              type="date"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>
          <div>
            <label
              htmlFor="dateTo"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Date To
            </label>
            <Input
              name="dateTo"
              placeholder=""
              onChange={(e) => {
                const dateValue = e.target.value;
                if (dateValue) {
                  setDateTo(format(new Date(e.target.value), "yyyy-MM-dd"));
                } else {
                  setDateTo(""); // Clear the date if the input is cleared
                }
              }}
              value={dateTo}
              id="dateTo"
              type="date"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>
          <div>
            <label
              htmlFor="service"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Service
            </label>
            <select
              name="service"
              id="service"
              value={service}
              onChange={(e) => {
                setService(e.target.value);
              }}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="">All</option>
              {reservationServiceList &&
                reservationServiceList?.length > 0 &&
                reservationServiceList?.map((service, index) => (
                  <option value={service.code} key={service.code}>
                    {service.description}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="transport"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Transport Type
            </label>
            <select
              id="transport"
              name="transport"
              value={transportType}
              onChange={(e) => setTransportType(e.target.value)}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="">All</option>
              {reservationTransportList &&
                reservationTransportList?.length > 0 &&
                reservationTransportList?.map((transport, index) => (
                  <option value={transport.code} key={transport.code}>
                    {transport.description}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="trip"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Trip Type
            </label>
            <select
              name="trip"
              id="trip"
              value={tripType}
              onChange={(e) => setTripType(e.target.value)}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="">All</option>
              {reservationTripList &&
                reservationTripList?.length > 0 &&
                reservationTripList?.map((trip, index) => (
                  <option value={trip.code} key={trip.code}>
                    {trip.description}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="actionCode"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Action Code
            </label>
            <select
              name="actionCode"
              id="actionCode"
              value={actionCode}
              onChange={(e) => setActionCode(e.target.value)}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="">All</option>
              {setActionCodeList &&
                setActionCodeList?.length > 0 &&
                setActionCodeList?.map((trip, index) => (
                  <option value={trip.code} key={trip.code}>
                    {trip.code + " - " + trip.description}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="contractorStatus"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Contractor Status
            </label>
            <select
              name="contractorStatus"
              id="contractorStatus"
              value={conAssignStatus}
              onChange={(e) => setConAssignStatus(e.target.value)}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value={"0"}>All</option>
              <option value={"1"}>Assigned</option>
              <option value={"-1"}>Not Assigned</option>
            </select>
          </div>
          <div>
            <label
              htmlFor="status"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Status
            </label>
            <select
              id="Status"
              name="Status"
              value={inactiveflag}
              onChange={(e) => setInactiveflag(e.target.value)}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value={"-2"}>All</option>
              <option value={"0"}>Active</option>
              <option value={"-1"}>Inactive </option>
            </select>
          </div>
          <div>
            <label
              htmlFor="claimantConfirmation"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claimant Confirmation
            </label>
            <select
              id="claimantConfirmation"
              name="claimantConfirmation"
              value={claimantConfirmation}
              onChange={(e) => setClaimantConfirmation(e.target.value)}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value={""}>All</option>
              <option value={"Pending"}>Pending</option>
              <option value={"Accepted"}>Accepted</option>
              <option value={"Rejected"}>Rejected</option>
            </select>
          </div>
        </div>

        <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
          <button
            type="reset"
            onClick={() => {
              handleReset();
            }}
            className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            <MdOutlineRefresh className="mr-1" />
            Reset
          </button>
          <button className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4">
            <MdOutlineSearch className="mr-1" />
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReservationManagementFilter;
