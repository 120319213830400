import React from 'react';

const Footer = () => {

    const currentYear = new Date().getFullYear();

  return (

    <footer className=" bg-[#63b0e0] text-[black] p-2 text-center rounded ">
      <p className="text-sm">
        &copy; {currentYear} JNJ Services. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
