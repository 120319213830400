import { Link } from "react-router-dom";
import InernalServerErrorImage from "../assets/images/InternalServerError500.svg";

export const InternalServerError500 = () => {
    return(
        <div className="w-full bg-[#fff] flex items-center justify-center">
            <div className="text-center">
                <img className="h-[400px] object-contain" src={InernalServerErrorImage} alt="inernalServerErrorImage" />
                <div className=" relative mb-4">
                    <Link to="/">
                        <button className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000]">
                            Go TO DASHBOARD
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}