import React, { useEffect } from "react";
import {
  increaseNotificationCount,
  setNotificationList,
} from "../../Redux-Toolkit/NotificationSlice";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Indicator } from "react-daisyui";
import { Link } from "react-router-dom";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../Firebase/NotificationFCM";

const NotificationIcon = () => {

  const dispatch = useDispatch();
  const notificationCount = useSelector((state) => state?.notifications?.notificationCount);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      dispatch(setNotificationList(payload));
      dispatch(increaseNotificationCount());
    });
  }, []);

  return (
    <div>
      <Button
        size="sm"
        color="ghost"
        shape="circle"
        className="pl-[4px] pb-[20px] pt-1 ml-[4px] mt-[-3px] tooltip tooltip-left"
      >
        <Link
          className="cursor-pointer tooltip-bottom"
          to={`/notification-list`}
        >
          <Indicator
            item={
              notificationCount > 0 && (
                <Badge
                  className="aspect-[1] p-[10px] bg-[red] border-[red] mt-[4px]"
                  size="xs"
                >
                  {" "}
                  {notificationCount}{" "}
                </Badge>
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </Indicator>
        </Link>
      </Button>
    </div>
  );
};

export default NotificationIcon;
