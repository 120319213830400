import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { toast } from "react-toastify";
import { setNotificationPermission } from "../Redux-Toolkit/NotificationSlice";
import { setDevice_Id, setFcm_key_created_date, setFcmToken } from "../Redux-Toolkit/AuthSlice";

const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "intimeappapi.firebaseapp.com",
  projectId: "intimeappapi",
  storageBucket: "intimeappapi.appspot.com",
  messagingSenderId: "231590770560",
  appId: "1:231590770560:web:3095bae20a2bda29c13365",
  measurementId: "G-51GGD1LW0T",
};

/* Initialize Firebase */
const app = initializeApp(firebaseConfig);

/* Initialize Firebase Cloud Messaging and get a reference to the service */
export const messaging = getMessaging(app);

export const requestNotificationPermission = async (dispatch) => {
  try {
    const permission = await Notification.requestPermission();

    switch (permission) {
      case "granted":
        dispatch(setNotificationPermission(true));
        break;
      case "denied":
        dispatch(setNotificationPermission(false));
        break;
      case "default":
        dispatch(setNotificationPermission(false));
        toast.info(
          "Notification permission request was dismissed. Please enable notifications from settings.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
        break;
      default:
        console.error("Unexpected permission state:", permission);
    }
  } catch (err) {
    console.error("Error requesting notification permission:", err);
    toast.error("An error occurred while requesting notification permission.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  }
};

export const generateFcmToken = async (
  device_id,
  setFcmTokengenerated,
  dispatch
) => {
  try {

    /* Get the FCM token */
    const Token = await getToken(messaging, {vapidKey: FIREBASE_VAPID_KEY });

    if (Token) {
      if (!device_id) {
        const crypto = window.crypto || window.msCrypto;
        const array = new Uint8Array(30); /* 30 bytes */
        crypto.getRandomValues(array); /* Compliant for security-sensitive use cases */

        device_id = Array.from(array, (byte) =>
          byte.toString(16).padStart(2, "0")
        ).join("");
      }

      setFcmTokengenerated(true);
      localStorage.setItem("device_id", device_id);
      dispatch(setFcmToken(Token));
      dispatch(setDevice_Id(device_id));
      const current_date = new Date().toLocaleDateString();
      dispatch(setFcm_key_created_date(current_date));
    }
  } catch (err) {
    console.error(
      "Service worker registration or token generation failed, error:",
      err
    );
  }
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage((message, payload) => {
      const notificationTitle = "Data Message Title";
      const notificationOptions = {
        body: "Data Message body",
        icon: "https://c.disquscdn.com/uploads/users/34896/2802/avatar92.jpg",
        image: "https://c.disquscdn.com/uploads/users/34896/2802/avatar92.jpg",
      };
      /* Process your message as required */
      return window.self.registration.showNotification(
        notificationTitle,
        notificationOptions
      );
    });
  });
