import CryptoJS from 'crypto-js';

const encryptionKey = process.env.REACT_APP_KEY;

const decryptParamID = (cipherObj) => {

    const resObj = {};
    Object.keys(cipherObj).forEach((key) => {
        if (key.toLowerCase().includes("id")) {
            const safeCiphertext = cipherObj[key].replace(/KKSd9@923/g, '/');
            const bytes = CryptoJS.AES.decrypt(safeCiphertext, encryptionKey);
            const originalText = bytes.toString(CryptoJS.enc.Utf8);
            resObj[key] = JSON.parse(originalText);
        }
    })
    return resObj;
}

export default decryptParamID;