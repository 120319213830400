import CryptoJS from 'crypto-js';

const encryptionKey = process.env.REACT_APP_KEY;

const encryptParamID = (body) => {
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(body), encryptionKey).toString();
  const safeCiphertext = ciphertext.replace(/\//g, 'KKSd9@923');
  return safeCiphertext;
}

export default encryptParamID;