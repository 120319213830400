import React, { useState } from "react";
import { MdOutlineVpnKey } from "react-icons/md";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import { postLogin } from "../ApiMethods/Api";
import { toast } from "react-toastify";
import PasswordStrengthBar from "react-password-strength-bar";
import PasswordChecklist from "react-password-checklist";
import PropTypes from "prop-types";

const ContractorClaimantStaffPasswordReset = ({
  id,
  onClose
}) => {

  const url = window.location.href;
  const parts = url.split("/");

  const [passwordLoading, setPasswordLoading] = useState(false);
  const [newShowPassword, setNewShowPassword] = useState(false);
  const [confirmNewShowPassword, setConfirmNewShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const [passwordChangeModal, setPasswordChangeModal] = useState(true);

  let type = 0;
  if (parts.includes("claimant-management")) {
    type = 2;
  } else if (parts.includes("contractor-management")) {
    type = 1;
  } else if (parts.includes("staff-management")) {
    type = 3;
  }

  const passwordChange = async () => {
    const apiEndpoint =
      type !== 3 ? "/ResetClaimantContractorPassword" : "/UsersUpdatePassword";

    const body = {
      ...(type !== 3 && { type: Number(type) }),
      userID: id,
      password: newPassword,
    };

    try {
      setPasswordLoading(true);
      const response = await postLogin(apiEndpoint, body);
      setPasswordLoading(false);

      if (response?.data?.status === false) {
        toast.error(response?.data?.statusMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (response?.data?.status === true) {
        toast.success(response?.data?.statusMessage, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        onClose(); // Close the modal after success
      }
    } catch (error) {
      setPasswordLoading(false);
      console.error("An error occurred", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    passwordChange();
  };

  const Reset = () => {
    setNewPassword("");
    setConfirmPassword("");
    setNewShowPassword(false);
    setConfirmNewShowPassword(false);
  };

  const setpasswordpopup = () => {
    setPasswordChangeModal(!passwordChangeModal);
  };

  return (

    <>
      <input
        checked={passwordChangeModal}
        type="checkbox"
        id="my-modal-3"
        className="modal-toggle"
        onChange={setpasswordpopup}
      />
      <div className="modal z-[100001]">
        <div className="modal-box first-line:relative">
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
          >

            <button
              type="button" // Specify button type
              onClick={onClose}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  Reset();
                }
              }}
              className="btn btn-sm btn-circle absolute right-2 top-2"
              aria-label="Close"
            >
              ✕
            </button>

            <p className=" text-lg font-semibold text-[#63b0e0]">
              {" "}
              Reset password for ID: {id}
            </p>

            <div className="pt-4">
              <div className="form-control mt-2">
                <span className="label-text font-semibold label">
                  New Password
                </span>
                <label className="input-group"
                  data-testid="new-password"
                >
                  <span className=" bg-[#000]">
                    <MdOutlineVpnKey className=" text-[#63b0e0]" />
                  </span>
                  <input
                    id="new-password"
                    autoComplete={false}
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                    required
                    name="new-password"
                    type={newShowPassword ? "text" : "password"}
                    placeholder="New Password"
                    className="input w-full border-[#000] focus:outline-none border-r-0"
                  />

                  <button
                    type="button"
                    className=" bg-transparent cursor-pointer border-[#000] border border-l-0"
                    onClick={() =>
                      setNewShowPassword(!newShowPassword)
                    }
                  >
                    {newShowPassword ? (
                      <RxEyeOpen className=" text-[#000] mr-3 ml-3" />
                    ) : (
                      <RxEyeClosed className=" text-[#000] mr-3 ml-3" />
                    )}
                  </button>

                </label>
              </div>

              <div className="form-control mt-2">
                <span className="label-text font-semibold label">
                  Confirm New Password
                </span>
                <label className="input-group"
                  data-testid="confirm-new-password"
                >
                  <span className=" bg-[#000]">
                    <MdOutlineVpnKey className=" text-[#63b0e0]" />
                  </span>
                  <input
                    id="confirm-password"
                    autoComplete={false}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    required
                    name="confirm-password"
                    type={confirmNewShowPassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    className="input w-full border-[#000] focus:outline-none border-r-0"
                  />

                  <button
                    type="button"
                    className=" bg-transparent cursor-pointer border-[#000] border border-l-0"
                    onClick={() =>
                      setConfirmNewShowPassword(!confirmNewShowPassword)
                    }
                    data-testid="confirm-password-toggle"
                  >
                    {confirmNewShowPassword ? (
                      <RxEyeOpen className=" text-[#000] mr-3 ml-3" />
                    ) : (
                      <RxEyeClosed className=" text-[#000] mr-3 ml-3" />
                    )}
                  </button>

                </label>
              </div>
              <PasswordStrengthBar password={newPassword} />
              <PasswordChecklist
                className="passwordChecklist"
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "lowercase",
                  "match",
                  "nonEmpty",
                ]}
                minLength={8}
                value={newPassword}
                valueAgain={confirmPassword}
                messages={{
                  minLength: "The password must be at least 8 characters long.",
                  specialChar: "The password must include special characters.",
                  number: "The password must contain a number.",
                  capital: "The password must have at least one uppercase letter.",
                  match: "The passwords must match.",
                  lowercase: "The password must have at least one lowercase letter.",
                }}
                onChange={(isValid) => {
                  if (newPassword !== "" && isValid) {
                    setIsPasswordValid(true);
                  } else {
                    setIsPasswordValid(false);
                  }
                }}
              />
              <div className=" flex justify-center ">
                {!passwordLoading ? (
                  <button
                    type="submit"
                    disabled={!isPasswordValid}
                    onClick={() => passwordChange()}
                    className={`mt-5 btn w-[50%] bg-[#63b0e0] hover:bg-[#000] text-[#000] hover:text-[#63b0e0] font-semibold border-none`}
                  >
                    Update Password
                  </button>
                ) : (
                  <button
                    type="button"
                    className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-[#63b0e0] hover:text-[#63b0e0] font-semibold border-none flex justify-center items-center"
                  >
                    <svg
                      className="animate-spin mr-1 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

// PropTypes validation
ContractorClaimantStaffPasswordReset.propTypes = {
  htmlFor: PropTypes.string,
  id: PropTypes.string || PropTypes.number, // Assuming id is a string
  onClose: PropTypes.func, // Assuming onClose is a function
};

export default ContractorClaimantStaffPasswordReset;