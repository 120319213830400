import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  claimsList: [],
  claimantName: "",
  customerName: "",
  selectedTableRow: null,
  selectedClaimFacilityTableRow: null,
  selectedClaimApprovedContractorsTableRow: null,

  filterData: {
    claimID: 0,
    claimNumber: "",
    customerID: 0,
    claimantID: 0,
    birthdate: "",
  },
};

export const ClaimSlice = createSlice({
  name: "claims",
  initialState,
  reducers: {
    loadUser: (state) => {
      if (!localStorage.getItem("userAuthApproved")) {
        state.userAuthApproved = false;
        return;
      }
      state.Token = localStorage.getItem("Admintoken");
      state.user = localStorage.getItem("Admin");
      state.userAuthApproved = true;
    },
    setClaimID: (state, action) => {
      state.claimID = action.payload;
    },
    setClaimNumber: (state, action) => {
      state.claimNumber = action.payload;
    },
    setClaimantID: (state, action) => {
      state.claimantID = action.payload;
    },
    setCustomerNameList: (state, action) => {
      state.customerName = action.payload;
    },
    setClaimantNameList: (state, action) => {
      state.claimantName = action.payload;
    },
    setcustomerID: (state, action) => {
      state.customerID = action.payload;
    },
    setClaimsList: (state, action) => {
      state.claimsList = action.payload;
    },
    setClaimsFilter: (state, action) => {
      state.filterData = action.payload;
    },
    setClaimsFacility: (state, action) => {
      state.facilityList = action.payload;
    },
    updateClaimFilters: (state, action) => {
      state.filterData = { ...state.filterData, ...action.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedTableRow = action.payload;
    },
    setErrLoggingIn: (state, action) => {
      state.errLoggingIn = action.payload;
    },
    setLoginLoading: (state, action) => {
      state.loginLoading = action.payload;
    },
    setSelecteClaimFacilitydRow: (state, action) => {
      state.selectedClaimFacilityTableRow = action.payload;
    },
    setSelectedClaimApprovedContractorRow: (state, action) => {
      state.selectedClaimApprovedContractorsTableRow = action.payload;
    },
    resetClaimFilters: (state) => {
      state.filterData = { ...initialState.filterData };
      state.selectedTableRow = null;
    },
  },
});

export const {
  setErrLoggingIn,
  setLoginLoading,
  userAuthApproved,
  loadUser,
  setClaimID,
  setClaimNumber,
  setClaimantID,
  setClaimantNameList,
  setCustomerNameList,
  setClaimsList,
  setClaimsFilter,
  updateClaimFilters,
  setSelectedRow,
  setcustomerID,
  setClaimsFacility,
  setSelecteClaimFacilitydRow,
  setSelectedClaimApprovedContractorRow,
  resetClaimFilters,
} = ClaimSlice.actions;

export default ClaimSlice.reducer;
