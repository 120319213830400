import React from "react";
import BarGraphChart from "./BarGraphChart";
import HorizontalBarGraph from "./HorizontalBarGraph";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";

const Graph = ({graphData}) => {
  return (
    <Screen graphData={graphData} />
  );
};

Graph.propTypes = {
  graphData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string, // Each graph item must have a 'name' field
      data: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
      ]), // Each graph item must have a 'data' field which could be an array or an object
    })
  ),
};

export default Graph;

const Screen = ({graphData}) => {

  const graphTypes = [
    {
      name: "Reservations By Month",
      graph: "barGraph",
    },
    {
      name: "Reservations By Action Code",
      graph: "horizontalBarGraph",
    },
    {
      name: "Claimant By Month",
      graph: "barGraph",
    },
    {
      name: "Contractor by Availability",
      graph: "horizontalBarGraph",
    },
    {
      name: "Contractor By Month",
      graph: "barGraph",
    },
    {
      name: "Reservations By Service",
      graph: "horizontalBarGraph",
    },
    {
      name: "Customer By Category",
      graph: "horizontalBarGraph",
    },
    {
      name: "Contractor By Service",
      graph: "horizontalBarGraph",
    },
  ];

  // Create a mapping of component names to components
  const componentMapping = {
    barGraph: BarGraphChart,
    horizontalBarGraph: HorizontalBarGraph,
  };

  return (
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 mt-8 gap-8">
        {graphData &&
          Array.isArray(graphData) &&
          graphData.map((data, index) => {
            if (!data?.name || !data?.data) {
              return null; // Skip rendering this graph if any required field is missing or invalid
            }

            const type = graphTypes.find((type) => type?.name === data?.name);
            if (!type) return null;

            const GraphComponent = componentMapping[type.graph];
            if (!GraphComponent) return null; // or some fallback UI

            return (
              <div className="card bg-base-100 shadow-md p-6 xl:col-span-1" key={nanoid()}>
                <p className="text-lg font-semibold uppercase">
                  {type?.name}
                </p>
                <GraphComponent data={data?.data} name={data?.name} />
              </div>
            );
          })}
      </div>
  );
};

Screen.propTypes = {
  graphData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string, // Each graph item must have a 'name' field
      data: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
      ]), // Each graph item must have a 'data' field which could be an array or an object
    })
  ),
};
