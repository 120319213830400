import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assignments: [],
  assignmentStatusList: [],
  serviceList: [],
  notificationcount: "",
  selectedTableRow: null,

  assignmentFilters: {
    dropDownSelectedValue: "",
    assignmentJobStatus: "",
    reservationID: 0,
    reservationsAssignmentsID: 0,
    contractorid: 0,
    contractorName: "",
    claimantid: 0,
    claimantName: "",
    dateFrom: "",
    dateTo: "",
    claimID: 0,
    claimantID: 0,
    customerID: 0,
    assgncode: "",
    rsvacCode: "",
    conAssignStatus: 0,
    inactiveflag: -2,
    contractorContactInfo: [],
    page: 1,
    limit: 20
},
};

export const AssignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    loadUser: (state) => {
      if (!localStorage.getItem("userAuthApproved")) {
        state.userAuthApproved = false;
        return;
      }
      state.Token = localStorage.getItem("Admintoken");
      state.user = localStorage.getItem("Admin");
      state.userAuthApproved = true;
    },
    setAssignment: (state, action) => {
        state.assignments = action.payload;
    },
    setAssignedContractors: (state, action) => {
        state.assignedContractors = action.payload;
    },
    setErrLoggingIn: (state, action) => {
      state.errLoggingIn = action.payload;
    },
    // Add other reducers here
    setLoginLoading: (state, action) => {
      state.loginLoading = action.payload;
    },
    setAssignmentFilters: (state, action) => {
      state.assignmentFilters = action.payload;
    },
    updateAssignmentFilters: (state, action) => {
      state.assignmentFilters = { ...state.assignmentFilters, ...action.payload };
    },
    resetAssignmentFilters: (state) => {
      state.assignmentFilters = {...initialState.assignmentFilters};
      state.selectedTableRow = null; 
    },
    setSelectedRow: (state, action) => {
      state.selectedTableRow = action.payload;
    },
    setClaimantNameList: (state, action) => {
        state.claimantName = action.payload;
    },
    setContractorNameList: (state, action) => {
        state.contractorName =action.payload;
    },
    setAssignmentStatusList: (state, action) => {
      state.assignmentStatusList = action.payload;
    },
    setServiceList: (state, action) => {
      state.serviceList = action.payload;
    }
   
  },

});

export const {
  setErrLoggingIn,
  setLoginLoading,
  userAuthApproved,
  loadUser,
  setAssignmentFilters,
  updateAssignmentFilters,
  resetAssignmentFilters,
  setSelectedRow,
  setServiceList,
  setAssignment,
  setClaimantNameList,
  setContractorNameList,
  setAssignmentStatusList,
  setAssignedContractors,
} = AssignmentSlice.actions;

export default AssignmentSlice.reducer;
