import React, { useEffect, useState } from "react";
import CardDashboard from "./UIComponents/CardDashboard";
import Graph from "./UIComponents/Graph";
import { getMethodData } from "../../../ApiMethods/Api";
import dashboardreports from "../../../assets/images/dashboardreports.svg";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const AdminDashboard = () => {
  const [cardData, setCardData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dashboardCountList("/Dashboard");
  }, []);

  const dashboardCountList = async (url) => {
    try {
      setLoading(true);
      const response = await getMethodData(url);
      setLoading(false);
      setCardData(response?.data?.data?.counts);
      const { counts, ...rest } = response?.data?.data ?? {};
      setGraphData(Object.values(rest));
    } catch (error) {
      setLoading(false);
      setCardData([]);
    }
  };

  return (

    <>
      <div className="mb-4">
        <p className="text-2xl font-semibold">Welcome To Dashboard</p>
        <p className="opacity-80 text-sm">Home / Dashboard</p>
      </div>
      {loading ? (
        <div
          className="fixed inset-0  top-[1px] rounded-sm"
        >
          <LoadingPage />
        </div>
      ) : (
        <>
          {!graphData?.length ? (
            <div className="flex flex-col items-center justify-center my-20 text-[#6e6e6e]">
              <img
                src={dashboardreports}
                alt="no dashboard data found"
                className=" text-8xl mx-auto h-[150px] w-[auto]"
              />
              <p className="font-semibold text-lg">No Data Found</p>
            </div>
          ) : (
            <CardDashboard cardData={cardData} userRole={"Administrators"} />
          )}
          <Graph graphData={graphData} />
        </>
      )}
    </>
  );
};

export default AdminDashboard;