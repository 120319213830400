import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import {
  getTimeDifference,
  getTimeFromString,
  safeParseJSON,
} from "../../../../utils/helpers";
import movingcar from "../../../../assets/images/car-moving.gif";
import contractorWaiting from "../../../../assets/images/contractorWaiting.gif";
import translateContinue from "../../../../assets/images/translateContinue.gif";
import PropTypes from "prop-types";

const TranslationAssignmentHistory = ({
  fetchLocationName,
  trackingData,
  currentbuttonId,
  index,
}) => {
  const currentButtonID = currentbuttonId;
  const [contractorStartedLocation, setContractorStartedLocation] = useState(null);

  useEffect(() => {
    const location = safeParseJSON(trackingData?.startDriverLatitudeLongitude);
    location && fetchLocationNameFunc(location);
  }, [trackingData]);

  const fetchLocationNameFunc = async (location) => {
    const name = await fetchLocationName(location);
    setContractorStartedLocation(name);
  };

  //------------------------------------end of location name-----------------------------------

  const contractorMovingGif_WithMessage = (message, type) => {
    let imgSrc = "";
    let imgClassName = "";

    if (type === "enroute") {
      imgSrc = movingcar;
      imgClassName = "h-[60px] w-[250px]";
    } else if (type === "waiting") {
      imgSrc = contractorWaiting;
      imgClassName = "h-[100px]";
    } else if (type === "translateContinue") {
      imgSrc = translateContinue;
      imgClassName = "h-[80px] w-[160px]";
    }

    return (
      <div className="flex items-center gap-2">
        <span className="text-[#63b0e0] text-md">{message}</span>
        <span className="mb-[1rem] mt-[1rem]">
          <img
            src={imgSrc}
            className={imgClassName}
            alt={`contractor ${type}`}
          />
        </span>
      </div>
    );
  };

  const assignmentHistoryTitle = () => {
    if (index === 0 && currentButtonID[0] >= 1) return "Dead Miles Details";
    else if (
      index === 1 &&
      trackingData?.claimantPickedupButtonStatus === "START_SESSION"
    )
      return "Translation Details";
  };

  const deadMilesTrip = () => {
    return (
      <>
        {index === 0 && currentButtonID[0] >= 1 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            <span>Translator Started From Their Location</span>
            <textarea
              name="translatorStartedFromTheirLocation"
              id="translatorStartedFromTheirLocation"
              value={contractorStartedLocation || "N/A"}
              readOnly
              className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 0 && currentButtonID[0] >= 1 ? (
          <>
            <label className="grid grid-cols-2 mt-3 items-center" htmlFor="translatorStartedTime">
              Translator Started Time
              <Input
                name="translatorStartedTime"
                id="translatorStartedTime"
                value={getTimeFromString(trackingData?.startDateandTime)}
                readOnly
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
              />
            </label>
            {currentButtonID[0] < 2 &&
              contractorMovingGif_WithMessage(
                "Contractor is en route to the facility location", 
                "enroute"
              )}
          </>
        ) : (
          ""
        )}

        {index === 0 && currentButtonID[0] >= 2 ? (
          <label className="grid grid-cols-2 mt-3 items-center">
            <span>Translator Reached Location</span>
            <textarea
              name="translatorReachedLocation"
              id="translatorReachedLocation"
              value={trackingData?.doAddress1 + " " + trackingData?.doAddress2}
              readOnly
              className="max-h-[10em] pt-[5px] input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 0 && currentButtonID[0] >= 2 ? (
          <label className="grid grid-cols-2 mt-3 items-center" htmlFor="translatorReachedTime">
            Translator Reached Time
            <Input
              name="translatorReachedTime"
              id="translatorReachedTime"
              value={getTimeFromString(trackingData?.reachedDateandTime)}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 0 && currentButtonID[0] >= 2 ? (
          <>
            <label className="grid grid-cols-2 mt-3 items-center" htmlFor="deadMiles">
              Dead Miles
              <Input
                name="deadMiles"
                id="deadMiles"
                value={`${trackingData?.deadMiles || "0"} miles`}
                readOnly
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
              />
            </label>
            {currentButtonID[0] < 3 &&
              contractorMovingGif_WithMessage(
                "Contractor is at location. Waiting for Claimant",
                "waiting"
              )}
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const translationTrip = () => {
    return (
      <>
        {index === 1 && currentButtonID[1] >= 3 ? (
          <label className="grid grid-cols-2 mt-3 items-center" htmlFor="claimantWaitingTime">
            Claimant Waiting Time
            <Input
              name="claimantWaitingTime"
              id="claimantWaitingTime"
              value={getTimeDifference(
                trackingData?.reachedDateandTime,
                trackingData?.claimantPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 1 && currentButtonID[1] >= 3 ? (
          <>
            <label className="grid grid-cols-2 mt-3 items-center" htmlFor="translationStarted">
              Translation Started
              <Input
                name="translationStarted"
                id="translationStarted"
                value={getTimeFromString(
                  trackingData?.claimantPickedupDateandTime
                )}
                readOnly
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
              />
            </label>
            {currentButtonID[1] < 4 &&
              contractorMovingGif_WithMessage(
                "Session is Ongoing",
                "translateContinue"
              )}
          </>
        ) : (
          ""
        )}

        {index === 1 && currentButtonID[1] >= 4 ? (
          <label className="grid grid-cols-2 mt-3 items-center" htmlFor="translationEnded">
            Translation Ended
            <Input
              name="translationEnded"
              id="translationEnded"
              value={getTimeFromString(
                trackingData?.tripEndPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}

        {index === 1 && currentButtonID[0] >= 4 ? (
          <label className="grid grid-cols-2 mt-3 items-center" htmlFor="totalTranslationTime">
            Total Translation Time
            <Input
              name="totalTranslationTime"
              id="totalTranslationTime"
              value={getTimeDifference(
                trackingData?.claimantPickedupDateandTime,
                trackingData?.tripEndPickedupDateandTime
              )}
              readOnly
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </label>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <>
      <div className=" justify-center font-semibold text-xl mb-2">
        {assignmentHistoryTitle()}
      </div>
      <>
        {deadMilesTrip()}
        {trackingData?.claimantPickedupButtonStatus === "START_SESSION" &&
          translationTrip()}
      </>
    </>
  );
};

TranslationAssignmentHistory.propTypes = {
  currentbuttonId: PropTypes.arrayOf(PropTypes.number),
  fetchLocationName: PropTypes.func,
  trackingData: PropTypes.shape({
    startDriverLatitudeLongitude: PropTypes.string,
    startDateandTime: PropTypes.string,
    reachedDateandTime: PropTypes.string,
    rsvattcode: PropTypes.string,
    tripEndButtonStatus: PropTypes.string,
    puAddress1: PropTypes.string,
    puAddress2: PropTypes.string,
    doAddress1: PropTypes.string,
    doAddress2: PropTypes.string,
    deadMiles: PropTypes.number,
    claimantPickedupDateandTime: PropTypes.string,
    tripEndPickedupDateandTime: PropTypes.string,
    travellingMiles: PropTypes.number,
    claimantPickedupButtonStatus: PropTypes.string,
  }),
  index: PropTypes.number,
};

export default TranslationAssignmentHistory;
