import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCustomerFilters } from "../../../../Redux-Toolkit/CustomerSlice";
import { Input } from "react-daisyui";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const CustomersFilter = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerStatesList = useSelector((state) => state.customer.stateList) || [];
  const customerCategoryList = useSelector((state) => state.customer.categoryList) || [];
  const customerFilters = useSelector((state) => state.customer.customerFilters);

  useEffect(() => {
    setCustomerID(customerFilters?.customerID);
    setCustomerName(customerFilters?.customerName);
    setCustomerEmail(customerFilters?.customerEmail);
    setStates(customerFilters?.states);
    setCategory(customerFilters?.category);
    setInactive(customerFilters?.inactive);
  }, [customerFilters])

  const [customerID, setCustomerID] = useState(customerFilters?.customerID || 0);
  const [customerName, setCustomerName] = useState(customerFilters?.customerName || "");
  const [customerEmail, setCustomerEmail] = useState(customerFilters?.customerEmail || "");
  const [states, setStates] = useState(customerFilters?.states || "");
  const [category, setCategory] = useState(customerFilters?.category || "");
  const [inactive, setInactive] = useState(customerFilters?.inactive || -2);

  const Reset = () => {

    setCustomerID(0);
    setCustomerName("");
    setCustomerEmail("");
    setStates("");
    setCategory("");
    setInactive(-2);

    const data = {
      customerID: 0,
      customerName: "",
      customerEmail: "",
      email: "",
      states: "",
      category: "",
      inactive: -2,
      page: 1,
      limit: 20,
    };
    dispatch(setCustomerFilters(data));
    navigate("/customer-management");
  };

  return (

    <div className="card bg-base-100 shadow-md p-6 mt-3 mb-3">
      <form
        onSubmit={(e) => {
          const data = {
            ...(customerID && customerID.trim() !== "" && { customerID }),
            ...(customerName && customerName.trim() !== "" && { customerName }),
            ...(customerEmail && customerEmail.trim() !== "" && { email: customerEmail }),
            ...(states && { states }),
            ...(category && { category }),
            ...(inactive &&
              !isNaN(Number(inactive)) && {
              inactive: Number(inactive),
            }),
            page: 1,
            limit: 20,
          };
          dispatch(setCustomerFilters(data));
          e.preventDefault();
        }}
      >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          <div>
            <label
              htmlFor="clientid"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Customer ID
            </label>
            <Input
              name="clientid"
              placeholder="Customer ID"
              onChange={(e) => 
               { const value = e.target.value;
                if ( /^\d*$/.test(value)) {
                setCustomerID(e.target.value)
                }}}
              value={customerID === 0 ? "" : customerID}
              id="clientid"
              type="text"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Customer Name
            </label>
            <Input
              name="name"
              placeholder="Customer Name"
              onChange={(e) => setCustomerName(e.target.value)}
              value={customerName}
              id="name"
              type="text"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Email
            </label>
            <Input
              name="email"
              placeholder="Customer Email"
              onChange={(e) => setCustomerEmail(e.target.value)}
              value={customerEmail}
              id="email"
              type="text"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>

          <div>
            <label
              htmlFor="state"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              State
            </label>
            <select
              value={states}
              id="state"
              name="state"
              onChange={(e) => setStates(e.target.value)}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="" className="">
                All
              </option>
              {customerStatesList &&
                customerStatesList?.length > 0 &&
                customerStatesList?.map((state, index) => (
                  <option value={state.code} key={state.code}>
                    {state.description}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="category"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Category
            </label>
            <select
              value={category}
              id="category"
              name="category"
              onChange={(e) => setCategory(e.target.value)}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="">ALL</option>
              {customerCategoryList &&
                customerCategoryList?.length > 0 &&
                customerCategoryList?.map((category, index) => (
                  <option value={category.code} key={category.code}>
                    {category.description}
                  </option>
                ))}
            </select>
          </div>
          
          <div>
            <label
              htmlFor="Status"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Status
            </label>
            <select
              id="Status"
              onChange={(e) => setInactive(e.target.value)}
              name="Status"
              value={inactive}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value={-2}>All</option>
              <option value={0}>Active</option>
              <option value={-1}>Inactive </option>
            </select>
          </div>
        </div>
        <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
          <button
            type="reset"
            onClick={() => {
              Reset();
            }}
            className="btn btn-md bg-[#000] text-[#63b0e0] text-sm hover:bg-[#000]"
          >
            <MdOutlineRefresh className="mr-1" />
            Reset
          </button>
          <button className="btn btn-md bg-[#63b0e0] text-[#000] text-sm hover:text-[#63b0e0] hover:bg-[#000] ml-4">
            <MdOutlineSearch className="mr-1" />
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomersFilter;
