import React from "react";
import { Input } from "react-daisyui";
import { formatDate } from "../../../../utils/helpers";
import { Watermark } from "antd";
import PropTypes from "prop-types";

const ReservationDetails = ({ assignmentDetails }) => {
  const watermarkContent = () => {
    if (
      assignmentDetails?.inactiveflag === 0 &&
      assignmentDetails?.rsvacCode === "ResCancel"
    ) {
      return "Reservation Canceled";
    } else if (
      assignmentDetails?.inactiveflag === -1 &&
      assignmentDetails?.rsvacCode === "ResCancel"
    ) {
      return "Assignment & Reservation Canceled";
    } else if (
      assignmentDetails?.inactiveflag === -1 &&
      assignmentDetails?.rsvacCode !== "ResCancel"
    ) {
      return "Assignment Canceled";
    } else return "JNJ Services";
  };

  return (
    <div className="card bg-base-100 shadow-md p-6 mt-8">
      <Watermark
        content={watermarkContent() || ""}
        zIndex={11}
        rotate={-30}
        height={
          watermarkContent() !== "JNJ Services" ||
          assignmentDetails?.inactiveflag === -1 ||
          assignmentDetails?.rsvacCode === "ResCancel"
            ? 26
            : 0
        }
        gap={[200, 200]}
        font={{
          color: "rgba(255, 0, 0, 0.4)", // Set the desired color
          fontSize: 26, // Set the desired font size
          fontWeight: "normal", // Set the desired font weight
          fontStyle: "normal", // Set the desired font style
          fontFamily: "Arial, sans-serif", // Set the desired font family
          textAlign: "center", // Set the desired text alignment
        }}
      >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          <div>
            <label
              htmlFor="assignmentID"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Assignment ID
            </label>
            <Input
              name="assignmentID"
              id="assignmentID"
              value={assignmentDetails?.assgnNum || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="reservationID"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Reservation ID
            </label>
            <Input
              name="reservationID"
              value={assignmentDetails?.reservationid || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="contractorID"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Contractor ID
            </label>
            <Input
              name="contractorID"
              id="contractorID"
              value={assignmentDetails?.contractorid || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="claimID"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim ID
            </label>
            <Input
              name="claimID"
              id="claimID"
              value={assignmentDetails?.claimID || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="contractorName"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Contractor Name
            </label>
            <Input
              name="contractorName"
              id="contractorName"
              value={assignmentDetails?.contractor || "Not Confirmed"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="claimantName"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claimant Name
            </label>
            <Input
              name="claimantName"
              id="claimantName"
              value={assignmentDetails?.claimantName || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="company"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Company
            </label>
            <Input
              name="company"
              id="company"
              value={assignmentDetails?.contractorCompany || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="contractorEmail"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Contractor Email
            </label>
            <Input
              name="contractorEmail"
              id="contractorEmail"
              value={assignmentDetails?.contractorEmail || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="contractorContactNumber"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Contractor Contact Number
            </label>
            <Input
              name="contractorContactNumber"
              id="contractorContactNumber"
              value={
                assignmentDetails?.contractorhomephone ||
                assignmentDetails?.contractorcellphone ||
                assignmentDetails?.contractorworkphone ||
                assignmentDetails?.contractorFax ||
                "N/A"
              }
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="claimantContactNumber"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claimant Contact Number
            </label>
            <Input
              name="claimantContactNumber"
              id="claimantContactNumber"
              value={
                assignmentDetails?.hmPhone ||
                assignmentDetails?.contractorcellphone ||
                assignmentDetails?.contractorworkphone ||
                assignmentDetails?.contractorFax ||
                "N/A"
              }
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="facility"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Facility
            </label>
            <Input
              name="facility"
              id="facility"
              value={
                (assignmentDetails?.doFacilityName ??
                  assignmentDetails?.doFacilityName2) ||
                "N/A"
              }
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
          <div>
            <label
              htmlFor="service"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Service
            </label>
            <Input
              name="service"
              id="service"
              value={assignmentDetails?.assgncode || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="language"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Language
            </label>
            <Input
              name="language"
              id="language"
              value={assignmentDetails?.language || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="pickupTime"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Pickup Time
            </label>
            <Input
              name="pickupTime"
              id="pickupTime"
              value={assignmentDetails?.pickupTime || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="pickupDate"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Pickup Date
            </label>
            <Input
              name="pickupDate"
              id="pickupDate"
              value={formatDate(assignmentDetails?.reservationDate)}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="pickupAddress"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Pickup Address
            </label>
            <textarea
              name="pickupAddress"
              value={
                assignmentDetails?.resTripType !== "Back End"
                  ? assignmentDetails?.puAddress1 +
                      assignmentDetails?.puAddress2 || "N/A"
                  : assignmentDetails?.doAddress1 +
                      assignmentDetails?.doAddress2 || "N/A"
              }
              id="pickupAddress"
              type="text"
              disabled
              autoComplete="off"
              className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="dropoffAdress"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Dropoff Address
            </label>
            <textarea
              name="dropoffAddress"
              value={
                assignmentDetails?.resTripType !== "Back End"
                  ? assignmentDetails?.doAddress1 +
                      assignmentDetails?.doAddress2 || "N/A"
                  : assignmentDetails?.puAddress1 +
                      assignmentDetails?.puAddress2 || "N/A"
              }
              id="dropoffAddress"
              type="text"
              disabled
              autoComplete="off"
              className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="tripType"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Trip Type
            </label>
            <Input
              name="tripType"
              id="tripType"
              value={assignmentDetails?.resTripType || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>

          <div>
            <label
              htmlFor="actionCode"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Action Code
            </label>
            <Input
              name="actionCode"
              id="actionCode"
              value={assignmentDetails?.actionCode || "N/A"}
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
            />
          </div>
        </div>
      </Watermark>
    </div>
  );
};

ReservationDetails.propTypes = {
  assignmentDetails: PropTypes.shape({
    inactiveflag: PropTypes.number,
    rsvacCode: PropTypes.string,
    reservationsAssignmentsID: PropTypes.number,
    reservationid: PropTypes.number,
    contractorid: PropTypes.number,
    claimID: PropTypes.number,
    contractor: PropTypes.string,
    claimantName: PropTypes.string,
    contractorCompany: PropTypes.string,
    contractorEmail: PropTypes.string,
    contractorhomephone: PropTypes.string,
    contractorcellphone: PropTypes.string,
    contractorworkphone: PropTypes.string,
    contractorFax: PropTypes.string,
    hmPhone: PropTypes.string,
    doFacilityName: PropTypes.string,
    doFacilityName2: PropTypes.string,
    assgncode: PropTypes.string,
    language: PropTypes.string,
    pickupTime: PropTypes.string,
    reservationDate: PropTypes.string,
    puAddress1: PropTypes.string,
    puAddress2: PropTypes.string,
    doAddress1: PropTypes.string,
    doAddress2: PropTypes.string,
    resTripType: PropTypes.string,
    actionCode: PropTypes.string,
    assgnNum: PropTypes.string,
  }),
};

export default ReservationDetails;
