import { toast } from "react-toastify";
import { postMethodData } from "../../../ApiMethods/Api";

export const postImage = async (url, imageData) => {
    const formData = new FormData();
    formData.append("file", imageData);
  
    try {
      const { data } = await postMethodData(url, formData);
      const { status, statusMessage, data: imageData } = data;
  
      if (status) {
        toast.success(statusMessage);
        return imageData?.imageUrl || "";
      }
  
      toast.error(statusMessage);
      return "";
    } catch (error) {
      toast.error("Failed to upload image");
      return "";
    }
  };