import React, { useState, useEffect } from "react";
import { Input } from "react-daisyui";
import { Link, useParams } from "react-router-dom";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ViewClaimApprovedContractorDetails = () => {

  const { claimID } = decryptParamID(useParams()); // get id from URL parameters
  const [claimFacilityDetails, setClaimFacilityDetails] = useState([]);
  const [claimApprovedDetailsDataLoading, setClaimApprovedDetailsDataLoading] = useState(false);

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    claimApprovedList("/ClaimsApprovedContractors", Number(claimID));
  }, []);

  const claimApprovedList = async (url, claimID) => {
    const body = {
      claimID: claimID,
    };
    try {
      const response = await postMethodData(url, body);
      if (response?.data?.data?.length === 0) {
        setClaimFacilityDetails([]);
        setClaimApprovedDetailsDataLoading(true);
      } else {
        setClaimFacilityDetails(response?.data?.data[0]);
        setClaimApprovedDetailsDataLoading(true);
      }
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setClaimFacilityDetails([]);
    }
  };

  return (
    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            Details for claim ID: {claimID ?? "N/A"}
          </p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
        data-testid="claimApprovedContractor-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="claimid"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Claim ID
              </label>
              <Input
                name="claimid"
                value={claimFacilityDetails?.claimID || "N/A"}
                id="claimid"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="contractorid"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Contractor ID
              </label>
              <Input
                name="contractorid"
                value={claimFacilityDetails?.contractorID || "N/A"}
                id="contractorid"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="contractorName"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Contractor Name
              </label>
              <Input
                name="contractorName"
                value={claimFacilityDetails?.contractorName || "N/A"}
                id="contractorName"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="service"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Service
              </label>
              <Input
                name="service"
                value={claimFacilityDetails?.service || "N/A"}
                id="service"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="mile"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Miles
              </label>
              <Input
                name="mile"
                value={claimFacilityDetails?.miles || "N/A"}
                id="mile"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="note"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Notes
              </label>
              <Input
                name="note"
                value={claimFacilityDetails?.notes || "N/A"}
                id="note"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="priority"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Priority
              </label>
              <Input
                name="priority"
                value={claimFacilityDetails?.prioritySort || "N/A"}
                id="priority"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between" />
        </form>
      </div>
      {claimApprovedDetailsDataLoading && (
        <div
          className="fixed inset-0  top-[1px] rounded-sm"
          style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
        >
          <LoadingPage />
        </div>
      )}
    </>
  );
};

export default ViewClaimApprovedContractorDetails;
