import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./AuthSlice";
import customerReducer from "./CustomerSlice";
import claimantsReducer from "./ClaimantsSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import staffReducer from "./StaffSlice";
import contractorReducer from "./ContractorSlice";
import reservationReducer from "./ReservationSlice";
import claimsReducer from "./ClaimsSlice";
import newUserPasswordChangeReducer from "./NewUserPasswordChangeSlice";
import assignmentReducer from "./AssignmentSlice";
import contractorRatesReducer from "./ContractorRateDetailsSlice";
import notificationReducer from "./NotificationSlice";
import metricsReducer from "./MatricsSlice";
import signalRSlice from "./signalRSlice"
import AssignmentTrackingStatsReducer from "./AssignmentTrackingStatsSlice";
import SettingsReducer from "./SettingSlice";
import { createLogger } from "redux-logger";

const logger = createLogger({
  collapsed: true,
  predicate: true,
  diff: true,
  duration: true,
  timestamp: true,
  level: console.warn,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'newUserPasswordChange',
    'customer',
    'claimants',
    'staff',
    'contractor',
    'reservation',
    'claims',
    'assignments',
    'contractorRates',
    'notifications',
    'metrics',
    'signalR',
    'assignmentTrackingStats',
    'settings',
  ],
};

const rootReducer = combineReducers({
  auth: authReducer,
  newUserPasswordChange: newUserPasswordChangeReducer,
  customer: customerReducer,
  claimants: claimantsReducer,
  staff: staffReducer,
  contractor: contractorReducer,
  reservation: reservationReducer,
  claims: claimsReducer,
  assignments: assignmentReducer,
  contractorRates: contractorRatesReducer,
  notifications: notificationReducer,
  metrics: metricsReducer,
  signalR: signalRSlice,
  assignmentTrackingStats: AssignmentTrackingStatsReducer,
  settings: SettingsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(process.env.NODE_ENV === "development" ? logger : []),
});

export const persistor = persistStore(store);

export default store;
