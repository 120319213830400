import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import rate_list from "../../../assets/JNJ-icons/rate_list.svg";
import { useDispatch, useSelector } from "react-redux";
import { setContractorRateSelectedRow } from "../../../Redux-Toolkit/ContractorSlice";
import { formatDate, inactiveFlagClass } from "../../../utils/helpers";
import ReactPagination from "../../../utils/ReactPagination";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ContractorRatesTable = () => {

  const { id } = decryptParamID(useParams());
  const { contractorname } = useParams();
  const { ratecodeid } = decryptParamID(useParams());
  const dispatch = useDispatch();

  const [contractorRateTableList, setContractorRateTableList] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const tableRef = useRef();
  const rowRefs = useRef([]);
  const [contractorDataLoading, setContractorDataLoading] = useState(true);

  const [currentPage] = useState(0);
  const ITEMS_PER_PAGE = 50;

  const selectedRow = useSelector((state) => state?.contractor?.contractorRateTableSelectedTableRow);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setContractorDataLoading(false);
    contractorRatesList(
      "/ContractorRates",
      {
        rateCode: ratecodeid,
        stateCode: "",
        effectiveDate: "",
        inactiveflag: -2,
      },
      (response) => {
        if (!response?.data?.status) {
          setContractors([]);
        } else if (response?.data?.status) {
          setContractors(response?.data?.data);
          return response;
        }
      }
    );

    contractorRatesList("/ContractorSelDetail",
      {
        contractorID: id,
      },
      (response) => {
      if (response?.data?.status === true) {
        setCompanyName(response?.data?.data[0]?.company);
      } else {
        setCompanyName([]);
      }
    });

  }, []);

  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && tableRef.current) {
      const td = document.getElementById(selectedRow);
      if (td) {
        td.scrollIntoView({ block: "center" });
      }
    }
  }, [contractors]);

  const contractorRatesList = async (url, body, callback) => {
    try {
      const data = body;
      const response = await postMethodData(url, data);
      if (response?.data?.status === true) {
        setContractorRateTableList(response?.data?.data);
        callback(response);
      } else {
        setContractorRateTableList([]);
      }
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer claimant list:",
        error
      );
    }
  };

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  /**
   * Page change options to be used into ReactPagination component
   */

  const pageChangeOptions = {
    endPointFunction: contractorRatesList,
    endpoint: "/ContractorRates",
    objectBodyForEndpoint: {
      rateCode: ratecodeid,
      stateCode: "",
      effectiveDate: "",
      inactiveflag: -2,
    },
    setterAction: setContractors,
    itemsPerPage: ITEMS_PER_PAGE,
    scrollElementId: "tablescroll", // Optional, only if you want to scroll
  };

  return (
    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            Rates Table For: {id} | {companyName}
          </p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6">
        <div className="overflow-x-auto w-full">
          <table
            ref={tableRef}
            id="tablescroll"
            className="table table-zebra w-full"
          >
            <thead>
              <tr>
                <th></th>
                <th style={{ whiteSpace: "normal" }}>rate Code</th>
                <th style={{ whiteSpace: "normal" }}>Description</th>
                <th style={{ whiteSpace: "normal" }}>Eff date From</th>
                <th style={{ whiteSpace: "normal" }}>Eff Date To</th>
                <th style={{ whiteSpace: "normal" }}>State</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {contractorDataLoading &&
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={nanoid()}>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                ))}

              {contractors?.length != 0 &&
                contractors?.map((data, index) => (
                  <tr
                    key={nanoid()}
                    id={data.contractorRatesID}
                    className={`table-row-align-top ${
                      data?.contractorRatesID === selectedRow
                        ? "activeTableRow"
                        : ""
                    }`}
                    ref={(ele) => (rowRefs.current[index] = ele)}
                    onClick={() => {
                      dispatch(
                        setContractorRateSelectedRow(data?.contractorRatesID)
                      );
                    }}
                  >
                    <td>{currentPage * ITEMS_PER_PAGE + index + 1}</td>

                    <td>{data?.ratectCode || "N/A"}</td>
                    <td className=" capitalize">
                      {data?.rateDescription || "N/A"}
                    </td>
                    <td className=" capitalize">
                      {formatDate(data?.effectiveDateFrom)}
                    </td>
                    <td className=" capitalize">
                      {formatDate(data?.effectiveDateTo)}
                    </td>
                    <td>{data?.stateCode}</td>
                    <td>
                      <label
                        htmlFor="my-modal-5"
                        className={`btn capitalize ${inactiveFlagClass(data)} 
                        text-[#000] border-hidden  btn-xs cursor-default`}
                      >
                        {data?.inactiveflag ? "Inactive" : "Active"}
                      </label>
                    </td>
                    <td className=" text-base">
                      <div className="flex items-center space-x-4">
                        <button title="">
                          <span className="flex items-center space-x-4">
                            <span
                              className="tooltip text-left"
                              data-tip="View Rate Details"
                            >
                              <Link
                                className="cursor-pointer"
                                to={`/contractor-management/contractor-rate-details/${contractorname}/${data?.ratectCode}/${data?.contractorRatesID}`}
                              >
                                <img
                                  className="cursor-pointer max-w-none"
                                  alt="Claims"
                                  src={rate_list}
                                />
                              </Link>
                            </span>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}

              {!contractorDataLoading &&
                contractorRateTableList?.length == 0 && (
                  <tr>
                    <td colSpan={11} className="my-10 text-center">
                      <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                      <p className=" font-semibold text-lg">No Details Found</p>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          {contractorRateTableList?.length !== 0 && (
            <ReactPagination
              forcePage={currentPage - 1}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              // onPageChange={handlePageChange}
              pageChangeOptions={pageChangeOptions}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}
        </div>
      </div>
      {contractorDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
    </>
  );
};

export default ContractorRatesTable;
