import React, { useState } from "react";
import { MdOutlineVpnKey } from "react-icons/md";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import Logo from "../../assets/images/JNJ Services-logo-large art-vector.png";
import backgroundImg from "../../assets/images/1920-darkflakes.svg";
import { postLogin } from "../../ApiMethods/Api";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

const ForgotPassword = ({dataTestId}) => {
  const navigate = useNavigate();

  const [passwordLoading, setPasswordLoading] = useState(false);
  const [newShowPassword, setNewShowPassword] = useState(false);
  const [confirmNewShowPassword, setConfirmNewShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const passwordChange = async () => {
    const body = {  
      encryptID: id,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };

    try {
      setPasswordLoading(true);
      const response = await postLogin("/UpdatePasswordByEmail", body);
      setPasswordLoading(false);

      if (response?.data?.status === false) {
        toast.error(response?.data?.statusMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (response?.data?.status === true) {
        toast.success(
          "Password has been reset. Please login with your new password.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          }
        );
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      setPasswordLoading(false);
      console.error("error occured", error);
    }
  };

  const handleSubmit = () => {
    const passwordValidation =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

    if (newPassword !== confirmPassword) {
      toast.error("New Password and confirm password do not match", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return;
    }

    if (!passwordValidation.test(newPassword)) {
      toast.error("Password doesn't meet the criteria", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return;
    }
    passwordChange();
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
      }}
      className="flex justify-center items-center w-full h-screen md:py-0 py-5 bg-no-repeat bg-fixed object-cover object-top"
    >
      <div className=" grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 bg-[#f7f7f7] shadow-lg rounded-xl 2xl:w-6/12 lg:w-7/12 md:w-9/12 w-11/12 overflow-hidden">
        <div className=" bg-[#90d8f5] p-5 lg:col-span-2 flex justify-center items-center">
          <div>
            <div className="">
              <img className="w-[100%] mx-auto" alt="JNJ logo" src={Logo} />
            </div>
            <div className="text-[#000] text-center mt-5"></div>
          </div>
        </div>
        <div className="p-5 lg:col-span-3">
          <p className=" text-lg font-semibold text-[orange]">
            {" "}
            Reset your password
          </p>
          <div className="mt-5">
            <form
              autoComplete="off"
              onSubmit={(e) => {
                passwordChange();
                handleSubmit();
                e.preventDefault();
              }}
            >
              <div className="pt-4">
                <div className="form-control mt-2">
                  <span className="label-text font-semibold mb-2">
                    New Password
                  </span>
                  <label className="input-group">
                    <span className=" bg-[#000]">
                      <MdOutlineVpnKey className=" text-[#63b0e0]" />
                    </span>
                    <input
                      autoComplete={false}
                      onChange={(e) => setNewPassword(e.target.value)}
                      value={newPassword}
                      required
                      name="newpassword"
                      type={newShowPassword ? "text" : "password"}
                      placeholder="New Password"
                      className="input w-full border-[#000] focus:outline-none border-r-0"
                    />

                    <button
                      type="button"
                      aria-label={
                        newShowPassword ? "Hide password" : "Show password"
                      } // Accessible label for screen readers
                      className="bg-transparent cursor-pointer border-[#000] border border-l-0"
                      onClick={() => setNewShowPassword(!newShowPassword)}
                      data-testid={`${dataTestId}-newPassword`}
                    >
                      {newShowPassword ? (
                        <RxEyeOpen className="text-[#000] mr-3 ml-3" />
                      ) : (
                        <RxEyeClosed className="text-[#000] mr-3 ml-3" />
                      )}
                    </button>
                  </label>
                </div>
                <div className="form-control mt-2">
                  <span className="label-text font-semibold mb-2 mt-2">
                    Confirm Password
                  </span>
                  <label className="input-group">
                    <span className=" bg-[#000]">
                      <MdOutlineVpnKey className=" text-[#63b0e0]" />
                    </span>
                    <input
                      autoComplete={false}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      name="confirmpassword"
                      value={confirmPassword}
                      type={confirmNewShowPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      className="input w-full border-[#000] focus:outline-none border-r-0"
                    />

                    <button
                      type="button"
                      aria-label={
                        confirmNewShowPassword
                          ? "Hide password"
                          : "Show password"
                      } // Accessible label for screen readers
                      className="bg-transparent cursor-pointer border-[#000] border border-l-0"
                      onClick={() =>
                        setConfirmNewShowPassword(!confirmNewShowPassword)
                      }
                      data-testid={`${dataTestId}-confirmPasword`}
                    >
                      {confirmNewShowPassword ? (
                        <RxEyeOpen className="text-[#000] mr-3 ml-3" />
                      ) : (
                        <RxEyeClosed className="text-[#000] mr-3 ml-3" />
                      )}
                    </button>
                  </label>
                  <span className=" text-sm mt-2 text-[#ff3f3f]">
                    *Passwords must have at least 8 characters and contain
                    combination of the following: uppercase letters, lowercase
                    letters, numbers and symbols.
                  </span>
                </div>
                <div className=" flex justify-center ">
                  {!passwordLoading ? (
                    <button
                      type="submit"
                      className="mt-5 btn w-[50%] bg-[#63b0e0] hover:bg-[#000] text-[#000] hover:text-[#63b0e0] font-semibold border-none"
                    >
                      Update Password
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-[#63b0e0] hover:text-[#63b0e0] font-semibold border-none flex justify-center items-center"
                    >
                      <svg
                        className="animate-spin mr-1 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

// PropTypes validation
ForgotPassword.propTypes = {
  dataTestId: PropTypes.string // Assuming id is a string
};

export default ForgotPassword;
