import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { postMethodData } from "../../../ApiMethods/Api";
import ContractorRateDetailsFilter from "./ContractorRateDetailsFilter/ContractorRateDetailsFilter";
import { useDispatch, useSelector } from "react-redux";
import {
  resetContractorRatesFilters,
  setContractorRates,
  updateContractorRatesFilters,
} from "../../../Redux-Toolkit/ContractorRateDetailsSlice";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ContractorRateDetails = () => {

  const dispatch = useDispatch();

  const contractorSearchListData = useSelector((state) => state?.contractorRates?.contractorRates) || [];
  const contractorRateFilters = useSelector((state) => state?.contractorRates?.contractorRateFilters) || [];

  const { contractorRateID } = useParams(); //get contractor rate id from params
  const [contractorDataLoading, setContractorDataLoading] = useState(true);

  const [currentPage] = useState(0);
  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(
      updateContractorRatesFilters({
        ContractorRatesID: Number(contractorRateID),
      })
    );
  }, []);

  useEffect(() => {
    contractorRatesList(
      "/ContractorRateDetails",
      contractorRateFilters,
      (response) => {

        if (!response?.data?.status) {
          dispatch(setContractorRates([]));
        } else if (response?.data?.status) {
          dispatch(setContractorRates(response?.data?.data));
        }
      }
    );
  }, [contractorRateFilters]);

  const contractorRatesList = async (url, data, callback) => {
    const body = data;
    try {
      setContractorDataLoading(true);
      const response = await postMethodData(url, body);
      callback(response);
      setContractorDataLoading(false);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setContractorDataLoading(false);
      dispatch(setContractorRates([]));
    }
  };

  const goBack = () => {
    dispatch(resetContractorRatesFilters());
    window.history.back(); // Go back to the previous page
  };

  return (
    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            Rate Table Details for #{contractorRateID}
          </p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>

      <ContractorRateDetailsFilter />

      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th></th>
                <th data-testid="acctgCode" style={{ whiteSpace: "normal" }}>Acctg Code</th>
                <th style={{ whiteSpace: "normal" }}>UOM</th>
                <th data-testid="trntyName" style={{ whiteSpace: "normal" }}>Transport Name</th>
                <th style={{ whiteSpace: "normal" }}>Language</th>
                <th style={{ whiteSpace: "normal" }}>Rate</th>
                <th className="whitespace-normal">Threshold Metric Qty</th>
                <th className="whitespace-normal">Minimum Pay Qty</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {contractorDataLoading &&
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={nanoid()}>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                ))}

              {contractorSearchListData?.length != 0 &&
                contractorSearchListData?.map((data, index) => (
                  <tr key={nanoid()}>
                    <td>{currentPage * ITEMS_PER_PAGE + index + 1}</td>
                    <td data-testid="acctgCode">{data?.acctgCode || "N/A"}</td>
                    <td>{data?.uom}</td>
                    <td data-testid="trntyName">{data?.trntyName || ""}</td>
                    <td>{data?.language}</td>
                    <td>{data?.rate}</td>
                    <td className=" text-center">{data?.tmQty}</td>
                    <td className=" text-center">{data?.mpQty}</td>
                  </tr>
                ))}

              {!contractorDataLoading &&
                contractorSearchListData?.length == 0 && (
                  <tr>
                    <td colSpan={11} className="my-10 text-center">
                      <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                      <p className=" font-semibold text-lg">No Details Found</p>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
      {contractorDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
            role="loader"
          >
            <LoadingPage />
          </div>
        )}
    </>
  );
};

export default ContractorRateDetails;
