import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import { formatDate } from "../../../utils/helpers";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ContractorVehiclesList = () => {

  const { id } = decryptParamID(useParams());
  const [contractors, setContractors] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [contractorDataLoading, setContractorDataLoading] = useState(true);

  const currentPage = 0;
  const ITEMS_PER_PAGE = 50;

  useEffect(() => {

    setContractorDataLoading(false);
    getData("/ContractorVehicle", id, (response) => {

      if (!response?.data?.status) {
        setContractors([]);
      } else if (response?.data?.status) {
        setContractors(response?.data?.data);
        return response;
      }
    });

    getData("/ContractorSelDetail", id, (response) => {
      if (response?.data?.status === true) {
        setCompanyName(response?.data?.data[0]?.company);
      } else {
        setCompanyName([]);
      }
    });

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const getData = async (url, id, callback) => {
    try {
      const data = {
        contractorID: id,
      };

      const response = await postMethodData(url, data);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer claimant list:",
        error
      );
    }
  };

  const goBackHandler = () => {
    window.history.back(); // Go back to the previous page
  };

  return (
    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            Vehicle List for: {id} | {companyName}
          </p>
        </div>
        <div>
          <Link
            onClick={goBackHandler}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th></th>
                <th>VIN</th>
                <th>Make</th>
                <th>Model</th>
                <th>Year</th>
                <th>Vehicle Size</th>
                <th>Registered Owner</th>
                <th>Regstered State</th>
                <th>Insurance Company</th>
                <th>Insured</th>
                <th>Policy Number</th>
                <th>Policy Expiration</th>
                <th>Limit</th>
                <th>Is Primary</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {contractorDataLoading &&
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={nanoid()}>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                ))}

              {contractors
                .map((data, index) => (
                  <tr key={nanoid()}>
                    <td>{currentPage * ITEMS_PER_PAGE + index + 1}</td>
                    <td>{data?.vin || "N/A"}</td>
                    <td className=" capitalize">{data?.carMake || "N/A"}</td>

                    <td>{data?.carModel || "N/A"}</td>
                    <td className=" capitalize">{data?.carYear || "N/A"}</td>

                    <td>{data?.vehszName || "N/A"}</td>
                    <td>{data?.registeredOwner || "N/A"}</td>

                    <td className="capitalize">{data?.stateName || "N/A"}</td>

                    <td className=" capitalize">
                      {data?.insuranceCo || "N/A"}
                    </td>
                    <td className=" capitalize">{data?.insured || "N/A"}</td>

                    <td className=" capitalize">
                      {data?.policyNumber || "N/A"}
                    </td>
                    <td className=" capitalize">
                      {formatDate(data?.policyExpiration)}
                    </td>

                    <td>{data?.limit || "N/A"}</td>

                    <td>
                      <span>{data?.isPrimary ? "Yes" : "No"}</span>
                    </td>
                  </tr>
                ))}

              {!contractorDataLoading && contractors?.length === 0 && (
                <tr>
                  <td colSpan={11} className="my-10 text-center">
                    <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Vehicle Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {contractorDataLoading && (
        <div
          role="loader"
          className="fixed inset-0  top-[1px] rounded-sm"
          style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
        >
          <LoadingPage />
        </div>
      )}
    </>
  );
};

export default ContractorVehiclesList;
