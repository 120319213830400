import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import { useDispatch, useSelector } from "react-redux";
import { setmetricsList } from "../../../Redux-Toolkit/MatricsSlice";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const MetricsManagementDetail = () => {

  const dispatch = useDispatch  ();
  const {id} = decryptParamID(useParams());

  const [metricList, setMetricList] = useState([]);
  const [metricManagementDataLoading, setMetricManagementDataLoading] = useState(false);
  const [currentPage] = useState(0);
  const ITEMS_PER_PAGE = 20;
  const currentUserList = metricList || [];
  const rowRefs = useRef([]);

  const selectedRow = useSelector((state) => state?.metrics?.selectedTableRow);

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    listMetric("/AssignmnetMetricsDetails",
      Number(id),
      (response) => {
        if (response?.data?.data?.length === 0) {
          //to update this qwith statua as true or false withj boolean value from response once himanshu updates response
          setMetricList([]);
        } else setMetricList(response?.data?.data);
      }
    );
  }, []);

  /**
   * useEffect to scroll to the selectedRow when the claimsList changes as side effect
   */
  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && rowRefs.current[selectedRow]) {
      const row = rowRefs.current[selectedRow];
      if (row) {
        row.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [metricList]);


  const listMetric = async (url, id, callback) => {
    const body = {
      reservationsAssignmentsID: id,
    };
    try {
      setMetricManagementDataLoading(true);
      const response = await postMethodData(url, body);
      callback(response);
      setMetricManagementDataLoading(false);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setMetricManagementDataLoading(false);
      setMetricList([]);
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Metric Details for Assignment ID #{metricList[0]?.assgnNum} </p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th></th>
                <th>Acctg Code</th>
                <th>Metric UOM</th>
                <th>Quantity</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
              {metricManagementDataLoading &&
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={nanoid()}>
                    <td colSpan={13} className="animate-pulse py-6"></td>
                  </tr>
                ))}

              {(!metricManagementDataLoading && currentUserList?.length === 0
                ? metricList
                : currentUserList
              )?.map((data, index) => (
                <tr
                  key={nanoid()}
                  id={index}
                  className={`table-row-align-top ${
                    index === selectedRow ? "activeTableRow" : ""
                  }`}
                  ref={(ele) => (rowRefs.current[index] = ele)}
                  onClick={() => {
                    dispatch(setmetricsList(index));
                  }}
                  >
                    <td>{currentPage * ITEMS_PER_PAGE + index + 1}</td>
                    <td>{(data?.code + " (" + data?.description + ")") || "N/A"}</td>
                    <td>{data?.metricunitofmeasure || "N/A"}</td>

                    <td>{data?.quantity || 0}</td>

                    <td>{data?.notes || "N/A"}</td>
                  </tr>
                ))}

                {!metricManagementDataLoading && metricList?.length === 0 && (
                  <tr>
                    <td colSpan={11} className="my-10 text-center">
                      <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                      <p className=" font-semibold text-lg">
                        No Metric Detail Found
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {metricManagementDataLoading && (
          <div
            data-testid="loading-page"
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
    </>
  );
};

export default MetricsManagementDetail;
