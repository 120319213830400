import React from "react";
import { Input } from "react-daisyui";
import { formatDate } from "../../../../utils/helpers";
import moment from "moment";
import { Watermark } from "antd";
import PropTypes from "prop-types";

const AssignmentCancellationReviews = ({
  assignmentCancelled,
  assignmentDetails,
}) => {
  const watermarkContent = () => {
    let content = "";

    if (
      assignmentDetails?.inactiveflag === 0 &&
      assignmentDetails?.rsvacCode === "ResCancel"
    ) {
      content = "Reservation Canceled";
    } else if (
      assignmentDetails?.inactiveflag === -1 &&
      assignmentDetails?.rsvacCode === "ResCancel"
    ) {
      content = "Assignment & Reservation Canceled";
    } else if (
      assignmentDetails?.inactiveflag === -1 &&
      assignmentDetails?.rsvacCode !== "ResCancel"
    ) {
      content = "Assignment Canceled";
    }

    return content;
  };

  return (
    <>
      {assignmentCancelled === -1 && (
        <>
          <div className=" p-6 flex justify-start">
            <div>
              <p className=" text-xl font-semibold">
                {" "}
                Cancellation Information{" "}
              </p>
            </div>
          </div>
          <div
            className=" flex card bg-base-100 shadow-md p-6 mt-2"
            style={{ flexDirection: "column" }}
          >
            <Watermark
              content={watermarkContent()}
              zIndex={11}
              rotate={-30}
              height={
                assignmentDetails?.inactiveflag === -1 ||
                assignmentDetails?.rsvacCode === "ResCancel"
                  ? 20
                  : 0
              }
              gap={[100, 80]}
              font={{
                color: "rgba(255, 0, 0, 0.4)", // Set the desired color
                fontSize: 20, // Set the desired font size
                fontWeight: "normal", // Set the desired font weight
                fontStyle: "normal", // Set the desired font style
                fontFamily: "Arial, sans-serif", // Set the desired font family
                textAlign: "center", // Set the desired text alignment
              }}
            >
              <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                <div>
                  <label
                    htmlFor="cancelReason"
                    className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                  >
                    Cancel Reason
                  </label>
                  <Input
                    name="cancelReason"
                    id="cancelReason"
                    value={assignmentDetails?.rsvcXdescription || "N/A"}
                    disabled
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </div>
                <div>
                  <label
                    htmlFor="canceledBy"
                    className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                  >
                    Canceled By
                  </label>
                  <Input
                    name="canceledBy"
                    id="canceledBy"
                    value={assignmentDetails?.canceledBy || "N/A"}
                    disabled
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </div>
                <div>
                  <label
                    htmlFor="cancelDate"
                    className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                  >
                    Cancel Date
                  </label>
                  <Input
                    name="cancelDate"
                    id="cancelDate"
                    value={formatDate(assignmentDetails?.canceledDate)}
                    disabled
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </div>
                <div>
                  <label
                    htmlFor="cancelTime"
                    className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                  >
                    Cancel Time
                  </label>
                  <Input
                    name="cancelTime"
                    id="cancelTime"
                    value={
                      (assignmentDetails?.canceledTime &&
                        moment(assignmentDetails?.canceledTime).format("LT")) ||
                      "N/A"
                    }
                    disabled
                    className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered cursor-default"
                  />
                </div>
              </div>
            </Watermark>
          </div>
        </>
      )}
    </>
  );
};

// Define prop types
AssignmentCancellationReviews.propTypes = {
  assignmentCancelled: PropTypes.number,
  assignmentDetails: PropTypes.shape({
    inactiveflag: PropTypes.number,
    rsvacCode: PropTypes.string,
    rsvcXdescription: PropTypes.string,
    canceledBy: PropTypes.string,
    canceledDate: PropTypes.string, // Assuming it's a string representing a date
    canceledTime: PropTypes.string, // Assuming it's a string representing a time
  }),
};

export default AssignmentCancellationReviews;
