import React, { useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setContractorRatesFilters } from "../../../../Redux-Toolkit/ContractorRateDetailsSlice";

const ContractorRateDetailsFilter = () => {

  const dispatch = useDispatch();
  const contractorRatesFilter = useSelector((state) => state?.contractorRates?.contractorRateFilters) || [];

  const contractorRatesID = contractorRatesFilter?.ContractorRatesID || 0;
  const [acctgCode, setAcctgCode] = useState(contractorRatesFilter?.acctgCode || "");
  const [transportType, setTransportType] = useState(contractorRatesFilter?.transType || "");
  const [language, setLanguage] = useState(contractorRatesFilter?.language || "");
  const [lob, setLob] = useState(contractorRatesFilter?.lob || "");

  const filterData = {

    ContractorRatesID: Number(contractorRatesID),
    acctgCode: "",
    transType: "",
    language: "",
    lob: "",
  };

  const Reset = () => {

    setAcctgCode("");
    setTransportType("");
    setLanguage("");
    setLob("");

    dispatch(setContractorRatesFilters(filterData)); //to update the filter states in store to persist filter values

  }

  return (

      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          onSubmit={(e) => {
            const data = {
              ContractorRatesID: Number(contractorRatesID),
              acctgCode,
              transType: transportType,
              language,
              lob,
            };

            dispatch(setContractorRatesFilters(data)); //to update the filter states in store to persist filter values

            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="acctgCode"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Acctg Code
              </label>
              <Input
                name="acctgCode"
                placeholder="Acctg Code"
                onChange={(e) => setAcctgCode(e.target.value) }
                value={acctgCode}
                id="acctgCode"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="transportType"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Transport Type
              </label>
              <Input
                name="transportType"
                placeholder="Transport Type"
                onChange={(e) => setTransportType(e.target.value)}
                value={transportType}
                id="transportType"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="language"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Language
              </label>
              <Input
                name="language"
                placeholder="Language"
                onChange={(e) => setLanguage(e.target.value)}
                value={language}
                id="language"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="lob"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                LOB
              </label>
              <Input
                name="lob"
                placeholder="LOB"
                onChange={(e) => {setLob(e.target.value)}}
                value={lob}
                id="lob"
                type="phone"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>

          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <button
              type="reset"
              onClick={() => {
                Reset();
              }}
              className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]"
            >
              <MdOutlineRefresh className="mr-1" />
              Reset
            </button>
            <button className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4">
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
  );

};

export default ContractorRateDetailsFilter;
