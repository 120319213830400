import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { setContractorFilters } from "../../../../Redux-Toolkit/ContractorSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { handleIDInputChange, iDValue } from "../../../../utils/helpers";

const ContractorFilter = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initializeState = (filter) => ({

    contractorID: filter?.contractorID || 0,
    firstName: filter?.firstName || "",
    lastName: filter?.lastName || "",
    mobile: filter?.mobile || "",
    contractorStatus: filter?.inactiveflag || -2,
    statusCode: filter?.statusCode || "",
    serviceCode: filter?.serviceCode || "",
    zipCode: filter?.zipCode || 0,
    state: filter?.state || "",
    miles: filter?.miles || 0,
    vehicleSize: filter?.vehicleSize || "",
    languageCode: filter?.languageCode || "",
    page: filter?.page || 1,
  });

  const contractorFilter = useSelector((state) => state?.contractor?.contractorFilters) || [];
  const contractorStatesList = useSelector((state) => state?.contractor?.stateList) || [];
  const contractorStatusList = useSelector((state) => state?.contractor?.statusList) || [];
  const contractorServiceList = useSelector((state) => state?.contractor?.serviceList) || [];
  const ContractorVehicleSizeList = useSelector((state) => state?.contractor?.vehicleSizeList) || [];
  const ContractorLanguage = useSelector((state) => state?.contractor?.languageList) || [];

  const initialState = initializeState(contractorFilter);
  const [contractorID, setContractorID] = useState(initialState.contractorID);
  const [firstName, setFirstName] = useState(initialState.firstName);
  const [lastName, setLastName] = useState(initialState.lastName);
  const [mobile, setMobile] = useState(initialState.mobile);
  const [contractorStatus, setContractorStatus] = useState(initialState.inactiveflag);
  const [statusCode, setStatusCode] = useState(initialState.statusCode);
  const [serviceCode, setServiceCode] = useState(initialState.serviceCode);
  const [zipCode, setZipCode] = useState(initialState.zipCode);
  const [state, setState] = useState(initialState.state);
  const [miles, setMiles] = useState(initialState.miles);
  const [vehicleSize, setVehicleSize] = useState(initialState.vehicleSize);
  const [languageCode, setLanguageCode] = useState(initialState.languageCode);
  const [page, setPage] = useState(initialState.page);

  useEffect(() => {
    updateStateFromFilter(contractorFilter);
  }, [contractorFilter]);

  const updateStateFromFilter = (filter) => {
    setContractorID(filter?.contractorID);
    setFirstName(filter?.firstName);
    setLastName(filter?.lastName);
    setMobile(filter?.mobile);
    setContractorStatus(filter?.inactiveflag);
    setStatusCode(filter?.statusCode);
    setServiceCode(filter?.serviceCode);
    setZipCode(filter?.zipCode);
    setState(filter?.state);
    setMiles(filter?.miles);
    setVehicleSize(filter?.vehicleSize);
    setLanguageCode(filter?.languageCode);
    setPage(filter?.page);
  };

  const handleReset = () => {
    resetState();
    dispatch(setContractorFilters(createFilterData()));
    navigate("/contractor-management");
  };

  const resetState = () => {
    setContractorID(0);
    setFirstName("");
    setLastName("");
    setMobile("");
    setContractorStatus(-2);
    setStatusCode("");
    setServiceCode("");
    setVehicleSize("");
    setLanguageCode("");
    setZipCode(0);
    setMiles(0);
    setState("");
  };

  const createFilterData = () => ({
    contractorID: 0,
    firstName: "",
    lastName: "",
    mobile: "",
    statusCode: "",
    serviceCode: "",
    state: "",
    zipCode: 0,
    vehicleSize: "",
    languageCode: "",
    miles: 0,
    inactiveflag: -2,
    page: page,
    limit: 20,
  });

  const validateZipMiles = () => {
    if ((miles && !zipCode) || (zipCode && !miles)) {
      toast.error("Both miles and zipcode are required fields.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    return true;
  };

  return (

    <div className="card bg-base-100 shadow-md p-6 mt-3 mb-3">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (validateZipMiles()) {
          const data = {
            ...(contractorID && !isNaN(Number(contractorID)) && {
              contractorID: Number(contractorID),
            }),
            ...(firstName && firstName.trim() !== "" && { firstName }),
            ...(lastName && lastName.trim() !== "" && { lastName }),
            ...(mobile && mobile.trim() !== "" && { mobile }),
            ...(contractorStatus &&
              !isNaN(Number(contractorStatus)) && {
                inactiveflag: Number(contractorStatus),
              }),
            ...(state && state.trim() !== "" && { state }),
            ...(statusCode && statusCode.trim() !== "" && { statusCode }),
            ...(zipCode && !isNaN(Number(zipCode)) && {
              zipCode: Number(zipCode),
            }),
            ...(vehicleSize && vehicleSize.trim() !== "" && { vehicleSize }),
            ...(languageCode && languageCode.trim() !== "" && { languageCode }),
            ...(miles && !isNaN(Number(miles)) && {
              miles: Number(miles),
            }),
            ...(serviceCode && serviceCode.trim() !== "" && { serviceCode }),
            page: 1,
            limit: 30,
          };
          dispatch(setContractorFilters(data)); //to update the filter states in store to persist filter values
        }
        }}
      >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          <div>
            <label
              htmlFor="contractorID"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Contractor ID
            </label>
            <Input
              name="contractorID"
              placeholder="Contractor ID"
              onChange={(e) => handleIDInputChange(e, setContractorID)}
              value={iDValue(contractorID)}
              id="contractorID"
              type="text"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>
          <div>
            <label
              htmlFor="firstname"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              First Name
            </label>
            <Input
              name="firstName"
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              id="firstName"
              type="text"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>
          <div>
            <label
              htmlFor="lastName"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Last Name
            </label>
            <Input
              name="lastName"
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              id="lastName"
              type="text"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>
          <div>
            <label
              htmlFor="mobile"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Mobile
            </label>
            <Input
              name="mobile"
              placeholder="Mobile Number"
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 10 && /^\d*$/.test(value)) {
                  setMobile(value);
                }
              }}
              value={mobile}
              id="mobile"
              type="phone"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>

          <div>
            <label
              htmlFor="statusCode"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Availability
            </label>
            <select
              name="statusCode"
              value={statusCode}
              onChange={(e) => setStatusCode(e.target.value)}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="" className="">
                All
              </option>
              {contractorStatusList &&
                contractorStatusList?.length > 0 &&
                contractorStatusList?.map((statusCode, index) => (
                  <option value={statusCode.code} key={statusCode.code}>
                    {statusCode.description}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="state"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              State
            </label>
            <select
              name="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="" className="">
                All
              </option>
              {contractorStatesList &&
                contractorStatesList?.length > 0 &&
                contractorStatesList?.map((state, index) => (
                  <option value={state.code} key={state.code}>
                    {state.description}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="status"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Status
            </label>
            <select
              name="status"
              value={contractorStatus}
              onChange={(e) => setContractorStatus(e.target.value)}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value={-2}>All</option>
              <option value={0}>Active</option>
              <option value={-1}>Inactive</option>
            </select>
          </div>

          <div>
            <label
              htmlFor="service"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Services
            </label>
            <select
              id="service"
              value={serviceCode}
              onChange={(e) => setServiceCode(e.target.value)}
              name="service"
              className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="" className="">
                All
              </option>
              {contractorServiceList &&
                contractorServiceList?.length > 0 &&
                contractorServiceList?.map((service, index) => (
                  <option value={service.code} key={service.code}>
                    {service.description}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="zipcode"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Zip Code
            </label>
            <Input
              name="zipCode"
              placeholder="Zip code"
              onChange={(e) => handleIDInputChange(e, setZipCode)}
              value={iDValue(zipCode)}
              id="zipcode"
              type="zipcode"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>

          <div>
            <label
              htmlFor="mile"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Miles
            </label>
            <Input
              name="mile"
              placeholder="Miles"
              onChange={(e) => handleIDInputChange(e, setMiles)}
              value={iDValue(miles)}
              id="mile"
              type="mile"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>

          <div>
            <label
              htmlFor="vehicleSize"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Vehicle Size
            </label>
            <select
              id="vehicleSize"
              value={vehicleSize}
              onChange={(e) => setVehicleSize(e.target.value)}
              name="vehicleSize"
              className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="" className="">
                All
              </option>
              {ContractorVehicleSizeList &&
                ContractorVehicleSizeList?.length > 0 &&
                ContractorVehicleSizeList?.map((vehicle, index) => (
                  <option value={vehicle?.code} key={vehicle?.code}>
                    {vehicle?.description}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="language"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Language
            </label>
            <select
              id="language"
              value={languageCode}
              onChange={(e) => setLanguageCode(e.target.value)}
              name="language"
              className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="" className="">
                All
              </option>
              {ContractorLanguage &&
                ContractorLanguage?.length > 0 &&
                ContractorLanguage?.map((language, index) => (
                  <option value={language.code} key={language.code}>
                    {language.description}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
          <button
            type="reset"
            onClick={() => {
              handleReset();
            }}
            className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            <MdOutlineRefresh className="mr-1" />
            Reset
          </button>
          <button
            className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4"
            onClick={() => navigate("?page=1")}
          >
            <MdOutlineSearch className="mr-1" />
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContractorFilter;
