import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { postMethodData } from "../../../ApiMethods/Api";
import { MdRemoveRedEye } from "react-icons/md";
import decryptParamID from "../../../utils/decryptParamID";
import encryptParamID from "../../../utils/encryptParamID";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedClaimApprovedContractorRow } from "../../../Redux-Toolkit/ClaimsSlice";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ViewClaimApprovedContractor = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { claimantName } = useParams();
  const { claimID } = decryptParamID(useParams());
  const { claimnumber } = useParams();

  const [approvedContractorList, setApprovedContractorList] = useState([]);
  const [approvedContractorDataLoading, setApprovedContractorDataLoading] = useState(false);
  const currentUserList = approvedContractorList || [];
  const encryptId = (id) => encryptParamID(id);
  const rowRefs = useRef([]);

  const selectedRow = useSelector((state) => state?.claims?.selectedClaimApprovedContractorsTableRow);

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    approvedList("/ClaimsApprovedContractors", Number(claimID), (response) => {
      if (!response?.data?.status) {
        setApprovedContractorList([]);
      } else if (response?.data?.status) {
        setApprovedContractorList(response?.data?.data);
      }
    });
  }, []);

  /**
   * useEffect to scroll to the selectedRow when the claimsList changes as side effect
   */
  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && rowRefs.current[selectedRow]) {
      const row = rowRefs.current[selectedRow];
      if (row) {
        row.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [approvedContractorList]);

  const approvedList = async (url, claimID, callback) => {
    const body = {
      claimID: claimID,
    };
    try {
      setApprovedContractorDataLoading(true);
      const response = await postMethodData(url, body);
      callback(response);
      setApprovedContractorDataLoading(false);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setApprovedContractorDataLoading(false);
      setApprovedContractorList([]);
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Approved Contractor</p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 md:gap-8 gap-4">
          <div>
            <label
              htmlFor="claimID"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim ID
            </label>
            <input
              name="claimID"
              value={claimID === 0 ? "" : claimID}
              placeholder="Claim ID"
              id="claimID"
              type="text"
              autoComplete="off"
              disabled
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>
          <div>
            <label
              htmlFor="claimNumber"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim Number
            </label>
            <input
              name="claimNumber"
              value={claimID === 0 ? "" : claimnumber}
              placeholder="Claim Number"
              id="claimNumber"
              disabled
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>
          <div>
            <label
              htmlFor="claimantName"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claimant Name
            </label>
            <input
              name="claimantName"
              value={claimantName || ""}
              disabled
              placeholder="Claimant"
              id="claimantName"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>
        </div>
      </div>
      {
        <div className="card bg-base-100 shadow-md p-6 mt-8">
          <div className="overflow-x-auto w-full">
            <table className="table table-zebra w-full">
              <thead>
                <tr>
                  <th></th>
                  <th>Service Type</th>
                  <th>Priority</th>
                  <th>Contractor</th>
                  <th>Miles</th>
                  <th>Notes</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="text-sm relative">
                {approvedContractorDataLoading &&
                  Array.from({ length: 5 }).map((_, index) => (
                    <tr key={nanoid()}>
                      <td colSpan={13} className="animate-pulse py-6"></td>
                    </tr>
                  ))}

                {(!approvedContractorDataLoading &&
                  currentUserList?.length === 0
                  ? approvedContractorList
                  : currentUserList
                )?.map((data, index) => (
                  <tr
                    key={currentUserList?.claimID || nanoid()}
                    id={currentUserList?.claimID || nanoid()}
                    className={`table-row-align-top ${index === selectedRow ? "activeTableRow" : ""
                      }`}
                    ref={(ele) => (rowRefs.current[index] = ele)}
                    data-testid="row-id"
                    onClick={() => {
                      dispatch(setSelectedClaimApprovedContractorRow(index));
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{data?.service || "Not Confirmed"}</td>
                    <td>{data?.prioritySort || "Not Confirmed"}</td>

                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <span
                            className="tooltip text-left"
                            data-tip="View Details"
                          >
                            <div
                              className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                            >
                              <button
                                onClick={() => {
                                  if (data?.contractorName !== undefined) {
                                    !data?.isDelete &&
                                      navigate(
                                        `/contractor-management/contractor-details/${encryptId(data?.contractorID)}`
                                      );
                                  }
                                }}
                              >
                                {data?.contractorName || "Not Confirmed"}
                              </button>
                            </div>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>{data?.miles || "Not Confirmed"}</td>

                    <td>{data?.notes || "Not Confirmed"}</td>
                    <td className=" text-base">
                      <div className="flex items-center space-x-4">
                        <button title="">
                          <span className="flex items-center space-x-4">
                            <span
                              className="tooltip text-left"
                              data-tip="View Details"
                            >
                              <Link
                                className="cursor-pointer"
                                to={`/claims-management/claim-approved-contractor-details/${encryptId(data?.claimID)}`}
                              >
                                <MdRemoveRedEye className="cursor-pointer" />
                              </Link>
                            </span>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}

                {!approvedContractorDataLoading &&
                  approvedContractorList?.length === 0 && (
                    <tr>
                      <td colSpan={11} className="my-10 text-center">
                        <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                        <p className=" font-semibold text-lg">
                          No Approved Contractors Found
                        </p>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </div>
      }
      {approvedContractorDataLoading && (
        <div
          className="fixed inset-0  top-[1px] rounded-sm"
          style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
        >
          <LoadingPage />
        </div>
      )}
    </>
  );
};

export default ViewClaimApprovedContractor;
