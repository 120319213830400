import PropTypes from "prop-types";
import React from "react";
import Chart from "react-apexcharts";

const HorizontalBarGraph = ({ data, name }) => {
  
  const graphName = {
    "Reservations By Month": "RESERVATIONS",
    "Claimant By Month": "CLAIMANTS",
    "Contractor By Month": "CONTRACTORS",
  };

  // Check if data is valid
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <div>No data available</div>; // Fallback UI if data is invalid
  }

  // Extract categories and series data
  const categories = data.map((item) => item.month || item.group);
  const seriesData = data.map((item) => item.value);

  const options = {
    chart: {
      type: "bar",
      height: 400,
    },
    plotOptions: {
      bar: {
        horizontal: true, // Make bars horizontal
        barHeight: "100%",
        distributed: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: [
      "#33b2df",
      "#bfd0d7",
      "#faa9bb",
      "#13d8aa",
      "#cdc0b5",
      "#7ed4d3",
      "#f9a3a4",
      "#90ee7e",
      "#ffb57a",
      "#69d2e7",
    ],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#000"], // Set label text color to black
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      offsetX: 0,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    // title: {
    //   text: 'Custom DataLabels',
    //   align: 'center',
    //   floating: true,
    // },
    // subtitle: {
    //   text: 'Category Names as DataLabels inside bars',
    //   align: 'center',
    // },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    legend: {
      show: false,
    },
  };

  const series = [
    {
      name: graphName[name] || "Value",
      data: seriesData,
    },
  ];

  return (
    <div style={{ width: "100%", height: 400 }}>
      <Chart options={options} series={series} type="bar" height={400} />
    </div>
  );
};

// Define PropTypes
HorizontalBarGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string, // Assuming 'name' is a string, adjust as necessary
    })
  ),
  name: PropTypes.string,
};

export default HorizontalBarGraph;
