import React, { useState, useEffect } from "react";
import { Input } from "react-daisyui";
import { Link, useParams } from "react-router-dom";
import { postMethodData } from "../../../ApiMethods/Api";
import decryptParamID from "../../../utils/decryptParamID";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ViewClaimFacilityDetails = () => {

  const { claimID } = decryptParamID(useParams()); // get id from URL parameters
  const [claimFacilityDetails, setClaimFacilityDetails] = useState([]);
  const [facilityDetailsDataLoading, setFacilityDetailsDataLoading] = useState(false);

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    claimFacilitySearchList("/ClaimsFacilities", Number(claimID));
  }, []);

  const claimFacilitySearchList = async (url, claimID) => {
    const body = {
      claimID: claimID,
    };
    try {
      const response = await postMethodData(url, body);
      if (response?.data?.data?.length === 0) {
        setClaimFacilityDetails([]);
        setFacilityDetailsDataLoading(false);
      } else {
        setClaimFacilityDetails(response?.data?.data[0]);
        setFacilityDetailsDataLoading(true);
      }
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setClaimFacilityDetails([]);
    }
  };

  return (
    <>
      <div className=" p-6 flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            Facility details for Claim ID: {claimID ?? "N/A"}
          </p>
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <form
          data-testid="claimFacilityDetails-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="facilityid"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Facility ID
              </label>
              <Input
                name="facilityid"
                value={claimFacilityDetails?.facilityID || "N/A"}
                id="facilityid"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="facilityType"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Facility Type
              </label>
              <Input
                name="facilityType"
                value={claimFacilityDetails?.facilityType || "N/A"}
                id="facilityType"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="facilityname"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Facility Name
              </label>
              <Input
                name="facilityName"
                value={claimFacilityDetails?.facilityname || "N/A"}
                id="facilityName"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="contact"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Contact
              </label>
              <Input
                name="contact"
                value={claimFacilityDetails?.contactname || "N/A"}
                id="contact"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="complex"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Complex
              </label>
              <Input
                name="complex"
                value={claimFacilityDetails?.complex || "N/A"}
                id="complex"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="address"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Address
              </label>
              <Input
                name="address"
                value={claimFacilityDetails?.address1 || "N/A"}
                id="address"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="city"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City
              </label>
              <Input
                name="city"
                value={claimFacilityDetails?.city || "N/A"}
                id="city"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="zipcode"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Zip Code
              </label>
              <Input
                name="zipcode"
                value={claimFacilityDetails?.zipcode || "N/A"}
                id="zipcode"
                type="text"
                disabled
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Phone
              </label>
              <Input
                name="phone"
                value={claimFacilityDetails?.phone || "N/A"}
                id="phone"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>

            <div>
              <label
                htmlFor="language"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Language
              </label>
              <Input
                name="language"
                value={claimFacilityDetails?.languageName || "N/A"}
                id="language"
                type="text"
                disabled
                autoComplete="off"
                className="textarea textarea-bordered focus:outline-0 w-full text-sm font-medium rounded-md cursor-default"
              />
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between"></div>
        </form>
      </div>
      {!facilityDetailsDataLoading && (
        <div
          className="fixed inset-0  top-[1px] rounded-sm"
          style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
        >
          <LoadingPage />
        </div>
      )}
    </>
  );
};

export default ViewClaimFacilityDetails;
