import React from "react";
import Logo from "../../assets/images/JNJ Services-logo-large art-vector.png";
import backgroundImg from "../../assets/images/1920-darkflakes.svg";
import { useNavigate } from "react-router-dom";

const EmailSentPageUI = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (

      <div
        className="flex justify-center items-center w-full h-screen md:py-0 py-5 bg-no-repeat bg-fixed object-cover object-top"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: "cover",
        }}
      >
        <div className=" grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 bg-[#f7f7f7] shadow-lg rounded-xl 2xl:w-6/12 lg:w-7/12 md:w-9/12 w-11/12 overflow-hidden">
          <div className=" bg-[#90d8f5] p-5 lg:col-span-2 flex justify-center items-center">
            <div>
              <div className="">
                <img className="w-[100%] mx-auto" alt="JNJ logo" src={Logo} />
              </div>
              <div className="text-[#000] text-center mt-5">
              </div>
            </div>
          </div>
          <div className="p-5 lg:col-span-3">
            <p className=" text-lg font-semibold text-[#519dbb] mb-3">
                {" "}
               We sent password reset instructions to your email
            </p>
            <p className=" text-m font-semibold text-[#519dbb]">
                {" "}
               Please follow the instruction sent in your email to reset your password.
            </p>
            <div className="mt-5">
              <form
                 data-testid="email-form" 
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className="pt-4">
                  <div className=" flex justify-center ">
                      <button
                        type="submit"
                        name="back to sign in"
                        className="mt-5 btn w-[75%] bg-[#63b0e0] hover:bg-[#000] text-[#000] hover:text-[#63b0e0] font-semibold border-none"
                        onClick={() => navigate("/login")}
                      >
                        back to sign in 
                      </button>
                   
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  );
};

export default EmailSentPageUI;
