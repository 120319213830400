import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { RxEnvelopeOpen } from "react-icons/rx";
import Logo from "../../assets/images/JNJ Services-logo-large art-vector.png";
import backgroundImg from "../../assets/images/1920-darkflakes.svg";
import { postLogin } from "../../ApiMethods/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  setUserAuthApproved,
  setUserRole,
} from "../../Redux-Toolkit/AuthSlice";

const VerifyEmail = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [passwordLoading, setPasswordLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const passwordChange = async () => {
    const body = {
      email: userEmail,
    };
    try {
      setPasswordLoading(true);
      const response = await postLogin("/ForgotpasswordByEmail", body);
      setPasswordLoading(false);
      if (response?.data?.status === false) {
        toast.error(response?.data?.statusMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (response?.data?.status === true) {
        localStorage.clear();
        dispatch(setUserAuthApproved(false));
        dispatch(setUserRole(""));

        toast.success(response?.data?.emailRes, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });

        navigate("/password-sent-to-email-page");
      }
    } catch (error) {
      setPasswordLoading(false);
      console.error("error occured", error);
      toast.error("An error occurred. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setPasswordLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    passwordChange();
  };

  return (
    <div
      className="flex justify-center items-center w-full h-screen md:py-0 py-5 bg-no-repeat bg-fixed object-cover object-top"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
      }}
    >
      <div className=" grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 bg-[#f7f7f7] shadow-lg rounded-xl 2xl:w-6/12 lg:w-7/12 md:w-9/12 w-11/12 overflow-hidden">
        <div className=" bg-[#90d8f5] p-5 lg:col-span-2 flex justify-center items-center">
          <div>
            <div className="">
              <img className="w-[100%] mx-auto" alt="JNJ logo" src={Logo} />
            </div>
            <div className="text-[#000] text-center mt-5"></div>
          </div>
        </div>
        <div className="p-5 lg:col-span-3">
          <p className=" text-lg font-semibold text-[orange]">
            {" "}
            Forgot password
          </p>
          <div className="mt-5">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="pt-4">
                <div className="form-control">
                  <label className="label" htmlFor="email">
                    <span className="label-text font-semibold">Email</span>
                  </label>
                  <div className="input-group">
                    <span className=" bg-[#000]">
                      <RxEnvelopeOpen className=" text-[#63b0e0]" />
                    </span>
                    <input
                      id="email"
                      autoComplete={false}
                      onChange={(e) => setUserEmail(e.target.value)}
                      required
                      name="email"
                      type="email"
                      placeholder="Email"
                      className="input w-full border-[#000] focus:outline-none border-r-1"
                    />
                  </div>
                </div>
                <div className=" flex items-center flex-col">
                  {!passwordLoading ? (
                    <button
                      type="submit"
                      className="mt-5 btn w-[50%] bg-[#63b0e0] hover:bg-[#000] text-[#000] hover:text-[#63b0e0] font-semibold border-none"
                    >
                      Send Mail
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-[#63b0e0] hover:text-[#63b0e0] font-semibold border-none flex justify-center items-center"
                    >
                      <svg
                        className="animate-spin mr-1 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      <p className="ml-2">Verifying</p>
                    </button>
                  )}
                  <div className="label space-x-1 mt-2 ">
                    <button
                      className="text-[#63b0e0] hover:underline cursor-pointer"
                      onClick={() => navigate("/login")}
                    >
                      Go back to Login?
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
