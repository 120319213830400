import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMethodData, postMethodData } from "../../../ApiMethods/Api";
import {
  setStatesList,
  setCategoryList,
  setCustomers,
  setSelectedRow,
} from "../../../Redux-Toolkit/CustomerSlice";
import { RxPerson } from "react-icons/rx";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Filter from "./CustomersFilter/CustomersFilter";
import { updateClaimantFilters } from "../../../Redux-Toolkit/ClaimantsSlice";
import { updateClaimFilters } from "../../../Redux-Toolkit/ClaimsSlice";
import { updateReservationFilters } from "../../../Redux-Toolkit/ReservationSlice";
import encryptParamID from "../../../utils/encryptParamID";
import claimants from "../../../assets/JNJ-icons/claimants.svg";
import claims from "../../../assets/JNJ-icons/claimss.svg";
import reservations from "../../../assets/JNJ-icons/reserve.svg";
import { MdRemoveRedEye } from "react-icons/md";
import ReactPagination from "../../../utils/ReactPagination";
import { nanoid } from "nanoid";
import { inactiveFlagClass } from "../../../utils/helpers";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const CustomerManagement = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef();

  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const userRole = useSelector((state) => state?.auth?.role);
  const selectedRow = useSelector((state) => state?.customer?.selectedTableRow);

  const [customerDataLoading, setCustomerDataLoading] = useState(false);
  const rowRefs = useRef([]);
  const customerFilters = useSelector((state) => state?.customer?.customerFilters);
  const customersSearchListData = useSelector((state) => state?.customer?.customers) || [];
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") ?? 1);
  const ITEMS_PER_PAGE = 20;

  const encryptId = (id) => encryptParamID(id);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  /**
   * useEffect to set the current page to the searchParamPage
   */
  useEffect(() => {
    setCurrentPage(searchParamPage ?? 1);
  }, [searchParamPage]);

  /**
   * useEffect to scroll to the selected row
   */
  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && tableRef.current) {
      const td = document.getElementById(selectedRow);
      if (td) {
        td.scrollIntoView({ block: "center" });
      }
    }
  }, [customersSearchListData]);

  useEffect(() => {
    customerStatesList("/State");
    customerCategoryList("/CustomerCategory");
    customerSearchList(
      "/CustomerSearch",
      { ...customerFilters, page: Number(currentPage), limit: ITEMS_PER_PAGE },
      (response) => {
        setCustomerDataLoading(false);

        if (!response?.data?.status) {
          dispatch(setCustomers([]));
          setPageCount(0);
        } else if (response?.data?.status) {
          dispatch(setCustomers(response?.data?.data));
          setPageCount(Math.ceil(response?.data?.totalData / ITEMS_PER_PAGE));
        }
      }
    );
  }, [customerFilters, currentPage]);

  /**
   * function to get the customer states list
   * @param {url} url
   * @returns customer states list for usa
   */
  const customerStatesList = async (url) => {
    try {
      const response = await getMethodData(url);
      dispatch(setStatesList(response?.data?.data));
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer category list:",
        error
      );
    }
  };

  /**
   * fucntion to get the customer category list
   * @param {url} url
   * @returns customer categories list
   */
  const customerCategoryList = async (url) => {
    try {
      const response = await getMethodData(url);
      dispatch(setCategoryList(response?.data?.data));
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer category list:",
        error
      );
    }
  };

  /**
   * function to get the customer search list
   * @param {url} url
   * @returns customers search list
   */
  const customerSearchList = async (url, data, callback) => {
    const body = data;
    try {
      setCustomerDataLoading(true);
      const response = await postMethodData(url, body);
      callback(response);
    } catch (error) {
      setCustomerDataLoading(false);
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      dispatch(setCustomers([]));
    }
  };

  /**
   * page change options for pagination to be usede into the ReactPagination component
   */
  const pageChangeOptions = {
    endPointFunction: customerSearchList,
    endpoint: "/CustomerSearch",
    componentFilter: customerFilters,
    dispatchAction: setCustomers,
    itemsPerPage: ITEMS_PER_PAGE,
    scrollElementId: "tablescroll", // Optional, only if you want to scrol,
  };

  return (

    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Customer Management</p>
        </div>
      </div>
      <div className="relative">
        <Filter />
        <div className="card bg-base-100 shadow-md p-6 mt-8">
          <div className="overflow-x-auto w-full">
            <table
              ref={tableRef}
              id="tablescroll"
              className="table table-zebra w-full"
            >
              <thead>
                <tr>
                  <th></th>
                  <th className="whitespace-nowrap !important">Customer ID</th>
                  <th className="whitespace-normal">Company Name</th>
                  <th className="whitespace-normal">Customer Name</th>
                  <th className="whitespace-normal">Category</th>
                  <th className="whitespace-normal">State</th>
                  <th className="whitespace-normal">Status</th>
                  <th className="whitespace-normal">Action</th>
                </tr>
              </thead>
              <tbody className="text-sm relative">
                {customerDataLoading &&
                  Array.from({ length: 5 }).map((_) => (
                    <tr key={nanoid()}>
                      <td colSpan={13} className="animate-pulse py-6"></td>
                    </tr>
                  ))}
                {!customerDataLoading &&
                  customersSearchListData?.length !== 0 &&
                  customersSearchListData?.map((data, index) => (
                    <tr
                      key={nanoid()}
                      ref={(ele) => (rowRefs.current[index] = ele)}
                      id={data?.customerID}
                      className={
                        data?.customerID === selectedRow ? "activeTableRow" : ""
                      }
                      onClick={() => {
                        dispatch(setSelectedRow(data?.customerID));
                      }}
                    >
                      <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>

                      <td>{data?.customerID}</td>
                      <td>{data?.companyName}</td>

                      <td>
                        <div className="flex items-center space-x-3">
                          <div>
                            <span
                              className="tooltip text-left"
                              data-tip="View Details"
                            >
                              <button
                                className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                                onClick={() => {
                                  navigate(
                                    `/customer-management/customer-details/${encryptId(
                                      data?.customerID
                                    )}`
                                  );
                                }}
                              >
                                {(data?.firstname ?? "N/A") +
                                  " " +
                                  (data?.lastname ?? "")}
                              </button>
                            </span>
                            <div className="text-sm ">
                              {data?.email || "N/A"}
                            </div>
                            <div className="text-sm">
                              {data?.phone
                                ?.replace(/\D/g, "")
                                ?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2 - $3"
                                ) ?? "N/A"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="capitalize">
                        {data?.categoryName || "N/A"}
                      </td>
                      <td>{data?.stateName || "N/A"}</td>
                      <td>
                        <label
                          htmlFor="my-modal-5"
                          className={`btn capitalize ${inactiveFlagClass(data)}
                         text-[#000] border-hidden  btn-xs cursor-default`}
                        >
                          {data?.inactiveflag ? "Inactive" : "Active"}
                        </label>
                      </td>
                      <td className=" text-base">
                        <div className="flex items-center space-x-4">
                          <button title="">
                            <span className="flex items-center space-x-4">
                              <span
                                className="tooltip text-left"
                                data-tip="View Details"
                              >
                                <Link
                                  className="cursor-pointer"
                                  to={`/customer-management/customer-details/${encryptId(
                                    data?.customerID
                                  )}`}
                                >
                                  <MdRemoveRedEye className="cursor-pointer" />
                                </Link>
                              </span>

                              {["Administrators", "Schedulers"].includes(
                                userRole
                              ) && (
                                <span
                                  className="tooltip text-left"
                                  data-tip="View Claimants"
                                >
                                  <Link
                                    className="cursor-pointer"
                                    to={`/claimant-management/customer/${encryptId(
                                      data?.customerID
                                    )}`}
                                    onClick={() =>
                                      dispatch(
                                        updateClaimantFilters({
                                          customerID: data?.customerID,
                                        })
                                      )
                                    }
                                  >
                                    <img
                                      className="cursor-pointer max-w-none"
                                      alt="Claimants"
                                      src={claimants}
                                    />
                                  </Link>
                                </span>
                              )}
                              {["Administrators", "Schedulers"].includes(
                                userRole
                              ) && (
                                <span
                                  className="tooltip text-left"
                                  data-tip="View Claims"
                                >
                                  <Link
                                    className="cursor-pointer"
                                    to={`/claims-management/customer/${encryptId(
                                      data?.customerID
                                    )}`}
                                    onClick={() =>
                                      dispatch(
                                        updateClaimFilters({
                                          customerID: data?.customerID,
                                        })
                                      )
                                    }
                                  >
                                    <img
                                      className="cursor-pointer max-w-none"
                                      alt="Claims"
                                      src={claims}
                                    />
                                  </Link>
                                </span>
                              )}
                              {["Administrators", "Schedulers"].includes(
                                userRole
                              ) && (
                                <span
                                  className="tooltip tooltip-left text-left"
                                  data-tip="View Reservations"
                                >
                                  <Link
                                    className="cursor-pointer"
                                    to={`/reservations-management/customer/${encryptId(
                                      data?.customerID
                                    )}`}
                                    onClick={() =>
                                      dispatch(
                                        updateReservationFilters({
                                          customerID: data?.customerID,
                                        })
                                      )
                                    }
                                  >
                                    <img
                                      className="cursor-pointer max-w-none"
                                      alt="Reservation"
                                      src={reservations}
                                    />
                                  </Link>
                                </span>
                              )}
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}

                {!customerDataLoading &&
                  customersSearchListData?.length == 0 && (
                    <tr>
                      <td colSpan={11} className="my-10 text-center">
                        <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                        <p className=" font-semibold text-lg">
                          No Customer Found
                        </p>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            {customersSearchListData?.length !== 0 && (
              <ReactPagination
                forcePage={currentPage - 1}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                // onPageChange={handlePageChange}
                pageChangeOptions={pageChangeOptions}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
        {customerDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerManagement;
