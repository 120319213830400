import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./Auth/Login/Login";
import Header from "./Common/Header/Header";
import Footer from "./Common/Footer";
import Routess from "./routes";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import ResetPassword from "./Auth/Login/ResetPassword";
import VerifyEmail from "./Auth/Login/VerifyEmail";
import EmailSentPageUI from "./Auth/Login/EmailSentPageUI";
import { increaseNotificationCount, resetNotificationsState } from "./Redux-Toolkit/NotificationSlice";
import ForgotPassword from "./Auth/Login/ForgotPassword";
import FcmNotificationEnabled from "./Common/FcmNotificationEnabled/FcmNotificationEnabled";
import { clearAuthToken, setUserAuthApproved, setUserRole, setuserToken } from "./Redux-Toolkit/AuthSlice";
import { clearAllIntervals } from "./utils/setIntervalsManager";

const toastConfig = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};  

function App() {
  const navigate = useNavigate();

  const userToken = useSelector((state) => state?.auth?.Token);
  let newUserToken = useSelector((state) => state?.newUserPasswordChange?.Token);

  const dispatch = useDispatch();

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("message", (event) => {
      if (event.data && event.data.type === "NEW_NOTIFICATION") {
        dispatch(increaseNotificationCount());
      }
    });
  }

  const logout = () => {
    dispatch(clearAuthToken());
    dispatch(setUserAuthApproved(false));
    dispatch(setuserToken(""));
    dispatch(setUserRole(""));
    dispatch(resetNotificationsState());

    localStorage.removeItem("isFirstTimeUser");
    localStorage.removeItem("persist:auth");

    /**
     * Clear all intervals
     */
    clearAllIntervals();
    navigate("/login");

    toast.error("Please login to continue", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  const userAuthApproved =
    useSelector((state) => state?.auth?.userAuthApproved) || false;

  axios.interceptors.request.use(async function (config) {
    const token =
      newUserToken && newUserToken !== null ? newUserToken : userToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  let lastError = null;
  let lastErrorTime = 0;

  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      // Default error message
      let errorMessage = "An unknown error occurred";
      const currentTime = new Date().getTime();

      // Ensure error object is defined
      if (error?.response) {
        /**
         * The request was made and the server responded with a status code outside of the range of 2xx
         */
        errorMessage =
          error?.response?.data?.message ||
          error?.response?.statusText ||
          errorMessage;

        // Check for 401 Unauthorized error
        if (error.response.status === 401) {
          logout();
          window.location.reload();
        }
      } else if (error?.request) {
        /**
         * The request was made but no response was received
         */
        errorMessage = "No response received from server";
      } else if (error?.message) {
        /**
         * Something happened in setting up the request that triggered an error
         */
        errorMessage = error?.message;
      }

      if (errorMessage !== lastError || currentTime - lastErrorTime > 1000) {
        // 1 second gap
        toast.error(error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        lastError = errorMessage;
        lastErrorTime = currentTime;
      }
      // Construct an Error object for rejection
      return Promise.reject(new Error(errorMessage));
    }
  );

  // Function to handle visibility changes
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      retrieveNotificationsAndDispatch();
    }
  };

  // Function to retrieve and handle notifications
  const retrieveNotificationsAndDispatch = async () => {
    try {
      const notifications = await retrieveNotificationData();
      notifications.forEach(() => {
        dispatch(increaseNotificationCount());
      });
      clearIndexedDBNotificationData();
    } catch (error) {
      console.error("Error retrieving notifications:", error);
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  /**
   * retrieve the background notifications data from indexedDB
   */

  function retrieveNotificationData() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("notificationsDB", 1);

      request.onupgradeneeded = handleUpgradeNeeded;
      request.onsuccess = (event) => handleSuccess(event, resolve, reject);
      request.onerror = (event) => handleOpenError(event, reject);
    });
  }

  // Function to handle the upgrade of the database
  function handleUpgradeNeeded(event) {
    const db = event.target.result;

    if (!db.objectStoreNames.contains("notifications")) {
      const objectStore = db.createObjectStore("notifications", {
        keyPath: "id",
        autoIncrement: true,
      });
      objectStore.createIndex("payload", "payload", { unique: false });
    }
  }

  // Function to handle successful database open and fetching of data
  function handleSuccess(event, resolve, reject) {
    const db = event.target.result;
    const transaction = db.transaction(["notifications"], "readonly");
    const objectStore = transaction.objectStore("notifications");
    const request = objectStore.getAll();

    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => handleRetrieveError(event, reject);
  }

  // Function to handle errors when retrieving data
  function handleRetrieveError(event, reject) {
    reject(`Error retrieving notification data: ${event.target.errorCode}`);
  }

  // Function to handle errors when opening the database
  function handleOpenError(event, reject) {
    reject(`Error opening IndexedDB: ${event.target.errorCode}`);
  }

  function clearIndexedDBNotificationData() {
    const request = indexedDB.open("notificationsDB", 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      if (!db.objectStoreNames.contains("notifications")) {
        const objectStore = db.createObjectStore("notifications", {
          keyPath: "id",
          autoIncrement: true,
        });
        objectStore.createIndex("payload", "payload", { unique: false });
      }
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(["notifications"], "readwrite");
      const objectStore = transaction.objectStore("notifications");
      const clearRequest = objectStore.clear();

      clearRequest.onsuccess = () => {};

      clearRequest.onerror = (event) => {
        console.error("Error clearing IndexedDB:", event.target.errorCode);
      };
    };

    request.onerror = (event) => {
      console.error("Error opening IndexedDB:", event.target.errorCode);
    };
  }

  return (
    <>
      {userAuthApproved ? (
        <div className="flex">
          <main className="w-full bg-[#f7f7f7] block">
            <Header />
            <FcmNotificationEnabled />
            <section className=" h-auto overflow-hidden w-full pt-8 pl-8 pr-8 pb-2 min-h-[calc(100vh-155px)] ">
              <Routess />
            </section>
            <Footer />
          </main>
        </div>
      ) : (
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route element={<VerifyEmail />} path="/forgot-password" />
          <Route
            element={<EmailSentPageUI />}
            path="/password-sent-to-email-page"
          />
          <Route element={<ForgotPassword />} path="/change-password" />
          <Route element={<ResetPassword />} path="/reset-password" />
          <Route element={<Login />} path="*" />
        </Routes>
      )}

      <ToastContainer {...toastConfig} />
    </>
  );
}

export default App;
