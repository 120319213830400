import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  notificationPermission: false,
  showFcmNoticationStatusBar: true,
  notificationList: [],
  deleteNotificationList: "",
  notificationCount: 0,
  notificationArchivedList: [],
};

export const NotificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setShowFcmNoticationStatusBar: (state, action) => {
      state.showFcmNoticationStatusBar = action.payload;
    },
    setNotificationPermission: (state, action) => {
      state.notificationPermission = action.payload;
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    setDeleteNotification: (state, action) => {
      state.deleteNotificationList = action.payload;
    },
    increaseNotificationCount: (state) => {
      state.notificationCount += 1;
    },
    setNotificationList: (state, action) => {
      // Check if action.payload is an array, if not, wrap it in an array
      const payloadArray = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      state.notificationList = [...state.notificationList, ...payloadArray];
    },
    clearNotificationCount: (state) => {
      state.notificationCount = 0;
    },
    clearNotificationList: (state) => {
      state.notificationList = [];
    },
    resetNotificationsState: () => initialState,
  },
});

export const {
  setNotificationPermission,
  setShowFcmNoticationStatusBar,
  setNotificationCount,
  increaseNotificationCount,
  setNotificationList,
  setDeleteNotification,
  clearNotificationCount,
  clearNotificationList,
  resetNotificationsState,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;
