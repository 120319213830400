import React, { useEffect, useState, useRef } from "react";
import { MdRemoveRedEye } from "react-icons/md";
import { FaKey } from "react-icons/fa6";
import { RxPerson } from "react-icons/rx";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Filter from "./ClaimantFilter/ClaimantFilter";
import { useSelector, useDispatch } from "react-redux";
import { postMethodData, getMethodData } from "../../../ApiMethods/Api";
import { setClaimants, setSelectedRow, setLanguage } from "../../../Redux-Toolkit/ClaimantsSlice";
import { updateClaimFilters } from "../../../Redux-Toolkit/ClaimsSlice";
import { updateReservationFilters } from "../../../Redux-Toolkit/ReservationSlice";
import encryptParamID from "../../../utils/encryptParamID";
import { SafeAllowShow } from "../../../utils/safe";
import decryptParamID from "../../../utils/decryptParamID";
import claims from "../../../assets/JNJ-icons/claimss.svg";
import reservations from "../../../assets/JNJ-icons/reserve.svg";
import { formatDate, inactiveFlagClass } from "../../../utils/helpers";
import ReactPagination from "../../../utils/ReactPagination";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";
import ContractorClaimantStaffPasswordReset from "../../../Common/ContractorClaimantStaffPasswordReset";

const ClaimantManagement = () => {

  const params = decryptParamID(useParams());
  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const urlHasParams = Object.keys(params).length > 0;
  const userRole = useSelector((state) => state?.auth?.role);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tableRef = useRef();
  const [claimantDataLoading, setClaimantDataLoading] = useState(false);
  const [entityName, setEntityName] = useState("");

  const claimantsList =
    useSelector((state) => state?.claimants?.claimants) || [];
  const claimantFilters =
    useSelector((state) => state?.claimants?.claimantFilters) || {};
  const selectedRow = useSelector(
    (state) => state?.claimants?.selectedTableRow
  );

  const rowRefs = useRef([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") ?? 1);
  const ITEMS_PER_PAGE = 20;

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  const encryptId = (id) => encryptParamID(id);

  useEffect(() => {
    setCurrentPage(searchParamPage ?? 1);
  }, [searchParamPage]);

  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && tableRef.current) {
      const td = document.getElementById(selectedRow);
      if (td) {
        td.scrollIntoView({ block: "center" });
      }
    }
  }, [claimantsList]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchEntityNames = async () => {
      for (let key in params) {
        if (keyMapping[key]) {
          let newKey = key.replace("id", "ID");
          const body = {
            [newKey]: Number(params[key]),
          };

          getDataList("/CustomerSelDetail", body, (response) => {
            if (response?.data?.data?.length === 0) {
              setEntityName("N/A");
            } else {
              setEntityName(response?.data?.data[0]?.companyName);
            }
          });
        }
      }
    };

    fetchEntityNames();
  }, []);

  const keyMapping = {
    customerid: "Customer ID",
    claimantid: "Claimant ID",
    contractorid: "Contractor ID",
  };

  useEffect(() => {
    getFilterListDataMethod("/LanguageList", (response) => {
      if (response?.data?.data?.length) {
        dispatch(setLanguage(response?.data?.data));
      } else {
        dispatch(setLanguage([]));
      }
    });    

    getDataList(
      "/ClaimantSearch",
      { ...claimantFilters, page: Number(currentPage), limit: ITEMS_PER_PAGE },
      (response) => {        
        if (!response?.data?.status) {
          dispatch(setClaimants([]));
          setPageCount(0);
        } else if (response?.data?.status) {
          dispatch(setClaimants(response?.data?.data));
          setPageCount(Math.ceil(response?.data?.totalData / ITEMS_PER_PAGE));
        }
      }
    );
  }, [claimantFilters, currentPage]);

  /**
   *
   * @param {url} url
   * @returns customers search list
   */

  const getFilterListDataMethod = async (url, callback) => {
    try {
      const response = await getMethodData(url);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer category list:",
        error
      );
    }
  };

  const getDataList = async (url, data, callback) => {
    const body = data;
    try {
      setClaimantDataLoading(true);
      const response = await postMethodData(url, body);
      setClaimantDataLoading(false);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the data list:",
        error
      );
      setClaimantDataLoading(false);
      dispatch(setClaimants([]));
    }
  };

  /**
   * Pagination options to be used into ReactPagination component
   */

  const pageChangeOptions = {
    endPointFunction: getDataList,
    endpoint: "/ClaimantSearch",
    componentFilter: claimantFilters,
    dispatchAction: setClaimants,
    itemsPerPage: ITEMS_PER_PAGE,
    scrollElementId: "tablescroll", // Optional, only if you want to scrol,
  };

  const [isPasswordResetVisible, setIsPasswordResetVisible] = useState({
    id: null,
    isVisible: false,
  });

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            {!urlHasParams
              ? "Claimant Management"
              : `Claimant List for ${Object.keys(params)
                .map(
                  (key) =>
                    `${keyMapping[key] || key}: ${params[key]
                    } | ${entityName}`
                )
                .join(", ")}`}
          </p>
        </div>

        {urlHasParams && (
          <div>
            <Link
              onClick={goBack}
              className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
            >
              Back
            </Link>
          </div>
        )}
      </div>
      <div className="relative">
        {!urlHasParams && <Filter />}
        <div className="card bg-base-100 shadow-md p-6 mt-8">
          <div className="overflow-x-auto w-full">
            <table
              ref={tableRef}
              id="tablescroll"
              className="table table-zebra w-full"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Claimant ID</th>
                  <th style={{ whiteSpace: "normal" }}>Name</th>
                  <th style={{ whiteSpace: "normal" }}>Registered On</th>
                  <th style={{ whiteSpace: "normal" }}>Status</th>
                  <th style={{ whiteSpace: "normal" }}>Action</th>
                </tr>
              </thead>
              <tbody className="text-sm relative">
                {claimantDataLoading &&
                  Array.from({ length: 5 }).map((_) => (
                    <tr key={nanoid()}>
                      <td colSpan={13} className="animate-pulse py-6"></td>
                    </tr>
                  ))}
                {claimantsList?.length !== 0 &&
                  claimantsList?.map((data, index) => (
                    <tr
                      key={data?.claimantID}
                      ref={(ele) => (rowRefs.current[index] = ele)}
                      id={data.claimantID}
                      className={
                        data?.claimantID === selectedRow ? "activeTableRow" : ""
                      }
                      onClick={() => {
                        dispatch(setSelectedRow(data?.claimantID));
                      }}
                    >
                      <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>

                      <td>{data?.claimantID}</td>
                      <td>
                        <div className="flex items-center space-x-3">
                          <div>
                            <span
                              className="tooltip text-left"
                              data-tip="View Details"
                            >
                              <div
                                className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                              >
                                <button
                                  onClick={() => {
                                    if (data?.firstName !== undefined) {
                                      !data?.isDelete &&
                                        navigate(
                                          `/claimant-management/claimant-details/${encryptId(
                                            data?.claimantID
                                          )}`
                                        );
                                    }
                                  }}
                                >
                                  {(data?.firstName ?? "N/A") +
                                    " " +
                                    (data?.lastName ?? "")}
                                </button>
                              </div>
                            </span>
                            <div className="text-sm ">
                              {data?.email || "N/A"}
                            </div>
                            <div className="text-sm">
                              {(
                                data?.homePhone ||
                                data?.cellPhone ||
                                data?.homePhonewH ||
                                data?.wkPhone ||
                                data?.wkPhoneExt ||
                                data?.altPhone ||
                                data?.hmPhone
                              )
                                ?.replace(/\D/g, "")
                                ?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2 - $3"
                                ) ?? "N/A"}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div>{formatDate(data?.createDate)}</div>
                      </td>
                      <td>
                        <label
                          htmlFor="my-modal-5"
                          className={`btn capitalize ${inactiveFlagClass(data)}
                        text-[#000] border-hidden  btn-xs cursor-default`}
                        >
                          {data?.inactiveflag ? "Inactive" : "Active"}
                        </label>
                      </td>
                      <td className=" text-base">
                        <div className="flex items-center space-x-4">
                          <button title="">
                            <span className="flex items-center space-x-4">
                              <span
                                className="tooltip text-left"
                                data-tip="View Details"
                              >
                                <Link
                                  className="cursor-pointer"
                                  to={`/claimant-management/claimant-details/${encryptId(
                                    data?.claimantID
                                  )}`}
                                >
                                  <MdRemoveRedEye className="cursor-pointer" />
                                </Link>
                              </span>

                              {["Administrators", "Schedulers"].includes(
                                userRole
                              ) && (
                                  <>
                                    <button
                                      className="tooltip"
                                      data-tip="Update Password"
                                      onClick={(e) => {
                                        setIsPasswordResetVisible({
                                          id: data.claimantID,
                                          isVisible: true,
                                        });
                                      }}
                                      htmlFor="my-modal-3"
                                    >
                                      <FaKey className="cursor-pointer" />
                                    </button>

                                    {isPasswordResetVisible.isVisible &&
                                      isPasswordResetVisible.id ===
                                      data?.claimantID ? (
                                      <ContractorClaimantStaffPasswordReset
                                        htmlFor={"my-modal-3"}
                                        id={data?.claimantID}
                                        onClose={() =>
                                          setIsPasswordResetVisible({
                                            id: null,
                                            isVisible: false,
                                          })
                                        }
                                      />
                                    ) : null}
                                  </>
                                )}

                              {SafeAllowShow(
                                <span
                                  className="tooltip text-left"
                                  data-tip="View Claims"
                                >
                                  <Link
                                    className="cursor-pointer"
                                    to={`/claims-management/claimant/${encryptId(
                                      data?.claimantID
                                    )}`}
                                    onClick={() =>
                                      dispatch(
                                        updateClaimFilters({
                                          claimantID: data?.claimantID,
                                        })
                                      )
                                    }
                                  >
                                    <img
                                      className="cursor-pointer max-w-none"
                                      alt="Claims"
                                      src={claims}
                                    />
                                  </Link>
                                </span>,
                                userRole,
                                "AS"
                              )}

                              <span
                                className="tooltip tooltip-left text-left"
                                data-tip="View Reservations"
                              >
                                <Link
                                  className="cursor-pointer"
                                  to={`/reservations-management/claimant/${encryptId(
                                    data?.claimantID
                                  )}`}
                                  onClick={() =>
                                    dispatch(
                                      updateReservationFilters({
                                        claimantID: data?.claimantID,
                                      })
                                    )
                                  }
                                >
                                  <img
                                    className="cursor-pointer max-w-none"
                                    alt="Claims"
                                    src={reservations}
                                  />
                                </Link>
                              </span>
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}

                {!claimantDataLoading && claimantsList?.length === 0 && (
                  <tr>
                    <td colSpan={11} className="my-10 text-center">
                      <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                      <p className=" font-semibold text-lg">
                        No Claimant Found
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {claimantsList?.length !== 0 && (
              <ReactPagination
                forcePage={currentPage - 1}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                pageChangeOptions={pageChangeOptions}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
        {claimantDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
      </div>
    </>
  );
};

export default ClaimantManagement;
