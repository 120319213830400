import React, { useEffect, useState } from "react";
import { Input } from "react-daisyui";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { setAssignmentFilters, updateAssignmentFilters } from "../../../../Redux-Toolkit/AssignmentSlice";
import { format } from "date-fns";
import ReactSelect from "react-select";
import { useNavigate } from "react-router-dom";
import { handleIDInputChange, iDValue } from "../../../../utils/helpers";
import InputSearchFilterDropDown from "../../../../Common/ui/inputSearchFilterDropDown";

const AssignmentManagementFilter = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const defaultState = {
    dropDownSelectedValue: null,
    reservationsAssignmentsID: 0,
    claimid: 0,
    claimantid: 0,
    contractorName: "",
    claimantName: "",
    contractorid: 0,
    rsvacCode: "",
    reservationid: 0,
    dateFrom: "",
    dateTo: "",
    inactiveflag: -2,
    contractorContactOptions: [],
    conAssignStatus: 0,
    assignmentJobStatus: "",
    assgncode: "",
    page: 1,
  };

  const initializeState = (filter = {}) => ({
    ...defaultState,
    ...filter,
  });

  const assignmentFilter = useSelector((state) => state?.assignments?.assignmentFilters) || [];
  const statusList = useSelector((state) => state?.assignments?.assignmentStatusList) || [];
  const serviceList = useSelector((state) => state?.assignments?.serviceList) || [];

  const initialState = initializeState(assignmentFilter);
  const [reservationsAssignmentsID, setReservationsAssignmentsID] = useState(
    initialState.reservationsAssignmentsID
  );
  const [claimid, setClaimid] = useState(initialState.claimid);
  const [claimantid, setClaimantid] = useState(initialState.claimantid);
  const [selectedClaimantName, setSelectedClaimantName] = useState(initialState.claimantName);
  const [contractorid, setContractorid] = useState(initialState.contractorid);
  const [selectedContractorName, setSelectedContractorName] = useState(initialState.contractorName);
  const [rsvacCode, setRsvacCode] = useState(initialState.rsvacCode);
  const [reservationid, setReservationid] = useState(initialState.reservationid);
  const [dateFrom, setDateFrom] = useState(initialState.dateFrom);
  const [dateTo, setDateTo] = useState(initialState.dateTo);
  const [inactiveflag, setInactiveflag] = useState(initialState.inactiveflag);
  const [contractorContactOptions, setContractorContactOptions] = useState(initialState.contractorContactOptions);
  const [conAssignStatus, setConAssignStatus] = useState(initialState.conAssignStatus);
  const [assignmentJobStatus, setAssignmentJobStatus] = useState(initialState.assignmentJobStatus);
  const [assgncode, setAssgncode] = useState(initialState.assgncode);
  const [page, setPage] = useState(initialState.page);

  useEffect(() => {
    updateStateFromFilter(assignmentFilter);
  }, [assignmentFilter]);

  const updateStateFromFilter = (filter) => {
    setReservationsAssignmentsID(filter?.reservationsAssignmentsID);
    setClaimid(filter?.claimid);
    setClaimantid(filter?.claimantid);
    setSelectedClaimantName(filter?.claimantName);
    setSelectedContractorName(filter?.contractorName);
    setContractorid(filter?.contractorid);
    setRsvacCode(filter?.rsvacCode);
    setReservationid(filter?.reservationid);
    setDateFrom(filter?.dateFrom);
    setDateTo(filter?.dateTo);
    setInactiveflag(filter?.inactiveflag);
    setConAssignStatus(filter?.conAssignStatus);
    setAssignmentJobStatus(filter?.assignmentJobStatus);
    setAssgncode(filter?.assgncode);
    setPage(filter?.page);
  };

  const options = [
    { value: "drivercalledflag", label: "Is called" },
    { value: "driveremailflag", label: "Is Emailed/Faxed" },
    { value: "driverconfirmedflag", label: "Is Confirmed" },
    { value: "driverreconfirmedflag", label: "Is ReConfirmed" },
    { value: "drivernaflag", label: "Is N/A" },
    { value: "leftmessageflag", label: "Is L/M" },
  ];

  /**
   * Reset the filter values to default
  */

  const handleReset = () => {
    resetState();
    dispatch(setAssignmentFilters(createFilterData()));
    navigate(`/assignments-management`);
  };

  const resetState = () => {
    setReservationsAssignmentsID(0);
    setClaimid(0);
    setClaimantid(0);
    setSelectedClaimantName("");
    setSelectedContractorName("");
    setContractorid(0);
    setRsvacCode("");
    setAssgncode("");
    setDateFrom("");
    setDateTo("");
    setInactiveflag(-2);
    setConAssignStatus(0);
    setReservationid(0);
    setContractorContactOptions([]);
    setAssignmentJobStatus("");
  };

  const createFilterData = () => ({
    reservationID: 0,
    reservationsAssignmentsID: 0,
    contractorID: 0,
    dateFrom: "",
    dateTo: "",
    claimID: 0,
    claimantID: 0,
    contractorName: "",
    claimantName: "",
    customerID: 0,
    assgnCode: "",
    rsvacCode: "",
    conAssignStatus: 0,
    assignmentJobStatus: "",
    inactiveflag: -2,
    contractorContactInfo: [],
    page: page,
    limit: 20,
  });
  //resetting filter values in redux and dispatching to update the store

  const handleDropdownChange = (contractorContactOptions) => {
    setContractorContactOptions(contractorContactOptions || []);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = createSubmissionData();
    dispatch(setAssignmentFilters(data));
    dispatch(updateAssignmentFilters({ claimantName: selectedClaimantName }));
    dispatch(updateAssignmentFilters({ contractorName: selectedContractorName }));
    navigate(`?page=1`);
  };

  /* -------- data to send to search as per filter */
  const createSubmissionData = () => {
    const selectedContractorContactInfoOptions = contractorContactOptions.map(
      (option) => option.value
    );

    return {
      ...(reservationsAssignmentsID &&
        !isNaN(Number(reservationsAssignmentsID)) && {
        reservationsAssignmentsID: Number(reservationsAssignmentsID),
      }),
      ...(claimid && !isNaN(Number(claimid)) && { claimid: Number(claimid) }),
      ...(contractorid &&
        !isNaN(Number(contractorid)) && {
        contractorid: Number(contractorid),
      }),
      ...(claimantid &&
        !isNaN(Number(claimantid)) && { claimantid: Number(claimantid) }),
      ...(reservationid &&
        !isNaN(Number(reservationid)) && {
        reservationid: Number(reservationid),
      }),
      ...(rsvacCode && rsvacCode.trim() !== "" && { rsvacCode }),
      ...(assgncode && assgncode.trim() !== "" && { assgncode }),
      ...(dateFrom && dateFrom.trim() !== "" && { dateFrom }),
      ...(dateTo && dateTo.trim() !== "" && { dateTo }),
      ...(inactiveflag &&
        !isNaN(Number(inactiveflag)) && {
        inactiveflag: Number(inactiveflag),
      }),
      ...(conAssignStatus &&
        !isNaN(Number(conAssignStatus)) && {
        conAssignStatus: Number(conAssignStatus),
      }),
      ...(assignmentJobStatus &&
        assignmentJobStatus.trim() !== "" && { assignmentJobStatus }),
      contractorContactInfo: selectedContractorContactInfoOptions,
      page: 1,
      limit: 20,
    };
  };

  return (

    <div className="card bg-base-100 shadow-md p-6 mt-3 mb-3">
      <form onSubmit={handleSubmit}>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">

          <div>
            <label
              htmlFor="reservationID"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Assignment ID/ Reservation ID
            </label>
            <input
              name="reservationID"
              onChange={(e) => handleIDInputChange(e, setReservationid)}
              value={iDValue(reservationid) || ""}
              placeholder="Assignment ID/ Reservation ID"
              id="reservationID"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>

          <div>
            <label
              htmlFor="claimid"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claim ID
            </label>
            <input
              name="claimid"
              onChange={(e) => handleIDInputChange(e, setClaimid)}
              value={iDValue(claimid) || ""}
              placeholder="Claim ID"
              id="claimid"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md capitalize focus:outline-offset-0 input-bordered"
            />
          </div>
          {/*-----------------------intellisence wala dropdown component for claimants list ------------ */}

          <div className="relative">
            <InputSearchFilterDropDown
              className={"claimant-search"}
              labelName={"Claimant"}
              url="/AllCLaimant"
              payloadName={"claimantName"}
              setSearchList={(selectedOption) => {
                setClaimantid(selectedOption?.claimantID);
                setSelectedClaimantName(selectedOption?.fullName);
              }}
              defaultSelectedValue={selectedClaimantName}
            />
          </div>

          <div className="relative">
            <InputSearchFilterDropDown
              className={"contractor-search"}
              labelName={"Contractor"}
              url="/AllContractor"
              payloadName={"contractorName"}
              data={[]}
              setSearchList={(selectedOption) => {
                setContractorid(selectedOption?.contractorID);
                setSelectedContractorName(selectedOption?.fullName);
              }}
              defaultSelectedValue={selectedContractorName}
            />
          </div>

          <div>
            <label
              htmlFor="datefrom"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Date From
            </label>
            <Input
              name="datefrom"
              placeholder=""
              onChange={(e) => {
                const dateValue = e.target.value;
                if (dateValue) {
                  setDateFrom(format(new Date(dateValue), "yyyy-MM-dd"));
                } else {
                  setDateFrom(""); // Clear the date if the input is cleared
                }
              }}
              value={dateFrom}
              id="datefrom"
              type="date"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>

          <div>
            <label
              htmlFor="dateto"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Date To
            </label>
            <Input
              name="dateto"
              placeholder=""
              onChange={(e) => {
                const dateValue = e.target.value;
                if (dateValue) {
                  setDateTo(format(new Date(dateValue), "yyyy-MM-dd"));
                } else {
                  setDateTo(""); // Clear the date if the input is cleared
                }
              }}
              id="dateto"
              type="date"
              value={dateTo}
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>

          <div>
            <label
              htmlFor="actioncode"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Action Code
            </label>
            <select
              name="actioncode"
              value={rsvacCode}
              onChange={(e) => {
                setRsvacCode(e.target.value);
              }}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="">All</option>
              {statusList &&
                statusList?.length > 0 &&
                statusList?.map((rsvacCode) => (
                  <option value={rsvacCode?.code} key={rsvacCode?.code}>
                    {rsvacCode?.code + " - " + rsvacCode?.description}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="contractorContactInfo"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              data-testid="contractorContactInfo"
            >
              Contractor Contact Info
            </label>
            <ReactSelect
              id="contractorContactInfo"
              name="contractorContactInfo"
              options={options}
              isMulti
              value={contractorContactOptions}
              onChange={handleDropdownChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div>
            <label
              htmlFor="activeInactiveStatus"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Status Active/Inactive
            </label>
            <select
              id="activeInactiveStatus"
              onChange={(e) => setInactiveflag(e.target.value)}
              name="activeInactiveStatus"
              value={inactiveflag}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value={"-2"}>All</option>
              <option value={"0"}>Active</option>
              <option value={"-1"}>Inactive </option>
            </select>
          </div>

          <div>
            <label
              htmlFor="contractorStatus"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Contractor Status
            </label>
            <select
              id="contractorStatus"
              onChange={(e) => setConAssignStatus(e.target.value)}
              name="contractorStatus"
              value={conAssignStatus}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value={"0"}>All</option>
              <option value={"1"}>Assigned</option>
              <option value={"-1"}>Not Assigned</option>
            </select>
          </div>

          <div>
            <label
              htmlFor="service"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Service
            </label>
            <select
              name="service"
              value={assgncode}
              onChange={(e) => {
                setAssgncode(e.target.value);
              }}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="">All</option>
              {serviceList &&
                serviceList?.length > 0 &&
                serviceList?.map((assgncode, index) => (
                  <option value={assgncode?.code} key={assgncode?.code}>
                    {assgncode?.description}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="jobStatus"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Job Status
            </label>
            <select
              id="jobStatus"
              onChange={(e) => setAssignmentJobStatus(e.target.value)}
              name="jobStatus"
              value={assignmentJobStatus}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value={""}>All</option>
              <option value={"NotStarted"}>Pending</option>
              <option value={"Ongoing"}>Ongoing</option>
              <option value={"Complete"}>Completed</option>
            </select>
          </div>
        </div>

        <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
          <button
            type="reset"
            onClick={() => {
              handleReset();
            }}
            className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            <MdOutlineRefresh className="mr-1" />
            Reset
          </button>
          <button 
            className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4"
            data-testid="assignment-search"
          >
            <MdOutlineSearch className="mr-1" />
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default AssignmentManagementFilter;
