import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  metricsList: [],
  customerName: "",
  contractorName: "",
  notificationcount: "",
  selectedTableRow: null,

  metricsFilter: {
      reservationID: 0,
      reservationsAssignmentsID: 0,
      customerID: 0,
      customerName: "",
      contractorName: "",
      contractorID: 0,
      claimnumber: "",
      dateFrom: "",
      dateTo: "",
      page: 0,
      limit: 0
    }
};

export const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    loadUser: (state) => {
      if (!localStorage.getItem("userAuthApproved")) {
        state.userAuthApproved = false;
        return;
      }
      state.Token = localStorage.getItem("Admintoken");
      state.user = localStorage.getItem("Admin");
      state.userAuthApproved = true;
    },
    setmetricsList: (state, action) => {
      state.metricsList = action.payload;
    },
    setErrLoggingIn: (state, action) => {
      state.errLoggingIn = action.payload;
    },
    // Add other reducers here
    setLoginLoading: (state, action) => {
      state.loginLoading = action.payload;
    },
    setmetricsFilters: (state, action) => {
      state.metricsFilter = action.payload;
    },
    updatemetricsFilters: (state, action) => {
      state.metricsFilter = { ...state.metricsFilter, ...action.payload };
    },
    setSelectedRow: (state, action) => {
      state.selectedTableRow = action.payload;
    },
    resetmetricsFilters: (state) => {
      state.metricsFilter = {...initialState.metricsFilter}; 
      state.selectedTableRow = null;
    },
    setClaimNumber: (state, action) => {
        state.claimNumber =action.payload;
    },
    setCustomerNameList: (state, action) => {
        state.customerName =action.payload;
    },
    setContractorNameList: (state, action) => {
        state.contractorName =action.payload;
    }
  },

});

export const {
  setErrLoggingIn,
  setLoginLoading,
  userAuthApproved,
  loadUser,
  setCustomerNameList,
  setmetricsFilters,
  updatemetricsFilters,
  resetmetricsFilters,
  setSelectedRow,
  setClaimNumber,
  setmetricsList,
  setContractorNameList,
  setmetricsStatusList,
} = metricsSlice.actions;

export default metricsSlice.reducer;
