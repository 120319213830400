export const sideNavbarOptions = [
  {
    to: "/",
    name: "Home",
    icon: "RxDashboard",
    event: "",
    roleAccess: ["all"],
  },
  {
    to: "/customer-management",
    name: "Customers",
    icon: "FaBuilding",
    event: "resetCustomerFilters",
    roleAccess: ["Administrators", "Billing"],
  },
  {
    to: "/claimant-management", 
    name: "Claimants",
    icon: "RxPerson",
    event: "resetClaimantFilters",
    roleAccess: ["Administrators", "Schedulers"]
  },
  {
    to: "/contractor-management",
    name: "Contractors",
    icon: "FaPeopleGroup",
    event: "resetContractorFilters",
    roleAccess: ["Administrators", "Schedulers", "Billing"],
  },
  {
    to: "/staff-management",
    name: "Staff",
    icon: "IoIosPeople",
    event: "resetStaffFilters",
    roleAccess: ["Administrators"]
  },
  {
    to: "/claims-management",
    name: "Claims",
    icon: "RxPerson",
    event: "resetClaimFilters",
    roleAccess: ["Administrators", "Schedulers"]
  },
  {
    to: "/reservations-management",
    name: "Reservations",
    icon: "FaPeopleArrows",
    event: "resetReservationFilters",
    roleAccess: ["Administrators", "Schedulers"]
  },
  {
    to: "/assignments-management",
    name: "Assignments",
    icon: "MdAssignmentAdd",
    event: "resetAssignmentFilters",
    roleAccess: ["Administrators", "Schedulers"]
  },
  {
    to: "/metrics-management",
    name: "Metrics",
    icon: "FaFileInvoiceDollar",
    event: "resetmetricsFilters",
    roleAccess: ["Administrators", "Billing"],
  },
];
