import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineVpnKey } from "react-icons/md";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import Logo from "../../assets/images/JNJ Services-logo-large art-vector.png";
import backgroundImg from "../../assets/images/1920-darkflakes.svg";
import { jwtDecode } from "jwt-decode";
import { postLogin } from "../../ApiMethods/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setUserAuthApproved, setUserRole, setuserToken } from "../../Redux-Toolkit/AuthSlice";

const ResetPassword = ({dataTestId}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [passwordLoading, setPasswordLoading] = useState(false);
  const [currentShowPassword, setCurrentShowPassword] = useState(false);
  const [newShowPassword, setNewShowPassword] = useState(false);
  const [confirmNewShowPassword, setConfirmNewShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [userEmail, setUserEmail] = useState("");

  let usertoken = useSelector((state) => state?.newUserPasswordChange?.Token);

  /**
   * @description This useEffect will check if the user is logged in or not.
   *  * If not, it will redirect to the login page.
   *  ** If logged in, setting newEmail for api body  from the token
   */
  useEffect(() => {
    if (!usertoken) {
      navigate("/");
    } else {
      const newemail = jwtDecode(usertoken);
      setUserEmail(newemail?.email);
    }
  }, []);

  /**
   * @description This function will call the api to update the password.
   */
  const passwordChange = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("The new password and confirm password must be the same.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const body = {
      emailID: userEmail,
      newPassword: newPassword,
      oldPassword: oldPassword,
    };
    try {
      setPasswordLoading(true); //setting state for password loading as true
      const response = await postLogin("/UpdatePassword", body);
      setPasswordLoading(false); //setting state for password loading as false
      if (response?.data?.status === false) {
        if (
          response?.data?.statusMessage ===
          "The new password cannot be the same as the old password."
        ) {
          toast.error(
            "The new password must be different from the old password.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        } else if (
          response?.data?.statusMessage === "Old password is incorrect"
        ) {
          toast.error("Old Password is incorrect.", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error("User does not exist", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
      else if (response?.data?.status === true) {
        localStorage.clear();
        dispatch(setUserAuthApproved(false));
        dispatch(setuserToken(""));
        dispatch(setUserRole(""));

        toast.success(
          "Password has been changed. Please login with your new password.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          }
        );

        navigate("/login");
      }
    } catch (error) {
      console.error("error occured", error);
    }
  };

  return (
    <div
      className="flex justify-center items-center w-full h-screen md:py-0 py-5 bg-no-repeat bg-fixed object-cover object-top"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
      }}
    >
      <div className=" grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 bg-[#f7f7f7] shadow-lg rounded-xl 2xl:w-6/12 lg:w-7/12 md:w-9/12 w-11/12 overflow-hidden">
        <div className=" bg-[#90d8f5] p-5 lg:col-span-2 flex justify-center items-center">
          <div>
            <div className="">
              <img className="w-[100%] mx-auto" alt="JNJ logo" src={Logo} />
            </div>
            <div className="text-[#000] text-center mt-5"></div>
          </div>
        </div>
        <div className="p-5 lg:col-span-3">
          <p className=" text-lg font-semibold text-[orange]">
            {" "}
            Your password is system generated.
          </p>
          <p className=" text-lg font-semibold text-[orange]">
            {" "}
            Kindly update your password.
          </p>
          <div className="mt-5">
            <form
              autoComplete="off"
              onSubmit={(e) => {
                passwordChange();
                e.preventDefault();
              }}
            >
              <div className="pt-4">
                <div className="form-control">
                  <label className="label" htmlFor="old_password">
                    <span className="label-text font-semibold">
                      Old Password (System Generated)
                    </span>
                  </label>
                  <label className="input-group">
                    <span className=" bg-[#000]">
                      <MdOutlineVpnKey className=" text-[#63b0e0]" />
                    </span>
                    <input
                      id="old_password"
                      autoComplete={false}
                      onChange={(e) => setOldPassword(e.target.value)}
                      required
                      name="old-password"
                      type={currentShowPassword ? "text" : "password"}
                      placeholder="Current Password"
                      className="input w-full border-[#000] focus:outline-none border-r-0"
                    />
                    <button
                      type="button"
                      className=" bg-transparent cursor-pointer border-[#000] border border-l-0"
                      onClick={() =>
                        setCurrentShowPassword(!currentShowPassword)
                      }
                      data-testid={`${dataTestId}-oldPasword`}
                    >
                      {currentShowPassword ? (
                        <RxEyeOpen className=" text-[#000] mr-3 ml-3" />
                      ) : (
                        <RxEyeClosed className=" text-[#000] mr-3 ml-3" />
                      )}
                    </button>
                  </label>
                </div>
                <div className="form-control mt-2">
                  <label className="label" htmlFor="new_password">
                    <span className="label-text font-semibold">
                      New Password
                    </span>
                  </label>
                  <label className="input-group">
                    <span className=" bg-[#000]">
                      <MdOutlineVpnKey className=" text-[#63b0e0]" />
                    </span>
                    <input
                      id="new_password"
                      autoComplete={false}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                      name="new-password"
                      type={newShowPassword ? "text" : "password"}
                      placeholder="New Password"
                      className="input w-full border-[#000] focus:outline-none border-r-0"
                    />
                    <button
                      type="button"
                      className=" bg-transparent cursor-pointer border-[#000] border border-l-0"
                      onClick={() => setNewShowPassword(!newShowPassword)}
                      data-testid={`${dataTestId}-newPassword`}
                    >
                      {newShowPassword ? (
                        <RxEyeOpen className=" text-[#000]" />
                      ) : (
                        <RxEyeClosed className=" text-[#000]" />
                      )}
                    </button>
                  </label>
                </div>
                <div className="form-control mt-2">
                  <label className="label" htmlFor="confirm_new_password">
                    <span className="label-text font-semibold">
                      Confirm New Password
                    </span>
                  </label>
                  <label className="input-group">
                    <span className=" bg-[#000]">
                      <MdOutlineVpnKey className=" text-[#63b0e0]" />
                    </span>
                    <input
                      id="confirm_new_password"
                      autoComplete={false}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      name="confirm-password"
                      type={confirmNewShowPassword ? "text" : "password"}
                      placeholder="Confirm New Password"
                      className="input w-full border-[#000] focus:outline-none border-r-0"
                    />
                    <button
                      type="button"
                      className=" bg-transparent cursor-pointer border-[#000] border border-l-0"
                      onClick={() =>
                        setConfirmNewShowPassword(!confirmNewShowPassword)
                      }
                      data-testid={`${dataTestId}-confirmPasword`}
                    >
                      {confirmNewShowPassword ? (
                        <RxEyeOpen className=" text-[#000]" />
                      ) : (
                        <RxEyeClosed className=" text-[#000]" />
                      )}
                    </button>
                  </label>
                  <span className=" text-xs mt-2 text-[#ff3f3f]">
                    *Passwords must have at least 8 characters and contain
                    combination of the following: uppercase letters, lowercase
                    letters, numbers and symbols.
                  </span>
                </div>

                <div className=" flex justify-center ">
                  {!passwordLoading ? (
                    <button
                      type="submit"
                      className="mt-5 btn w-[50%] bg-[#63b0e0] hover:bg-[#000] text-[#000] hover:text-[#63b0e0] font-semibold border-none"
                    >
                      Update Password
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-[#63b0e0] hover:text-[#63b0e0] font-semibold border-none flex justify-center items-center"
                    >
                      <svg
                        className="animate-spin mr-1 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
