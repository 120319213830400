import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  staff: [],
  roles: [],

  staffFilters: {
    userID: "",
    userName: "",
    role: 0,
    email: "",
    inactiveflag: -2,
  },
};

export const StaffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    loadUser: (state) => {
      if (!localStorage.getItem("userAuthApproved")) {
        state.userAuthApproved = false;
        return;
      }
      state.Token = localStorage.getItem("Admintoken");
      state.user = localStorage.getItem("Admin");
      state.userAuthApproved = true;
    },
    setStaff: (state, action) => {
      state.staff = action.payload;
    },
    setStaffRoles: (state, action) => {
      state.roles = action.payload;
    },
    setStaffFilters: (state, action) => {
      state.staffFilters = action.payload;
    },
    resetStaffFilters: (state) => {
      state.staffFilters = { ...initialState.staffFilters };
      state.selectedTableRow = null;
    },
    setSelectedRow: (state, action) => {
      state.selectedTableRow = action.payload;
    },
  },

});

export const {
  setStaff,
  loadUser,
  setStaffRoles,
  setStaffFilters,
  setSelectedRow,
  resetStaffFilters,
} = StaffSlice.actions;

export default StaffSlice.reducer;
