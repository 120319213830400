import React, { useEffect, useState, useRef } from "react";
import { MdRemoveRedEye } from "react-icons/md";
import { RxPerson } from "react-icons/rx";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Filter from "./ReservationManagementFilter/ReservationManagementFilter";
import { useDispatch, useSelector } from "react-redux";
import { postMethodData, getMethodData } from "../../../ApiMethods/Api";
import {
  setReservation,
  setServiceList,
  setTransportTypeList,
  setTripTypeList,
  setReservationStatusList,
  setSelectedRow,
} from "../../../Redux-Toolkit/ReservationSlice";
import { updateAssignmentFilters } from "../../../Redux-Toolkit/AssignmentSlice";
import decryptParamID from "../../../utils/decryptParamID";
import { SafeAllowClick } from "../../../utils/safe";
import encryptParamID from "../../../utils/encryptParamID";
import assignment from "../../../assets/JNJ-icons/assignment.svg";
import { formatDate, inactiveFlagClass } from "../../../utils/helpers";
import ReactPagination from "../../../utils/ReactPagination";
import { nanoid } from "nanoid";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const ReservationManagement = () => {

  const params = decryptParamID(useParams());
  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const urlHasParams = Object.keys(params).length > 0;
  const userRole = useSelector((state) => state?.auth?.role);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const [reservationDataLoading, setReservationDataLoading] = useState(false);

  const reservationSearchListData = useSelector((state) => state?.reservation?.reservations) || []; //for customers search list of JNJ customers
  const reservationFilters = useSelector((state) => state?.reservation?.reservationFilters);
  const [entityName, setEntityName] = useState("");

  const rowRefs = useRef([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const ITEMS_PER_PAGE = 20;
  const selectedRow = useSelector((state) => state?.reservation?.selectedTableRow);

  useEffect(() => {
    setCurrentPage(searchParamPage ?? 1);
  }, [searchParamPage]);

  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && tableRef.current) {
      const td = document.getElementById(selectedRow);
      if (td) {
        td.scrollIntoView({ block: "center" });
      }
    }
  }, [reservationSearchListData, selectedRow]);

  const fetchData = (url, action) => {
    getFilterListDataMethod(url, (response) => {
      const data = response?.data?.data;
      if (data?.length) {
        dispatch(action(data));
      }
    });
  };

  useEffect(() => {
    const urlsAndActions = [
      { url: "/resservice", action: setServiceList },
      { url: "/restriptype", action: setTripTypeList },
      {
        url: "/resactioncode",
        action: (data) => setReservationStatusList(data || []),
      },
      { url: "/restranstype", action: setTransportTypeList },
    ];

    urlsAndActions.forEach(({ url, action }) => fetchData(url, action));
  }, []);

  useEffect(() => {
    reservationSearchList(
      "/ReservationSearch",
      {
        ...reservationFilters,
        page: Number(currentPage),
        limit: ITEMS_PER_PAGE,
      },
      (response) => {

        if (!response?.data?.status) {
          dispatch(setReservation([]));
          setPageCount(0);
        } else if (response?.data?.status) {
          dispatch(setReservation(response?.data?.data));
          setPageCount(Math.ceil(response?.data?.totalData / ITEMS_PER_PAGE));
        }
      }
    );
  }, [reservationFilters, currentPage]);

  const getFilterListDataMethod = async (url, callback) => {
    try {
      const response = await getMethodData(url);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer category list:",
        error
      );
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchEntityNames = async () => {
      for (let key in params) {
        if (keyMapping[key]) {
          let newKey = key.replace("id", "ID");
          const endpoint = endpointMapping[key];
          const body = { [newKey]: Number(params[key]) };
          getEntityName(endpoint, body, (response) => {
            if (response?.data?.data?.length === 0) {
              setEntityName("N/A");
            } else {
              const property = propertyMapping[key];
              setEntityName(`| ${response?.data?.data[0]?.[[property]]}`);
            }
          });
        }
      }
    };

    fetchEntityNames();
  }, []);

  const endpointMapping = {
    customerid: "/CustomerSelDetail",
    claimantid: "/ClaimantSelDetail",
    contractorid: "/ContractorSelDetail",
  };

  const propertyMapping = {
    customerid: "companyName",
    claimantid: "fullName",
    contractorid: "company",
  };

  const keyMapping = {
    customerid: "Customer ID",
    claimantid: "Claimant ID",
    contractorid: "Contractor ID",
    claimnumber: "Claim Number",
  };

  const getEntityName = async (url, data, callback) => {
    const body = data;
    try {
      const response = await postMethodData(url, body);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setEntityName("");
    }
  };

  const reservationSearchList = async (url, filterdata, callback) => {
    const body = filterdata || {
      reservationid: 0,
      customerName: "",
      claimantname: "",
      contractor: "",
      serviceName: "",
      reservationDate: "",
      claimEmployerCity: "",
      inactiveflag: -2,
      conAssignStatus: "",
      limit: ITEMS_PER_PAGE,
    };
    try {
      setReservationDataLoading(true);
      const response = await postMethodData(url, body);
      callback(response);
      setReservationDataLoading(false);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setReservationDataLoading(false);
      dispatch(setReservation([]));
    }
  };

  const goBack = () => {
    window.history.back();
  };
  const encryptId = (id) => encryptParamID(id);

  const pageChangeOptions = {
    endPointFunction: reservationSearchList,
    endpoint: "/ReservationSearch",
    componentFilter: reservationFilters,
    dispatchAction: setReservation,
    itemsPerPage: ITEMS_PER_PAGE,
    scrollElementId: "tablescroll", // Optional, only if you want to scrol,
  };

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize"
            data-testid="reservationManagement"
          >
            {!urlHasParams
              ? "Reservation Management"
              : `Reservation List for ${Object.keys(params)
                  .map(
                    (key) =>
                      `${keyMapping[key] || key}: ${params[key]} ${entityName}`
                  )
                  .join(", ")}`}
          </p>
        </div>
        {urlHasParams && (
          <div>
            <Link
              onClick={goBack}
              className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
            >
              Back
            </Link>
          </div>
        )}
      </div>
      <div className="relative">
        {!urlHasParams && <Filter />}
        <div className="card bg-base-100 shadow-md p-6 mt-8">
          <div className="overflow-x-auto w-full">
            <table
              ref={tableRef}
              id="tablescroll"
              className="table table-zebra w-full"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Reservation ID</th>
                  <th>Action Code</th>
                  <th>Customer</th>
                  <th>Claimant</th>
                  <th>Service</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="text-sm relative">
                {reservationDataLoading &&
                  Array.from({ length: 5 }).map((_) => (
                    <tr key={nanoid()}>
                      <td colSpan={13} className="animate-pulse py-6"></td>
                    </tr>
                  ))}

                {!reservationDataLoading &&
                  reservationSearchListData?.length !== 0 &&
                  Array.isArray(reservationSearchListData) &&
                  reservationSearchListData?.map((data, index) => (
                    <tr
                      key={nanoid()}
                      ref={(el) => (rowRefs.current[index] = el)}
                      id={data?.reservationid}
                      className={`${
                        selectedRow === data?.reservationid
                          ? "activeTableRow"
                          : ""
                      } ${
                        data?.inactiveflag === -1
                          ? "assignmentCancelledRow"
                          : ""
                      }`}
                      onClick={() => {
                        dispatch(setSelectedRow(data?.reservationid));
                      }}
                    >
                      <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>
                      <td>{data?.reservationid}</td>
                      <td>{data?.actionCode}</td>
                      <td>
                        <div className="flex items-center space-x-3">
                          <div data-testid="customer">
                            <span
                              className="tooltip text-left"
                              data-tip={
                                ["Administrators"].includes(userRole)
                                  ? "View Details"
                                  : null
                              }
                              data-testid="customer"
                            >
                              <button
                                className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                                onClick={() =>
                                  SafeAllowClick(
                                    () => {
                                      if (data?.customerName !== undefined) {
                                        !data?.isDelete &&
                                          navigate(
                                            `/customer-management/customer-details/${encryptId(
                                              data?.customerid
                                            )}`
                                          );
                                      }
                                    },
                                    userRole,
                                    "A"
                                  )
                                }
                                data-testid="customer"
                              >
                                {data?.customerFL || "N/A"}
                              </button>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span
                          className="tooltip text-left"
                          data-tip="View Details"
                        >
                          <div className="flex items-center space-x-3">
                            <div>
                              <button
                                className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                                onClick={() => {
                                  if (data?.claimantname !== undefined) {
                                    !data?.isDelete &&
                                      navigate(
                                        `/claimant-management/claimant-details/${encryptId(
                                          data?.claimantid
                                        )}`
                                      );
                                  }
                                }}
                              >
                                {data?.claimantname ?? "N/A"}
                              </button>
                            </div>
                          </div>
                        </span>
                      </td>

                      <td>
                        <div className="flex items-center space-x-3">
                          <div>
                            <div className="">
                              {data?.serviceName || "N/A"}
                              <div>({data?.transType || "N/A"})</div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div style={{ whiteSpace: "nowrap" }}>
                          {formatDate(data?.reservationDate)}
                        </div>
                      </td>

                      <td>
                        <label
                          htmlFor="my-modal-5"
                          className={`btn capitalize
                          ${inactiveFlagClass(data)}
                          text-[#000] border-hidden  btn-xs cursor-default`}
                        >
                          {data?.inactiveflag ? "Inactive" : "Active"}
                        </label>
                      </td>

                      <td className=" text-base">
                        <div className="flex items-center space-x-4">
                          <button title="">
                            <span className="flex items-center space-x-4">
                              <span className="tooltip" data-tip="View Details">
                                <Link
                                  className="cursor-pointer"
                                  to={`/reservations-management/reservation-details/${data?.reservationid}`}
                                >
                                  <MdRemoveRedEye className="cursor-pointer" />
                                </Link>
                              </span>
                              <span
                                className="tooltip tooltip-left text-left"
                                data-tip="View Assignments"
                              >
                                <Link
                                  className="cursor-pointer"
                                  to={`/assignments-management/reservation/${encryptId(
                                    data?.reservationid
                                  )}/${data?.claimnumber}/${data?.puZipCode || 0}`}
                                  onClick={() =>
                                    dispatch(
                                      updateAssignmentFilters({
                                        reservationID: data?.reservationid,
                                      })
                                    )
                                  }
                                >
                                  <img
                                    className="cursor-pointer max-w-none"
                                    alt="Claims"
                                    src={assignment}
                                  />
                                </Link>
                              </span>
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}

                {!reservationDataLoading &&
                  reservationSearchListData?.length === 0 && (
                    <tr>
                      <td colSpan={11} className="my-10 text-center">
                        <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                        <p className=" font-semibold text-lg">
                          No Reservation Found
                        </p>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            {reservationSearchListData?.length !== 0 && (
              <ReactPagination
                forcePage={currentPage - 1}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                // onPageChange={handlePageChange}
                pageChangeOptions={pageChangeOptions}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
        {reservationDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
      </div>
    </>
  );
};

export default ReservationManagement;
